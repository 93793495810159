import React, {Fragment, useEffect, useState} from 'react';
import JezHeading from "../../../components/heading/heading";
import {Trans, useTranslation} from "react-i18next";
import {Grid, makeStyles} from "@material-ui/core";
import JezSelect from "../../../components/select/select";
import JezModeratedTextEdit from "../fields/moderatedTextEdit";
import JezModeratedSelect from "../fields/moderatedSelect";
import {GiBodyHeight} from 'react-icons/gi';


const useStyles = makeStyles((theme) => ({
    sectionHeadline: {
        margin: '50px 0 15px 0 !important'
    }
}));

const JezProfileBodyProperties = ({data, verified, onChange}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {t} = useTranslation();

    const [state, setState] = useState({});

    const change = async (e) => {
        if(onChange) {
            await onChange(e);
        }
    };

    useEffect(() => {
        setState(data);
    }, [data]);

    const changeNumber = async (e) => {
        e.target.value = e.target.value.replace(/^0/, '').replace(/[^0-9+ ]+/, '').replace('  ', ' ');

        await change(e);
    };

    return (
        <Fragment>
            <JezHeading center variant='profileform' icon={<GiBodyHeight />} className={classes.sectionHeadline}>
                <Trans>profile_body_group_title</Trans>
            </JezHeading>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6}>
                    <JezSelect
                        label={t("hair_color_label")}
                        name='hair_color'
                        placeholder=''
                        onChange={(e) => change(e)}
                        id='hair_color'
                        options={[
                            {key: '', value: ''},
                            {key: 'blond', value: t('blond')},
                            {key: 'dark blond', value: t('dark blond')},
                            {key: 'brown', value: t('brown')},
                            {key: 'black', value: t('black')},
                            {key: 'red', value: t('red')},
                            {key: 'white', value: t('white')},
                            {key: 'other', value: t('other')},
                            {key: 'secret', value: t('hair_color_let_yourself_be_surprised')}
                        ]}
                        className={'profileform'}
                        value={state.hair_color}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                    <JezSelect
                        label={t("eye_color_label")}
                        name='eye_color'
                        placeholder=''
                        onChange={(e) => change(e)}
                        id='eye_color'
                        options={[
                            {key: '', value: ''},
                            {key: 'brown', value: t('brown')},
                            {key: 'green', value: t('green')},
                            {key: 'blue', value: t('blue')},
                            {key: 'secret', value: t('eye_color_find_it_out')},
                        ]}
                        className={'profileform'}
                        value={state.eye_color}
                    />
                </Grid>
            </Grid>
            <JezSelect
                label={t("cup_type_label")}
                name='cup_type'
                onChange={(e) => change(e)}
                placeholder=''
                id='cup_type'
                value={state.cup_type}
                options={[
                    {key: '', value: ''},
                    {key: 'nature', value: t('nature')},
                    {key: 'silicone', value: t('silicone')},
                    {key: 'secret', value: t('cup_type_find_it_out')},
                ]}
                className={'profileform'}
            />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <JezModeratedTextEdit onChange={changeNumber} name={'size'} label={t("body_height_label")} unit={'cm'} type={'number'} field={verified.size}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <JezModeratedTextEdit onChange={changeNumber} name={'weight'} label={t("body_weight_label")} unit={'kg'} type={'number'} field={verified.weight}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <JezModeratedSelect label={t('cup_size_label')} name={'cup_size'} field={verified.cup_size} onChange={change} options={[
                        {key: '', value: ''},
                        {key: 'AA', value: t('cup_aa')},
                        {key: 'A', value: t('cup_a')},
                        {key: 'B', value: t('cup_b')},
                        {key: 'C', value: t('cup_c')},
                        {key: 'D', value: t('cup_d')},
                        {key: 'E', value: t('cup_e')},
                        {key: 'F', value: t('cup_f')},
                        {key: 'G', value: t('cup_g')},
                        {key: 'H', value: t('cup_h')},
                        {key: 'I', value: t('cup_i')},
                        {key: 'J', value: t('cup_j')},
                        {key: 'K', value: t('cup_k')},
                        {key: 'secret', value: t('cup_size_find_it_out')},
                    ]}/>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default JezProfileBodyProperties;