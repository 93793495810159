import {Grid, IconButton, InputAdornment} from '@material-ui/core';
import React, {Fragment, useState} from 'react';
import {useTranslation} from "react-i18next";
import {RiLockPasswordLine} from "react-icons/ri";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import Password from "../../util/password";
import {checkRequiredFields, inputChange, isEmptyObject} from "../../util/tools";
import JezTextEdit from "../textedit/textedit";
import './passwordform.scss';

const PasswordForm = (params) => {

    const {t} = useTranslation();
    Password.setTranslator(t);

    const [state, setState] = useState({
        password: '',
        password_confirm: '',
        // once_password: params.once_password ? false : null,
        current_password: params.current_password ? '' : null,
        minLength: params.min_length >= 0 ? params.min_length : 12,
        minLowercaseCount: params.min_lowercase_count >= 0 ? params.min_lowercase_count : 2,
        minUppercaseCount: params.min_uppercase_count >= 0 ? params.min_uppercase_count : 2,
        minNumbersCount: params.min_numbers_count >= 0 ? params.min_numbers_count : 2,
        minSpecialcharsCount: params.min_specialchars_count >= 0 ? params.min_specialchars_count : 2,
        maxLength: params.max_length >= 0 ? params.max_length : 4096,
        forbiddenchars: params.hasOwnProperty('forbiddenchars') ? params.forbiddenchars : true,
    });

    const [validation, setValidation] = useState({
        error: false,
        errorMessage: {},
    });

    const [value, setValue] = useState({
        showPassword: false
    });

    const handleClickShowPassword = () => {
        setValue({...value, showPassword: !value.showPassword});
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const stateValue = (aState, name) => {
        if (!(name in aState)) {
            return '';
        }
        return aState[name];
    };

    const validate = (e, aState) => {
        let newState = aState === undefined ? Object.assign({}, state) : aState;
        let requiredFields = {};
        if (params.current_password && 'password' in newState && newState.password !== '') {
            requiredFields.current_password = t('Current password');
        }
        let newValidation = checkRequiredFields(validation, requiredFields, state);
        if ('password' in newState && newState.password !== '') {
            let complexityErrors = Password.checkPasswordComplexity(
                newState.password,
                newState.minLength,
                newState.minLowercaseCount,
                newState.minUppercaseCount,
                newState.minNumbersCount,
                newState.minSpecialcharsCount,
                newState.maxLength,
                false //newState.forbiddenchars
            );
            if (complexityErrors.length > 0) {
                newValidation['error'] = true;
                newValidation['errorMessage']['password'] = complexityErrors.join("\n");
            }
            else {
                let errorMessage = newValidation.errorMessage;
                delete (errorMessage['password']);
                newValidation['errorMessage'] = {
                    ...errorMessage,
                };
                newValidation['error'] = !isEmptyObject(errorMessage);
            }
        }
        else {
            let errorMessage = newValidation.errorMessage;
            delete (errorMessage['password']);
            newValidation['errorMessage'] = {
                ...errorMessage,
            };
            newValidation['error'] = !isEmptyObject(errorMessage);
        }
        if (stateValue(newState, 'password') !== stateValue(newState, 'password_confirm')) {
            newValidation['error'] = true;
            newValidation['errorMessage']['password_confirm'] = t('password_confirm_error')
                + " " + Password.checkPasswordCompare(stateValue(newState, 'password'), stateValue(newState, 'password_confirm'));
        }
        else {
            let errorMessage = newValidation.errorMessage;
            delete (errorMessage['password_confirm']);
            newValidation['errorMessage'] = {
                ...errorMessage,
            };
            newValidation['error'] = !isEmptyObject(errorMessage);
        }
        if (aState === undefined) {
            setState(newState);
        }
        setValidation(newValidation);

        if (e !== undefined) {
            // call validate function on parent form
            if ('onValidate' in params) {
                params.onValidate();
            }
            // save data into parent form state
            if (!newValidation['error'] && 'onSave' in params) {
                params.onSave(e, 'bearbeiten', newState);
            }
        }

        return !newValidation.error;
    };

    const generatePasswordClick = (event) => {
        event.preventDefault();
        let password = Password.generatePassword();

        let newState = Object.assign({}, state);
        newState.password = password;
        newState.password_confirm = password;
        validate({}, newState);
        setState(newState);

        setValue({...value, 'showPassword': true});

        return false;
    };

    const passwordInput = (e) => {
        if (e.target.value.length === 0) {
            let newValidation = Object.assign({}, validation);
            newValidation.error = false;
            delete newValidation.errorMessage['password'];
            setValidation(newValidation);
        }
        else {
            let errors = Password.checkPasswordComplexity(
                e.target.value,
                state.minLength,
                state.minLowercaseCount,
                state.minUppercaseCount,
                state.minNumbersCount,
                state.minSpecialcharsCount,
                state.maxLength,
                state.forbiddenchars
            );
            if (errors.length > 0) {
                let newValidation = Object.assign({}, validation);
                newValidation.error = true;
                newValidation.errorMessage['password'] = errors.join(" ");
                setValidation(newValidation);
            }
            else {
                let newValidation = Object.assign({}, validation);
                newValidation.error = false;
                delete newValidation.errorMessage['password'];
                setValidation(newValidation);
            }
        }
    };

    params.parentRef.current.onValidate = validate;
    params.parentRef.current.getState = () => { return state; };

    return (
        <Fragment>
            <Grid container spacing={1}>
                {params.current_password && (
                    <Grid item xs={12} lg={12}>
                        <JezTextEdit
                            autoComplete="new-password"
                            className={'profileform'}
                            error={!!validation.errorMessage.current_password}
                            formhelpertextprops={{focused: true}}
                            fullWidth
                            helperText={validation.errorMessage.current_password || t('Current password')}
                            id='current_password'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            title={t("toggle password visibility")}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {value.showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label={t('Current password')}
                            name='current_password'
                            onBlur={validate}
                            onChange={(e) => setState(inputChange(e, state))}
                            placeholder={t('Current password')}
                            required={state.password !== ''}
                            type={value.showPassword ? 'text' : 'password'}
                            value={state.current_password}
                        />
                    </Grid>
                )}
                <Grid item xs={12} lg={12}>
                    <JezTextEdit
                        autoComplete="new-password"
                        className={'profileform'}
                        error={!!validation.errorMessage.password}
                        formhelpertextprops={{focused: true}}
                        fullWidth
                        helperText={validation.errorMessage.password || (state.password.length === 0 && [
                                state.minLength > 0 ? t('password_js_min_length')
                                    .replace(/\${minLength}/, state.minLength) : '',
                                state.minLowercaseCount > 0 ? t('password_js_min_lowercase_count')
                                    .replace(/\${minLowercaseCount}/, state.minLowercaseCount)
                                    .replace(/\${minLowercaseChars}/, " '" + Password.passwordCharacters['lowercase'] + "'") : '',
                                state.minUppercaseCount > 0 ? t('password_js_min_uppercase_count')
                                    .replace(/\${minUppercaseCount}/, state.minUppercaseCount)
                                    .replace(/\${minUppercaseChars}/, " '" + Password.passwordCharacters['uppercase'] + "'") : '',
                                state.minNumbersCount > 0 ? t('password_js_min_numbers_count')
                                    .replace(/\${minNumbersCount}/, state.minNumbersCount)
                                    .replace(/\${minNumbersChars}/, " '" + Password.passwordCharacters['numbers'] + "'") : '',
                                state.minSpecialcharsCount > 0 ? t('password_js_min_specialchars_count')
                                    .replace(/\${minSpecialcharsCount}/, state.minSpecialcharsCount)
                                    .replace(/\${minSpecialcharsChars}/, " '" + Password.passwordCharacters['specials'] + "'") : '',
                                state.forbiddenchars ? t('password_js_forbidden_chars_label')
                                    .replace(/\${forbiddenChars}/, "'" + (typeof state.forbiddenchars === "boolean" ? Password.passwordCharacters['forbidden'] : state.forbiddenchars) + "'") : '',
                        ].join(" "))}
                        id='password'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        title={t("toggle password visibility")}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {value.showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label={t('password_label')}
                        name='password'
                        onBlur={validate}
                        onChange={(e) => setState(inputChange(e, state))}
                        onInput={passwordInput}
                        placeholder={t('password_label')}
                        type={value.showPassword ? 'text' : 'password'}
                        value={state.password || ''}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <JezTextEdit
                        autoComplete="new-password"
                        error={!!validation.errorMessage.password_confirm}
                        formhelpertextprops={{focused: true}}
                        fullWidth
                        helperText={validation.errorMessage.password_confirm || t('password_confirm_error')}
                        id='password_confirm'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {params.generate_password && ((
                                        <IconButton
                                            title={t("password_generate_button")}
                                            onClick={generatePasswordClick}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            <RiLockPasswordLine/>
                                        </IconButton>
                                    ) || (
                                        <IconButton
                                            title={t("toggle password visibility")}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {value.showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                                        </IconButton>
                                    ))}
                                </InputAdornment>
                            ),
                        }}
                        label={t('password_confirm_label')}
                        name='password_confirm'
                        onBlur={validate}
                        onChange={(e) => setState(inputChange(e, state))}
                        placeholder={t('password_confirm_label')}
                        required={state.password !== ''}
                        type={value.showPassword ? 'text' : 'password'}
                        value={state.password_confirm}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default PasswordForm;
