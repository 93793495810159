const isHost = (user) => {
    return user && user.roles && (user.roles.includes('ROLE_HOST') || user.roles.includes('ROLE_HOST_ELEGANCE') || user.roles.includes('ROLE_HOST_LUXURY'));
};

const isCustomer = (user) => {
    return user && user.roles && (user.roles.includes('ROLE_USER') || user.roles.includes('ROLE_USER_SILVER') || user.roles.includes('ROLE_USER_GOLD') || user.roles.includes('ROLE_USER_PLATINUM'));
};

const isModerator = (user) => {
    return user && user.roles && (user.roles.includes('ROLE_MODERATOR') || user.roles.includes('ROLE_ADMIN'));
};

const isAdmin = (user) => {
    return user && user.roles && user.roles.includes('ROLE_ADMIN');
};

export { isHost, isCustomer, isModerator, isAdmin };