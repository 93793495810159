import React from 'react';
import './slide.scss';
import 'react-slideshow-image/dist/styles.css';
import useWindowDimensions from '../../hooks/useWindowDimension';

const JezSlide = ({ url }) => {
	const { height, width } = useWindowDimensions();

	let sliderHeight = height - 71;

	if(width < 1125) { sliderHeight = 550; }
	if(width < 469) { sliderHeight = 700; }
	if(width < 326) { sliderHeight = 800; }

	return (
		<div className='slide'>
			<div style={{ backgroundImage: `url(${url})`, height: `${sliderHeight}px` }}></div>
		</div>
	);
};

export default JezSlide;
