import React, {Fragment, useContext, useEffect, useState} from 'react';
import JezHeading from "../../../components/heading/heading";
import {Trans, useTranslation} from "react-i18next";
import {FormControlLabel, Grid, makeStyles} from "@material-ui/core";
import Checkbox from "../../../components/checkbox/checkbox";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Languages from "@cospired/i18n-iso-languages";
import JezModeratedCheckBox from "../fields/moderatedCheckBox";
import {useJezios} from "../../../util/jezios";
import AuthContext from "../../../context/auth";
import {rand} from "../../../util/tools";

Languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));
Languages.registerLocale(require("@cospired/i18n-iso-languages/langs/de.json"));

const useStyles = makeStyles((theme) => ({
    sectionHeadline: {
        margin: '50px 0 15px 0 !important'
    },
    pendingDataContainer: {
        position: 'relative',
    },
    iconContainer: {
        position: 'absolute',
        right: '5px',
        top: '26px',
        color: '#888',
        zIndex: 10,
    },
    statusIcon: {
        margin: '0 0 0 5px',
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    iconContainerSelect: {
        right: 31
    },
    subList: {
        listStyleType: 'none',
        margin: 0,
        '& li': {
            margin: '0 0 10px 0',
            '&:lastChild': {
                margin: 0
            }
        }
    },
    subListItem: {
        width: 190
    },
    check: {
        color: '#888',
        fontSize: 30,
        '& svg': {
            color: '#b4ab6e',
        },
    },
}));

const JezProfileMeetPoint = ({data, verified, onChange, onChangeLocation}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {t} = useTranslation();
    const {auth} = useContext(AuthContext);

    const jezios = useJezios(auth, t);

    const [state, setState] = useState({});
    const [locations, setLocations] = useState([]);

    const fetch = async () => {
        const locationResponse = await jezios.get('locations');
        setLocations(locationResponse.data);
    };

    const change = async (e) => {
        if(onChange) {
            await onChange(e);
        }
    };

    useEffect(() => {
        setState(data);
    }, [data]);

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <JezHeading center variant='profileform' icon={<LocationOnIcon />} className={classes.sectionHeadline}>
                <Trans>profile_meet_group_title</Trans>
            </JezHeading>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={12}>
                    <JezModeratedCheckBox label={t('You can visit me')} field={verified.meet} name={'meet'} onChange={change} />
                    <ul className={classes.subList}>
                        {locations.map((item) => (<li key={rand()}>
                            <FormControlLabel
                                className={classes.check}
                                control={
                                    <Checkbox
                                        className={classes.check}
                                        checked={state.places && state.places.includes(item._id)}
                                        onChange={(e) => onChangeLocation(e)}
                                        name='places'
                                        value={item._id}
                                    />
                                }
                                label={item.name}
                            />
                        </li>))}
                    </ul>
                </Grid>
                <Grid item xs={12} md={4} lg={12}>
                    <JezModeratedCheckBox label={t('I come to you')} field={verified.meet_home} name={'meet_home'} onChange={change} />
                    <ul className={classes.subList}>
                        <li>
                            <JezModeratedCheckBox className={classes.subListItem} label={t('I\'ll go with you')} field={verified.meet_agreement} name={'meet_agreement'} onChange={change} />
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} md={4} lg={12}>
                    <JezModeratedCheckBox label={t('I come to assorted hotels')} field={verified.meet_hotel} name={'meet_hotel'} onChange={change} />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default JezProfileMeetPoint;