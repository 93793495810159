import React from 'react';
import './link.scss';
import { Link } from 'react-router-dom';

const JezLink = ({className, ...props}) => {

    function BuildClassName() {
        let classname = 'link';
        if(props.homelink)
            classname += ' homelink';

        return classname;
    }



    return (
        <Link className={`${BuildClassName()} ${className}`} {...props}>
            {props.children}
        </Link>
    )
};
export default JezLink