/* jshint esversion: 8 */
const {toast} = require('react-toastify');

const handleErrors = (err, setAuth, navigate) => {
    if (err.response) {

        if (err.response.status === 401) {
            toast.error('unauthorized');
            localStorage.removeItem('auth');
            if (setAuth) {
                setAuth(null);
            }
            if (navigate) {
                navigate('/login');
            }
        }
        else {
            if (err.response.data && Array.isArray(err.response.data.errors)) {
                for (let error of err.response.data.errors) {
                    toast.error(error.msg);
                }
            }
            else {
                toast.error(err.response.data);
            }
        }
    }
    else {
        toast.error(err.message);
    }
};

export default handleErrors;
