import React, {Fragment, useContext} from 'react';
import { Link, NavLink } from 'react-router-dom';
import {Link as ScrollLink} from "react-scroll";
import { Trans } from 'react-i18next';
import AuthContext from '../../context/auth';
import SocketContext from "../../context/socket";
import {isAdmin, isHost, isModerator} from "../../util/roles";
import {useNavigate} from 'react-router-dom';

import "./navlink.scss";
import JezSliderbutton from "../sliderbutton/sliderbutton";
import {RiArrowUpCircleLine} from "react-icons/ri";

const JezNavLinks = () => {
    const { auth, setAuth } = useContext(AuthContext);
    const { socket } = useContext(SocketContext);
    const isHomePage = window.location.pathname === '/';

    const navigate = useNavigate();

    const logout = (e) => {
        e.preventDefault();

        if(isModerator(auth) || isHost(auth)) {
            socket.emit(`call/available`, {uid: {destination: null, source: auth.id}, state: 0});
            socket.emit(`io_leave_call_center_waiting_room`, {uid: auth.id});
        }

        socket.emit('io_logout', auth.id);

        localStorage.removeItem('auth');
        setAuth(null);
        if (navigate) {
            navigate('/login');
        }
    };

    return (
        <ul>
            <li>
                {!isHomePage && (
                <NavLink activeClassName='active' exact to='/'>
                    <Trans>home_menu_item</Trans>
                </NavLink>)}
                {isHomePage && (<Fragment>
                    <ScrollLink to={'top'} smooth={true} offset={-71} style={{cursor: 'pointer'}}>
                        <Trans>home_menu_item</Trans>
                    </ScrollLink>
                    <ul className="mobile-home">
                        <li>
                            <ScrollLink to={'locations'} smooth={true} offset={-73}>
                                Leistungen
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to={'ladies'} smooth={true} offset={-73}>
                                Damen
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to={'contact'} smooth={true} offset={-73}>
                                <Trans>btn_label_contact</Trans>
                            </ScrollLink>
                        </li>
                    </ul>
                    </Fragment>
                )}
            </li>
            <li>
                <NavLink activeClassName='active' exact to='/news'>
                    <Trans>news_label</Trans>
                </NavLink>
            </li>
            <li>
                <NavLink activeClassName='active' exact to='/jobs'>
                    <Trans>jobs_label</Trans>
                </NavLink>
            </li>
            <li>
                {auth && (
                    <Link to='/hosts'>
                        <Trans>host_link_link_text</Trans>
                    </Link>
                )}
            </li>
            <li>
                {auth && (
                    <Link to='/locations'>
                        <Trans>locations_link_text</Trans>
                    </Link>
                )}
            </li>
            {auth && isModerator(auth) && (
            <li className={'has-sub-navigation'}>
                    <div className={'sub-menu-header'}>
                        <Trans>moderation_menu_label</Trans>
                    </div>
                    <ul className={'navigation-nav-navigation'}>
                        <li>
                            <Link to='/moderation/user'>
                                <Trans>moderation_user</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link to='/moderation/news'>
                                <Trans>moderation_news</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link to='/moderation/jobs'>
                                <Trans>moderation_job</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link to='/moderation/locations'>
                                <Trans>moderation_locations</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link to='/moderation/hosts/booking'>
                                <Trans>book_host_link_text</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link to='/moderation/hosts/reservation'>
                                <Trans>view_headline_moderation_booking</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link to='/system/google'>
                                <Trans>system_section</Trans>
                            </Link>
                        </li>
                    </ul>
            </li>)}
            {auth && (
                <li className={'has-sub-navigation'}>
                        <div className={'sub-menu-header'}>
                            <Trans>account_menu_label</Trans>
                        </div>
                        <ul className={'navigation-nav-navigation'}>
                            <li>
                                <Link to='/profile'>
                                    <Trans>profile_link_text</Trans>
                                </Link>
                            </li>
                            {auth.roles && auth.roles.includes('ROLE_HOST') && (
                                <li>
                                    <Link to='/hosts/work/time'>
                                        <Trans>work_time_link_text</Trans>
                                    </Link>
                                </li>
                            )}
                            <li>
                                <Link onClick={(e) => logout(e)} to=''>
                                    <Trans>logout_btn_text</Trans>
                                </Link>
                            </li>
                        </ul>
                    )
                </li>
            )}
            {!auth && (
                <li>
                    <Link to='/login'>
                        <Trans>login_btn_text</Trans>
                    </Link>
                </li>
            )}
        </ul>
    );
};

export default JezNavLinks;
