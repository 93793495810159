import React from 'react';
import {TextField} from '@material-ui/core';
import './textedit.scss';

const JezTextEdit = ({onChange, ...props}) => {
    let current = props.value;
    const getClasses = (type) => {
        let classes = `input-type-${type}`;
        if (props.margin)
            classes += ' margin';
        if (props.error)
            classes += ' error';

        return classes.trim();
    };

    const timeChange = function(e) {
        let string = e.target.value.replace(/[^0-9:]/, '');

        if(current.length === 3 && string.length === 2 && current[2] === ':') {
            string = string.substr(0, 1);
        }

        if(string.length === 2) {
            if(string.match(/^[0-9]+$/)) {
                string = `${string}:`
            } else if(string[1] === ':') {
                string = `0${string}`
            }
        }

        if(string.length === 1) {
            if(parseInt(string) > 2) {
                string = `0${string}:`
            }
        }

        string = string.substr(0, 5);

        if(string.length >= 2 && parseInt(string[0]) === 2 && parseInt(string[1]) > 3) {
            string = string.substr(0, 1) + '3' + string.substr(2);
        }

        if(string.length >= 4 && parseInt(string[3]) > 5) {
            string = string.substr(0, 3) + '5' + string.substr(4);
        }

        if(!!onChange) {
            e.target.value = string;
            onChange(e)
        }
    }

    const fillTime = (e) => {
        let string = e.target.value.replace(/[^0-9:]/, '');

        if(string.length === 1) {
            string = `0${string}:`
        }

        if(string.length < 5 && string.length > 2) {
            string = `${string}00`.substr(0, 5);

            if(!!onChange) {
                e.target.value = string;
                onChange(e)
            }
        }
    }

    if(props.type === 'time') {
        props.type = 'text';
        props.onChange = timeChange;
        props.onBlur = fillTime;
    } else {
        props.onChange = onChange;
    }

    return (
        <div className='input-wrap'>
            <TextField
                className={getClasses(props.type)}
                {...props}
            />
            {props.error && <p className='error-text'>{props.errorText}</p>}
        </div>
    );
};

export default JezTextEdit
