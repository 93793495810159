import JezHeading from "../../components/heading/heading";
import React, {Fragment, useContext, useEffect, useState} from "react";
import JezContainer from "../../components/container/container";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Trans, useTranslation} from "react-i18next";
import AuthContext from "../../context/auth";
import {isHost, isModerator} from "../../util/roles";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import JezTable from "../../components/table/table";
import moment from "moment";
import JezButton from "../../components/button/button";
import {toast} from "react-toastify";
import OptInButton from "../../components/OptInButton/OptInButton";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: '48px auto',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    userName: {
        margin: '16px 0'
    },
    chancel: {
        float: "right"
    },
    help: {
        color: '#b4ab6e'
    }
}))

const BookingListForm = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    const {t} = useTranslation();
    const {hostId} = useParams();
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [bookings, setBookings] = useState([]);
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState([]);

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    if (!auth || (auth && !isModerator(auth))) {
        navigate.push('/');
    }

    const fetchUser = async () => {
        if(!!hostId) {
            const response = await axios.get(`/api/users/${hostId}`, {
                headers: {'x-auth-token': auth.token},
            });

            setUser(response.data);
        }
    }

    const fetchBookings = async (page, filter) => {
        try {
            let query = [];
            if(!!filter && !!filter.over_all) {
                query.push(`filter[search]=${filter.over_all[0]}`);
            }

            if(query.length > 0) {
                query = '&' + query.join('&')
            }

            const route = !!hostId ? `/api/users/${hostId}/work/time/book?page=${page}${query}` : `/api/users/work/time/book?page=${page}${query}`
            const response = await axios.get(route, {
                headers: {'x-auth-token': auth.token}
            });

            setBookings(response.data.items);
            setPages(response.data.max);
            setPage(page)
        } catch(err) {
            console.log(err);
        }
    }

    const chancelBooking = async (id, userId, force) => {
        try {
            await axios.delete(`/api/users/${userId}/work/time/book/${id}${!!force ? '?force=1' : ''}`, {
                headers: {'x-auth-token': auth.token}
            });

            if(!!force) {
                toast.success(t('toast_booking_delete'));
            } else {
                toast.success(t('toast_booking_canceled'));
            }

            fetchBookings();
        } catch(err) {
            console.log(err);
        }
    }

    const columns = [
        !!hostId ? {} : {name: 'host', title: t('cell_label_host'), lineBreak: false, render: (item) => { return item.user.username}},
        {name: 'customer', title: t('cell_label_customer'), lineBreak: false, render: (item) => { return item.customer }},
        {name: 'date', title: t('cell_label_date'), lineBreak: false, render: (item) => {
                const start = moment(item.from);
                const end = moment(item.to);
                return (<Fragment>
                    <div>{start.format('DD.MM.YYYY')}</div>
                    <div>{start.format('HH:mm')} bis {end.format('HH:mm')} Uhr</div>
                </Fragment>)
            }},
        {name: 'notice', title: t('cell_label_notice'), render: (item) => {
                return item.notice;
            }},
        {name: 'state', title: t('cell_label_state'), render: (item) => {
                if(item.state === 'CANCELED') {
                    return (<Trans>label_canceled</Trans>)
                }

                return (<Trans>label_booked</Trans>)
            }},
        {name: 'actions', title: '', render: (item) => {
                return (<Fragment>
                    <OptInButton onSubmit={async () => {
                        await chancelBooking(item._id, item.user._id, true);
                    }} className={['error', classes.chancel].join(' ')} id={item._id} label={'Löschen'}>
                        <h4>Möchten Sie diese Buchung löschen?</h4>
                    </OptInButton>
                    {item.state !== 'CANCELED' && (<OptInButton onSubmit={async () => {
                        await chancelBooking(item._id, item.user._id);
                    }} className={['error', classes.chancel].join(' ')} id={item._id} label={'Stornieren'}>
                        <h4>Möchten Sie diese Buchung stornieren?</h4>
                    </OptInButton>)}
                </Fragment>);
            }}
    ];

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchUser();
        fetchBookings();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div style={{margin: '96px 0 0 0'}} />
            <JezContainer padding className={classes.maxWidth1170} header={(isModerator(auth) && !!hostId ? user.username + "'s " : '') + t('view_headline_moderation_booking')}>
                {isModerator(auth) && isHost(user) && (<Fragment>
                    <ul style={{textAlign: 'center'}}>
                        <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18}}><Link to={`/profile/${hostId}`}><Trans>profile_link_text</Trans></Link></li>
                        <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18}}><Link to={`/profile/${hostId}/work/time`}><Trans>work_time_link_text</Trans></Link></li>
                        <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18, color: '#b4ab6e'}}><Trans>view_headline_moderation_booking</Trans></li>
                    </ul>
                </Fragment>)}

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.help}>
                            <div>Nach folgenden Kriterien kann gesucht werden:</div>
                            <ul>
                                <li>Kundenname</li>
                                <li>Nach Datum im Format 03.02.2024 oder 3.3.24</li>
                                <li>Mit den Wort "heute" für alle Termine für heute</li>
                                <li>Mit den Wort "morgen" für alle Termine für morgen</li>
                            </ul>
                        </div>
                        <JezTable rows={bookings} pages={pages} page={page} fetch={fetchBookings} columns={columns} loading={false}/>
                    </Grid>
                </Grid>
            </JezContainer>
        </>);
}

export default BookingListForm;