import React, {Fragment, useState} from "react";
import {Grid} from "@material-ui/core";
import JezTextEdit from "../textedit/textedit";
import {Trans, useTranslation} from "react-i18next";
import JezButton from "../button/button";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: '18px !important'
    },
}));

const JezBookableHostFilter = ({setFilter, args}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    const {t} = useTranslation();

    /**
     * STATES
     */
    const [state, setState] = useState({searchText: '', date: moment().format('YYYY-MM-DD'), time: ''});

    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const submit = () => {
        if(setFilter) {
            setFilter({...state});
        }
    }

    return (<Fragment>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5}>
                <JezTextEdit
                    label={t('label_full_text_search')}
                    type={'text'}
                    placeholder={t('label_full_text_search_placeholder')}
                    name={'searchText'}
                    value={state.searchText}
                    onChange={change}
                />

            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <JezTextEdit
                    label={t('label_date')}
                    type={'date'}
                    placeholder={t('label_date_placeholder')}
                    name={'date'}
                    value={state.date}
                    onChange={change}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <JezTextEdit
                    label={t('label_time')}
                    type={'time'}
                    placeholder={t('label_time_placeholder')}
                    name={'time'}
                    autoComplete={'off'}
                    value={state.time}
                    onChange={change}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
                <JezButton type='button' onClick={submit} margin center small className={classes.button}>
                    <Trans>button_label_filter</Trans>
                </JezButton>
            </Grid>
        </Grid>
    </Fragment>);
}

export default JezBookableHostFilter;