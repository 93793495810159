import React from 'react';
import './heading.scss';

const JezHeading = ({center, variant, children, icon, className, styles}) => {
    switch (variant) {
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
            const CustomHeaderTag = `${variant}`;
            return <CustomHeaderTag className={[className, center && 'center'].filter(x => x).join(" ")} style={styles}>{children}</CustomHeaderTag>;
        case 'profileform':
            return <h4 className={[className, center && 'center', 'profileform', 'limitedHeight'].filter(x => x).join(" ")}><span className={'header-icon'}>{icon}</span><span className={'header-label'}>{children}</span></h4>;
        case 'portalName':
            return <><span className='portalName portalName1'>JEzz</span><span className='portalName portalName2'>ABER</span></>;
        default:
            return <h1 className={[className, center && 'center'].filter(x => x).join(" ")} style={styles}>{children}</h1>;
    }
};

export default JezHeading;
