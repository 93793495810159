import React from 'react'
import './pageinfo.scss'

const JezPageInfo = ({type, children}) => {
    type = type ? type : 'default';


    return (
        <div className={`page-info-container page-info-type-${type}`}>
            {children}
        </div>
    )
};

export default JezPageInfo
