import React from 'react'
import { TextareaAutosize } from '@material-ui/core';
import './textarea.scss';

const JezTextArea = ({children, onChange, ...args}) => {

    const change = async (e) => {
        if(onChange) {
            await onChange(e);
        }
    };

    return (
        <TextareaAutosize rowsMin={2} onBlur={change} defaultValue={children}{...args} />
    )
};

export default JezTextArea
