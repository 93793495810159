import React, {Fragment} from "react";
import {Container, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto',
        position: "relative",
        zIndex: "500000"
    },
}));


const ContentContainer = ({children, ...args}) => {

    const classes = useStyles();

    return (<Container component='div' className={classes.maxWidth1170}>
            <Grid center container className="filterContainer justifyConentCenter">
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Container>
                );
}

ContentContainer.propTypes = {};

ContentContainer.defaultProps = {};

export default ContentContainer;