import React, {Fragment, useContext, useEffect, useState} from "react";
import JezContainer from "../components/container/container";
import {Trans, useTranslation} from "react-i18next";
import JezHeading from "../components/heading/heading";
import {Grid} from "@material-ui/core";
import JezTextEdit from "../components/textedit/textedit";
import JezSelect from "../components/select/select";
import JezButton from "../components/button/button";
import {toast} from "react-toastify";
import AuthContext from "../context/auth";
import axios from 'axios';
import {makeStyles} from "@material-ui/core/styles";
import JezTable from "../components/table/table";
import moment from "moment";
import InfoBox from "../components/infoBox/infoBox";
import {Link, useParams} from "react-router-dom";
import {isModerator} from "../util/roles";


const useStyles = makeStyles((theme) => ({
    addButton: {
        marginTop: '18px !important'
    },
    deleteButton: {
        fontSize: '12px !important',
        background: '#914949 !important',
        float: 'right',
        opacity: 0.6,

        '&:hover': {
            opacity: 1
        }
    },
    infoBox: {
        margin: '30px 0 0 0'
    },
    headline: {
        margin: '25px 0 10px 0'
    },
    lock: {
        color: '#914949'
    }
}));

const WorkingTimeForm = () => {

    /**
     * CSS
     */
    const classes = useStyles();
    let {hostId} = useParams();

    const {t} = useTranslation();

    /**
     * State
     */
    const [state, setState] = useState({date: moment('DD.MM.YYYY'), day: -1, time_from: '', time_to: '', type: 'recurrently', valid: false});
    const [rows, setRows] = useState([]);
    const [tableLoaded, setTableLoaded] = useState(false);
    const [calenderId, setCalenderId] = useState(false);
    const [user, setUser] = useState({});

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    if (auth && ((auth.roles && !isModerator(auth)) || !hostId)) {
        hostId = auth.id;
    }

    const fetchUser = async () => {
        const response = await axios.get(`/api/users/${hostId}`, {
            headers: {'x-auth-token': auth.token},
        });

        setUser(response.data);
    }

    /**
     * HOOKS
     */
    useEffect(() => {
        fetch();
        fetchCalender();
        fetchUser();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const change = (e) => {
        let update = {...state, [e.target.name]: e.target.value};
        update.valid = ((update.type === 'recurrently' && update.day >= 0) || ((update.type === 'one_time' || update.type === 'lock_one_time') && update.date.length > 0)) && update.time_to.length > 0 && update.time_from.length > 0;
        setState(update);
    }

    const save = async () => {
        try {
            const data = {...state, time_from: moment(`1970-01-01T${state.time_from}`), time_to: moment(`1970-01-01T${state.time_to}`)}
            await axios.post(`/api/users/${hostId}/work/time`, state, {
                headers: {'x-auth-token': auth.token},
            });
            setState({date: '', day: -1, time_from: '', time_to: '', type: 'recurrently', valid: false});
            toast.success(t('message_new_work_time_added'));
            fetch();
        } catch(err) {
            console.log(err);
        }
    }

    const fetchCalender = async () => {
        try {
            const response = await axios.get(`/api/users/${hostId}/calender`, {
                headers: {'x-auth-token': auth.token},
            });

            setCalenderId(response.data.id);
        } catch (err) {
            console.log(err);
        }
    }

    const fetch = async () => {
        try {
            const response = await axios.get(`/api/users/${hostId}/work/time`, {
                headers: {'x-auth-token': auth.token},
            });

            setRows(response.data);
            setTableLoaded(true);
        } catch(err) {
            console.log(err);
        }
    }

    const remove = async (e) => {
        try {
            const id = e.target.getAttribute('data-id');
            await axios.delete(`/api/users/${hostId}/work/time/${id}`, {
                headers: {'x-auth-token': auth.token},
            });
            toast.success(t('message_work_time_deleted'));
            fetch();
        } catch(err) {
            console.log(err);
        }
    }

    const columns = [
        {name: 'date', title: t('cell_label_date'), render: (item) => {
            let value = '';
            if(item.type === 'recurrently') {
                   switch(moment(item.from).weekday()) {
                       case 1:
                           value = t('day_monday');
                           break;
                       case 2:
                           value = t('day_tuesday');
                           break;
                       case 3:
                           value = t('day_wednesday');
                           break;
                       case 4:
                           value = t('day_thursday');
                           break;
                       case 5:
                           value = t('day_friday');
                           break;
                       case 6:
                           value = t('day_saturday');
                           break;
                       case 0:
                       case 7:
                           value = t('day_sunday');
                           break;
                   }
            } else {
                const mom = moment(item.from);
                value = mom.format('DD.MM.YYYY')
            }

            return (<span className={item.type === 'lock_one_time' ? classes.lock : ''}>{value}</span>)
        }},
        {name: 'from', title: t('cell_label_from'), render: (item) => {
            const mom = moment(`${item.from}`);

            return (<span className={item.type === 'lock_one_time' ? classes.lock : ''}>{mom.format('HH:mm')}</span>)
        }},
        {name: 'to', title: t('cell_label_to'), render: (item) => {
                const mom = moment(`${item.to}`);

                return (<span className={item.type === 'lock_one_time' ? classes.lock : ''}>{mom.format('HH:mm')}</span>)
            }},
        {name: 'action', title: '', render: (item) => {
            return (<JezButton small className={classes.deleteButton} data-id={item._id} onClick={remove}><Trans>btn_delete</Trans></JezButton>);
            }}
    ];

    return (<Fragment>
        <JezContainer padding style={{margin: '96px auto 32px auto'}} header={(isModerator(auth) ? user.username + "'s " : '') + t('header_working_time')}>
            {isModerator(auth) && !!hostId && (<Fragment>
                <ul style={{textAlign: 'center'}}>
                    <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18}}><Link to={`/profile/${hostId}`}><Trans>profile_link_text</Trans></Link></li>
                    <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18, color: '#b4ab6e'}}><Trans>work_time_link_text</Trans></li>
                    <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18}}><Link to={`/profile/${hostId}/bookings`}><Trans>view_headline_moderation_booking</Trans></Link></li>
                </ul>
            </Fragment>)}

            <JezHeading center variant='h4' className={classes.headline}>
                <Trans>work_time_add</Trans>
            </JezHeading>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={3}>
                    <JezSelect
                        label={t("label_entry_type")}
                        name='type'
                        required
                        onChange={(e) => change(e)}
                        id='type'
                        options={[
                            {key: 'recurrently', value: t('label_recurrently')},
                            {key: 'one_time', value: t('label_one_time')},
                            {key: 'lock_one_time', value: t('label_lock_one_time')},
                        ]}
                        value={state.type}
                    />
                </Grid>
                {state.type === 'recurrently' && (
                    <Grid item xs={12} sm={12} md={3}>
                        <JezSelect
                            label={t("label_day")}
                            name='day'
                            required
                            onChange={(e) => change(e)}
                            id='day'
                            options={[
                                {key: 8, value: t('day_daily')},
                                {key: 1, value: t('day_monday')},
                                {key: 2, value: t('day_tuesday')},
                                {key: 3, value: t('day_wednesday')},
                                {key: 4, value: t('day_thursday')},
                                {key: 5, value: t('day_friday')},
                                {key: 6, value: t('day_saturday')},
                                {key: 7, value: t('day_sunday')},
                            ]}
                            value={state.day}
                        />
                    </Grid>
                )}

                {(state.type === 'one_time' || state.type === 'lock_one_time') && (
                    <Grid item xs={12} sm={12} md={3}>
                        <JezTextEdit
                            label={t('label_date')}
                            type={'date'}
                            placeholder={t('label_date_placeholder')}
                            name={'date'}
                            value={state.date}
                            onChange={change}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={2}>
                    <JezTextEdit
                        label={t('label_time_from')}
                        type={'time'}
                        placeholder={t('label_time_from_placeholder')}
                        name={'time_from'}
                        autoComplete={'off'}
                        value={state.time_from}
                        onChange={change}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <JezTextEdit
                        label={t('label_time_to')}
                        type={'time'}
                        placeholder={t('label_time_to_placeholder')}
                        name={'time_to'}
                        autoComplete={'off'}
                        value={state.time_to}
                        onChange={change}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <JezButton type='button' onClick={save} margin center small disabled={!state.valid} className={classes.addButton}>
                        <Trans>submit_button</Trans>
                    </JezButton>
                </Grid>
            </Grid>

            {calenderId && (
                <InfoBox className={classes.infoBox}><a href={`https://calendar.google.com/calendar/u/0?cid=${calenderId}`} target={'_blank'}><Trans>link_text_click_her_to_access_calender</Trans></a></InfoBox>
            )}
            <JezHeading center variant='h4' className={classes.headline}>
                <Trans>added_work_time</Trans>
            </JezHeading>
            <JezTable rows={rows} columns={columns} loading={!tableLoaded}/>
        </JezContainer>
    </Fragment>);
}

export default WorkingTimeForm;