import React, {useEffect, useState} from 'react';
import {Grid, makeStyles, TextField} from '@material-ui/core';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#fff',
        width: '100px',
        display: 'inline-block',
        margin: '9px 0',
        verticalAlign: 'top',
        cursor: 'default'
    },
    range: {
        '& ::-webkit-calendar-picker-indicator': {
            width: 24,
            height: 24,
            cursor: 'pointer',
            filter: 'invert(1)'
        }
    }
}));

const JezTimeRange = ({id, label, from, to, onChange, ...args}) => {
    const [state, setState] = useState({from: '', to: '', id: 0});

    /**
     * CSS
     */
    const classes = useStyles();

    const blur = async (e) => {
        const update = {...state, [e.target.name]: e.target.value};

        if(onChange && update.from.trim().length > 0 && update.to.trim().length > 0) {
            await onChange(update);
        }

        setState(update);
    };

    const parse = (data) => {
        const match = data.match(/([0-9]{1,2})(:([0-9]{1,2}))?/);
        if(match) {
            return {
                minutes: match[3] ? parseInt(match[3]) : 0,
                hours: match[1] ? parseInt(match[1]) : 0,
            }
        }

        return false;
    };

    const leadingZero = (data) => {
        if (data < 10) {
            data = `0${data}`;
        }

        return data;
    };

    const format = (data) => {
        data = parse(data);

        if(data) {
            const hours = leadingZero(data.hours);
            const minutes = leadingZero(data.minutes);

            return `${hours}:${minutes}`;
        }

        return '';
    };

    const fetch = (from, to, id) => {
        setState({from: from, to: to, id: id});
    };

    useEffect(() => {
        fetch(from, to, id);
    }, [from, to, id]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} lg={2} className={classes.label}>
                {label}
            </Grid>
            <Grid item xs={12} sm={12} lg={5} className={classes.range}>
                <TextField onBlur={blur} name={'from'} type={'time'} {...args} defaultValue={format(from)}/>
            </Grid>
            <Grid item xs={12} sm={12} lg={5} className={classes.range}>
                <TextField onBlur={blur} name={'to'} type={'time'} {...args} defaultValue={format(to)}/>
            </Grid>
        </Grid>
    );
};

JezTimeRange.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired
};

export default JezTimeRange;