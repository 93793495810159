import { Trans } from 'react-i18next';
import React from 'react'
import './footer.scss'
import JezIconText from '../icontext/icontext';
import {FiMapPin, FiPhone, FiMail} from 'react-icons/fi';
import {AiOutlineMobile, AiOutlineWhatsApp} from "react-icons/ai";
import {Link} from "react-router-dom";

const JezFooter = () => {
    return (
        <footer>
            <div className={'footer-wrapper'}>
                <div>
                    <span>
                        <JezIconText icon={<FiMapPin />}>Lieblgasse 20A, 1220 Wien</JezIconText>
                    </span>

                    <span style={{whiteSpace: 'pre'}}>
                        <a href="phone:+436641947614"><span className='icontext'>{<AiOutlineMobile />} +43 664 194 76 14</span></a>
                        <a href="https://api.whatsapp.com/send/?phone=+436641947614&text=&app_absent=0" target="_blank" rel="noreferrer"><AiOutlineWhatsApp/></a>
                    </span>

                    <span>
                        <a href='mailto:info@jazzaber.com'><JezIconText icon={<FiMail />}>info@jezzaber.com</JezIconText></a>
                    </span>
                </div>
                    <ul className={'footer-links'}>
                        <li><Link to={'/impressum'}><Trans>link_label_impress</Trans></Link></li>
                        <li><Link to={'/datenschutz'}><Trans>link_label_privacy</Trans></Link></li>
                    </ul>
                <div className={'copyright'}>
                    © 2021 <Trans>portal_title</Trans>
                </div>
            </div>
        </footer>
    )
};

export default JezFooter
