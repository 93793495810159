import React from 'react'
import JezHostGrid from  '../components/hostgrid/hostgrid';
import {Trans, useTranslation} from "react-i18next";
import JezHeading from "../components/heading/heading";
import JezSpacer from "../components/spacer/spacer";
import mask from "../assets/img/mask_76x27x96.png";
import ModifyAblePageContent from "../components/ModifyAblePageContent/ModifyAblePageContent";
import ContentContainer from "../components/contentContainer/contentContainer";


const HostList = ( ) => {
    const {t} = useTranslation();

    return (
        <div style={{margin: '116px 0 48px 0px'}}>
            <JezHeading center variant='h2'>
                <ModifyAblePageContent context={'content-section-our-hosts-headline'} allowTeaser={false} defaultValue={t('our_hosts')} classes={'text-color-white'}/>
            </JezHeading>
            <JezHeading center variant='h4'>
                <ModifyAblePageContent context={'content-section-our-hosts-sub-headline'} allowTeaser={false}/>
            </JezHeading>
            <JezSpacer icon={<img src={mask} alt='mask' />} />
            <ContentContainer><ModifyAblePageContent context={'content-before-hosts'} /></ContentContainer>
            <JezHostGrid searchAble={true}/>
            <ContentContainer><ModifyAblePageContent context={'content-after-hosts'} /></ContentContainer>
        </div>
    );
};

export default HostList
