import React, {Fragment, useState} from "react";
import {Trans} from "react-i18next";
//import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component

import "./teaser.scss";
import {rand} from "../../util/tools";


const Teaser = ({teaser, children, length,...args}) => {
    const id = rand();

    /**
     * State
     */
    const [collapse, setCollapse] = useState(false);


    /**
     * Functions
     */
    const createTeaser = (text) => {
        const parts = text.split("<br />");

        return parts[0].substr(0, length);
    }

    return <div className={'teaser-container'} id={`teaser-${id}`}>
        {collapse && (<Fragment>
            {children}
            <br />
            <span className={'collapse-button'} onClick={() => setCollapse(!collapse)}><Trans>label_read_less</Trans></span>
        </Fragment>)}
        {!collapse && (<Fragment>
            {teaser} ...
            <br />
            <span className={'collapse-button'} onClick={() => setCollapse(!collapse)}><Trans>label_read_more</Trans></span>
        </Fragment>)}
    </div>
}

Teaser.propTypes = {
};

Teaser.defaultProps = {
    length: 1000
};

export default Teaser;