import React from "react";

const ExternalLink = ({href, target, ...props}) => {

    return (
        <a href={href} target={target}>
            {props.children}
        </a>
    )
};

export default ExternalLink