import React from 'react'
import './checkbox.scss'
import {rand} from "../../util/tools";

const JezCheckbox = ({id, checked, name, value, label, ...other}) => {
    id = id ? id : rand();

    return (
        <label className="checkbox-container" htmlFor={id}>{other.children}
            <input id={id} type="checkbox" name={name} value={value} onChange={other.onChange} checked={checked === 'true' || checked === 1 || checked === true} {...other}/>
            <span className="checkmark" />
            <span className={'label-value'}>{label}</span>
        </label>
    )
};

export default JezCheckbox
