import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import axios from 'axios';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AiFillHeart } from 'react-icons/ai';
import { BsPerson } from 'react-icons/bs';
import {useNavigate, useParams} from 'react-router-dom';
import '../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss';
import SocketContext from '../../context/socket';
import JezButton from '../button/button';
import JezContainer from '../container/container';
import JezHeading from '../heading/heading';
import JezSpacer from '../spacer/spacer';
import './profile.scss';
import JezGallery from '../gallery/gallery';
import AuthContext from '../../context/auth';
import { isHost, isModerator } from '../../util/roles';
import { scrollTop } from '../../util/scrollTop';
import { rand, raw } from '../../util/tools';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Modal from '@material-ui/core/Modal';
import JezLink from '../link/link';
import JezHostGrid from "../hostgrid/hostgrid";
import mask from "../../assets/img/mask_76x27x96.png";
import {FiPhone} from "react-icons/fi";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: '0px',
    },
    check: {
        color: '#fff',
        fontSize: 18,
        '& svg': {
            color: '#807154',
        },
        '& >span:first-child': {
            padding: '0 9px',
        },
    },
    actionIcons: {
        margin: '15px 0 0 0',
    },
    icon: {
        fontSize: '20px !important',
        margin: '0 0 -5px 10px',
        color: '#b4ab6e',
    },
    notAvailableMessage: {
        border: '1px solid #b4ab6e',
        padding: '10px 15px',
        textAlign: 'center',
        color: '#b4ab6e',
    },
    loginMessage: {
        border: '1px solid #b4ab6e',
        padding: '10px 15px',
        textAlign: 'center',
        color: '#b4ab6e',
        margin: '50px 0 0 0',
        fontWeight: 'bold',
    },
    online: {
        color: '#b4ab6e',
    },
}));

const JezProfile = () => {
    const { t } = useTranslation();
    const { hostId } = useParams();
    const navigate = useNavigate();

    const { socket } = useContext(SocketContext);
    const { auth } = useContext(AuthContext);

    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const [profile, setProfile] = useState({
        size: '',
        cup_size: '',
        description: '',
        public_services: [],
        meet: null,
        meet_swinger: null,
        meet_hotel: null,
        meet_home: null,
        meet_agreement: null,
    });
    const [online, setOnline] = useState(false);
    const [anonymousUid, setAnonymousUid] = useState(false);

    const [user, setUser] = useState({
        username: '',
    });
    const [services, setServices] = useState([]);
    const [images, setImages] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const onlineRef = useRef();
    onlineRef.current = { data: online, set: setOnline };

    /**
     * CONTROLS
     */
    // const change = (e) => {
    //     setState({...state, [e.target.name]: e.target.value});
    // };

    /**
     * QUERIES
     */
    const fetchData = async () => {
        try {
            const user = await axios.get(`/api/hosts/${hostId}`);
            const imageResponse = await axios.get(`/api/hosts/${hostId}/avatar`);

            let list = [];
            for (const item of imageResponse.data) {
                list.push({
                    src: item.media,
                    thumbnail: item.media,
                    videoId: item.video,
                    thumbnailWidth: item.video ? 342 : 168,
                    thumbnailHeight: 260,
                });
            }

            setImages(list);

            if (user.data.redirect) {
                navigate(user.data.redirect);
            }

            const profile = await axios.get(`/api/users/${hostId}/profile`);
            setUser(user.data);
            setProfile(profile.data);
        } catch (err) {
            if (navigate) {
                navigate('/');
            }
        }
    };

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const fetchServices = async () => {
        try {
            const response = await axios.get(`/api/categories`);
            setServices(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const meetVideoCall = (video, contactType) => {
        const doc = document.getElementsByClassName('initiate-call');
        if (doc.length > 0) {
            doc[0].setAttribute('data-video', video ? 1 : 0);
            doc[0].setAttribute('data-contact-type', contactType);
            doc[0].click();
        }
    };

    const modalHandleClose = () => {
        setModalOpen(false);
    };

    const urlValidator = (url) => {
        if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
            return url;
        }

        return `http://${url}`;
    };

    const isOnline = () => {
        socket.emit('io_req_member_is_online', { uid: { source: anonymousUid, destination: hostId } });
    };

    const updateInterval = () => {
        window.setInterval(
            (e) => {
                if (document.getElementById('profile_real_live_meet')) {
                    const available = document.getElementsByClassName('status-available').length > 0;
                    if (available && document.getElementsByClassName('initiate-call').length > 0) {
                        document.getElementById('profile_real_live_meet').removeAttribute('disabled');
                        document.getElementById('profile_web_cam').removeAttribute('disabled');
                        document.getElementById('profile_call_phone').removeAttribute('disabled');
                        if (!onlineRef.current.data) {
                            onlineRef.current.set(true);
                        }
                    } else {
                        document.getElementById('profile_real_live_meet').setAttribute('disabled', 'disabled');
                        document.getElementById('profile_web_cam').setAttribute('disabled', 'disabled');
                        document.getElementById('profile_call_phone').setAttribute('disabled', 'disabled');
                        if (onlineRef.current.data) {
                            onlineRef.current.set(false);
                        }
                    }
                }
            },
            1000,
            profile.availability
        );
    };

    const openInfoModal = () => {
        setModalOpen(true);
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchData();
        fetchServices();

        scrollTop();
        updateInterval();
    }, [socket, hostId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (socket !== null) {
            isOnline();
        }
    }, [socket, anonymousUid]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (socket !== null) {
            socket.on(`io_res_member_is_online_to_${anonymousUid}`, (data, peer, options) => {
                setOnline(data.state);
            });

            if (!anonymousUid) {
                const anonymousUid = auth ? auth._id : rand();
                setAnonymousUid(anonymousUid);
            }
        }

        if (socket !== null && auth) {
            // if (isCustomer(auth)) {
            //     socket.on(`io_call_is_available_${hostId}`, function (event) {
            //         if (hostId === event.uid || event.mod) {
            //             setOnline(event.state);
            //         }
            //     });
            // }

            socket.on(`io_call_is_available_${hostId}`, function (event) {
                if (hostId === event.uid || event.mod) {
                    setOnline(event.state);
                }
            });
        }

        return () => {
            if (socket !== null) {
                socket.off(`io_res_member_is_online_to_${anonymousUid}`);
            }

            if (socket !== null && auth) {
                socket.off(`io_call_is_available_${hostId}`);
            }
        };
    }, [socket, auth]); // eslint-disable-line react-hooks/exhaustive-deps

    const openMessageContext = function () {
        const messageBox = document.getElementsByClassName('toogle-messenger')[0];

        if (messageBox) {
            if (!document.getElementById('messenger-container').classList.contains('view')) messageBox.click();
            const user = document.getElementById(`messages-${hostId}`);

            if (!user) {
                document.getElementById('messenger-reload').click();
            }

            window.setTimeout(
                (hostId) => {
                    document.getElementById(`messages-${hostId}`).click();
                },
                1000,
                hostId
            );
        }
    };


    useEffect(() => {
        if (profile.description) {
            whatsappLink();
        }
    }, [profile.description]);

    const whatsappLink = () => {
        const modifiedContent = profile.description.replace(/<a.*?href="(.*?)".*?>(.*?)<\/a>/g, (match, href, text) => {
            if (href.includes('wa.me')) {
                return (
                    `<a class="whatsappLink" href="${href}">
                        ${text}
                        <span class="waicon">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1569683925316" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <defs></defs>
                                <path d="M713.5 599.9c-10.9-5.6-65.2-32.2-75.3-35.8-10.1-3.8-17.5-5.6-24.8 5.6-7.4 11.1-28.4 35.8-35 43.3-6.4 7.4-12.9 8.3-23.8 2.8-64.8-32.4-107.3-57.8-150-131.1-11.3-19.5 11.3-18.1 32.4-60.2 3.6-7.4 1.8-13.7-1-19.3-2.8-5.6-24.8-59.8-34-81.9-8.9-21.5-18.1-18.5-24.8-18.9-6.4-0.4-13.7-0.4-21.1-0.4-7.4 0-19.3 2.8-29.4 13.7-10.1 11.1-38.6 37.8-38.6 92s39.5 106.7 44.9 114.1c5.6 7.4 77.7 118.6 188.4 166.5 70 30.2 97.4 32.8 132.4 27.6 21.3-3.2 65.2-26.6 74.3-52.5 9.1-25.8 9.1-47.9 6.4-52.5-2.7-4.9-10.1-7.7-21-13z"></path>
                                <path d="M925.2 338.4c-22.6-53.7-55-101.9-96.3-143.3-41.3-41.3-89.5-73.8-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6 0.3-119.3 12.3-174.5 35.9-53.3 22.8-101.1 55.2-142 96.5-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9 0.3 69.4 16.9 138.3 48 199.9v152c0 25.4 20.6 46 46 46h152.1c61.6 31.1 130.5 47.7 199.9 48h2.1c59.9 0 118-11.6 172.7-34.3 53.5-22.3 101.6-54.3 142.8-95.2 41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5 0.3-60.9-11.5-120-34.8-175.6z m-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-0.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-0.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-0.6 99.6-39.7 192.9-110.1 262.7z"></path>
                            </svg>
                        </span>
                    </a>`
                );
            } else {
                return match;
            }
        });

        setProfile(prevItem => ({
            ...prevItem,
            description: modifiedContent
        }));
    };

    return (
        <>
            <div style={{ margin: '96px 0 0 0' }} />
            <JezContainer className={'maxWidth1170'} padding margin>
                <JezHeading center variant='h2'>
                    {user.username}
                </JezHeading>
                <JezHeading center variant='h4'>
                    {profile.position || "Model's Profile"}
                </JezHeading>
                <JezSpacer icon={<BsPerson />} />
                <Grid container spacing={1} className={classes.margin}>
                    <Grid item xs={12} sm={12} className={'onlyMobile'}>
                        {profile.phone && (
                            <a
                                target='_blank'
                                href={`https://wa.me/${profile.phone
                                    .replaceAll('+', '')
                                    .replaceAll(' ', '')
                                    .replaceAll('/', '')
                                    .replaceAll('(', '')
                                    .replaceAll(')', '')
                                    .trim()}?text=Hallo+${
                                    user.username
                                }%21%0D%0AIch+komme+von+deiner+SedCard+${encodeURI(
                                    window.location.href
                                )}+auf+jezzaber.com%0D%0AI+come+from+your+SedCard+${encodeURI(
                                    window.location.href
                                )}+on+jezzaber.com%0A`}
                                className='btn margin primary full-width  normal'
                                style={{fontWeight: "unset"}}
                            >
                                {profile.phone}
                                <span className="waicon" style={{marginLeft: "5px"}}>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1569683925316" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <defs></defs>
                                        <path d="M713.5 599.9c-10.9-5.6-65.2-32.2-75.3-35.8-10.1-3.8-17.5-5.6-24.8 5.6-7.4 11.1-28.4 35.8-35 43.3-6.4 7.4-12.9 8.3-23.8 2.8-64.8-32.4-107.3-57.8-150-131.1-11.3-19.5 11.3-18.1 32.4-60.2 3.6-7.4 1.8-13.7-1-19.3-2.8-5.6-24.8-59.8-34-81.9-8.9-21.5-18.1-18.5-24.8-18.9-6.4-0.4-13.7-0.4-21.1-0.4-7.4 0-19.3 2.8-29.4 13.7-10.1 11.1-38.6 37.8-38.6 92s39.5 106.7 44.9 114.1c5.6 7.4 77.7 118.6 188.4 166.5 70 30.2 97.4 32.8 132.4 27.6 21.3-3.2 65.2-26.6 74.3-52.5 9.1-25.8 9.1-47.9 6.4-52.5-2.7-4.9-10.1-7.7-21-13z"></path>
                                        <path d="M925.2 338.4c-22.6-53.7-55-101.9-96.3-143.3-41.3-41.3-89.5-73.8-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6 0.3-119.3 12.3-174.5 35.9-53.3 22.8-101.1 55.2-142 96.5-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9 0.3 69.4 16.9 138.3 48 199.9v152c0 25.4 20.6 46 46 46h152.1c61.6 31.1 130.5 47.7 199.9 48h2.1c59.9 0 118-11.6 172.7-34.3 53.5-22.3 101.6-54.3 142.8-95.2 41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5 0.3-60.9-11.5-120-34.8-175.6z m-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-0.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-0.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-0.6 99.6-39.7 192.9-110.1 262.7z"></path>
                                    </svg>
                                </span>
                            </a>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <JezGallery images={images} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div onClick={toggleExpand} className={'cursorPointer'}>
                            <JezHeading variant='profileform'>
                                Details {expanded ? <span>[-]</span> : <span>[+]</span>}
                            </JezHeading>
                        </div>

                        <ul className={`listStyleNone ${expanded ? '' : 'd-none'}`}>
                            <li>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <span className={'strong'}>
                                            <Trans>status_label</Trans>:
                                        </span>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <span
                                            className={`text ${
                                                profile.availability ? `status-available ${classes.online}` : ''
                                            }`}
                                        >
                                            {profile.availability ? t('available_label') : t('not_available_label')}
                                        </span>
                                    </Grid>
                                </Grid>
                            </li>
                            <li>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <span className={'strong'}>
                                            <Trans>username_label</Trans>:
                                        </span>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <span className={'text'}>{user.username}</span>
                                    </Grid>
                                </Grid>
                            </li>
                            {profile.size && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>body_height_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{profile.size} cm</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}
                            {profile.cup_size && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>cup_size_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.cup_size)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}
                            {profile.gender && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>gender_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.gender)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.hair_color && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>hair_color_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.hair_color)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}
                            {profile.sex_type && profile.sex_type.length > 0 && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>sex_type_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>
                                                {profile.sex_type instanceof Array
                                                    ? t(profile.sex_type.join(','))
                                                    : t(profile.sex_type)}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.eye_color && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>eye_color_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.eye_color)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}
                            {profile.nationality && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>nationality_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.nationality)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.city && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>city_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{profile.city}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.location && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>meeting_point_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>
                                                <Trans>{profile.location}</Trans>
                                            </span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.radius > 0 && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>radius_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{profile.radius} km</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.url && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>url_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <a href={urlValidator(profile.url)} rel='noreferrer' target={'_blank'}>
                                                {profile.url_name ? profile.url_name : profile.url}
                                            </a>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.languages &&
                                profile.languages instanceof Array &&
                                profile.languages.length > 0 && (
                                    <li>
                                        <Grid container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className={'strong'}>
                                                    <Trans>languages_label</Trans>:
                                                </span>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <span className={'text'}>
                                                    {profile.languages.map((item) => t(item)).join(', ')}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </li>
                                )}

                            {profile.cup_type && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>cup_type_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.cup_type)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {isModerator(user) && profile.phone && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>phone_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.phone)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {isModerator(user) && profile.mobile && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>mobile_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{t(profile.mobile)}</span>
                                            {profile && profile.whatsapp && (
                                                <Fragment>
                                                    <a
                                                        href={`https://api.whatsapp.com/send/?phone=${profile.mobile.replace(
                                                            /[ ]+/g,
                                                            ''
                                                        )}&text=&app_absent=0`}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        <WhatsAppIcon title={'Whatsapp'} className={classes.icon} />
                                                    </a>
                                                </Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.birth_year && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>age_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>
                                                {t('age_text').replace(
                                                    /\${age}/,
                                                    moment().format('YYYY') - profile.birth_year
                                                )}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}

                            {profile.prices && (
                                <li>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <span className={'strong'}>
                                                <Trans>prices_label</Trans>:
                                            </span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span className={'text'}>{raw(profile.prices)}</span>
                                        </Grid>
                                    </Grid>
                                </li>
                            )}
                        </ul>
                        <div className={'button-list'}>
                            {!auth > 0 && (
                                <>
                                    {profile.phone && (
                                        <a
                                            target='_blank'
                                            href={`https://wa.me/${profile.phone
                                                .replaceAll('+', '')
                                                .replaceAll(' ', '')
                                                .replaceAll('/', '')
                                                .replaceAll('(', '')
                                                .replaceAll(')', '')
                                                .trim()}?text=Hallo+${
                                                user.username
                                            }%21%0D%0AIch+komme+von+deiner+SedCard+${encodeURI(
                                                window.location.href
                                            )}+auf+jezzaber.com%0D%0AI+come+from+your+SedCard+${encodeURI(
                                                window.location.href
                                            )}+on+jezzaber.com%0A`}
                                            className='btn margin primary full-width  normal'
                                            style={{fontWeight: "unset"}}
                                        >
                                            {profile.phone}
                                            <span className="waicon" style={{marginLeft: "5px"}}>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1569683925316" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <defs></defs>
                                                    <path d="M713.5 599.9c-10.9-5.6-65.2-32.2-75.3-35.8-10.1-3.8-17.5-5.6-24.8 5.6-7.4 11.1-28.4 35.8-35 43.3-6.4 7.4-12.9 8.3-23.8 2.8-64.8-32.4-107.3-57.8-150-131.1-11.3-19.5 11.3-18.1 32.4-60.2 3.6-7.4 1.8-13.7-1-19.3-2.8-5.6-24.8-59.8-34-81.9-8.9-21.5-18.1-18.5-24.8-18.9-6.4-0.4-13.7-0.4-21.1-0.4-7.4 0-19.3 2.8-29.4 13.7-10.1 11.1-38.6 37.8-38.6 92s39.5 106.7 44.9 114.1c5.6 7.4 77.7 118.6 188.4 166.5 70 30.2 97.4 32.8 132.4 27.6 21.3-3.2 65.2-26.6 74.3-52.5 9.1-25.8 9.1-47.9 6.4-52.5-2.7-4.9-10.1-7.7-21-13z"></path>
                                                    <path d="M925.2 338.4c-22.6-53.7-55-101.9-96.3-143.3-41.3-41.3-89.5-73.8-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6 0.3-119.3 12.3-174.5 35.9-53.3 22.8-101.1 55.2-142 96.5-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9 0.3 69.4 16.9 138.3 48 199.9v152c0 25.4 20.6 46 46 46h152.1c61.6 31.1 130.5 47.7 199.9 48h2.1c59.9 0 118-11.6 172.7-34.3 53.5-22.3 101.6-54.3 142.8-95.2 41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5 0.3-60.9-11.5-120-34.8-175.6z m-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-0.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-0.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-0.6 99.6-39.7 192.9-110.1 262.7z"></path>
                                                </svg>
                                            </span>
                                        </a>
                                    )}
                                    {profile.phone && (
                                        <a
                                            target='_blank'
                                            href={`tel:${profile.phone}`}
                                            className='btn margin primary full-width normal button-flex-center'
                                            style={{fontWeight: "unset"}}
                                        >
                                            {profile.phone}
                                            <span className="waicon" style={{marginLeft: "5px"}}>
                                                <FiPhone />
                                            </span>
                                        </a>
                                    )}
                                    <JezButton disabled margin onClick={openInfoModal} fullwidth>
                                        <Trans>profile_action_message</Trans>
                                    </JezButton>

                                    <JezButton
                                        disabled
                                        margin
                                        onClick={() => {
                                            openInfoModal();
                                        }}
                                        fullwidth
                                    >
                                        <Trans>profile_real_live_meet</Trans>
                                    </JezButton>
                                    <JezButton
                                        disabled
                                        margin
                                        onClick={() => {
                                            openInfoModal();
                                        }}
                                        fullwidth
                                    >
                                        <Trans>profile_web_cam</Trans>
                                    </JezButton>
                                    <JezButton disabled margin fullwidth>
                                        <Trans>profile_watch_me_web_cam</Trans>
                                    </JezButton>
                                    <JezButton
                                        fullwidth
                                        margin
                                        disabled
                                        onClick={() => {
                                            openInfoModal();
                                        }}
                                    >
                                        <Trans>profile_phone</Trans>
                                    </JezButton>
                                    <JezButton disabled margin fullwidth>
                                        <Trans>profile_shop</Trans>
                                    </JezButton>
                                </>
                            )}

                            {auth && isHost(user) && (
                                <>
                                    {profile.phone && (
                                        <a
                                            target='_blank'
                                            href={`https://wa.me/${profile.phone
                                                .replaceAll('+', '')
                                                .replaceAll(' ', '')
                                                .replaceAll('/', '')
                                                .replaceAll('(', '')
                                                .replaceAll(')', '')
                                                .trim()}?text=Hallo+${
                                                user.username
                                            }%21%0D%0AIch+komme+von+deiner+SedCard+${encodeURI(
                                                window.location.href
                                            )}+auf+jezzaber.com%0D%0AI+come+from+your+SedCard+${encodeURI(
                                                window.location.href
                                            )}+on+jezzaber.com%0A`}
                                            className='btn margin primary full-width  normal'
                                            style={{fontWeight: "unset"}}
                                        >
                                            {profile.phone}
                                            <span className="waicon" style={{marginLeft: "5px"}}>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1569683925316" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <defs></defs>
                                                    <path d="M713.5 599.9c-10.9-5.6-65.2-32.2-75.3-35.8-10.1-3.8-17.5-5.6-24.8 5.6-7.4 11.1-28.4 35.8-35 43.3-6.4 7.4-12.9 8.3-23.8 2.8-64.8-32.4-107.3-57.8-150-131.1-11.3-19.5 11.3-18.1 32.4-60.2 3.6-7.4 1.8-13.7-1-19.3-2.8-5.6-24.8-59.8-34-81.9-8.9-21.5-18.1-18.5-24.8-18.9-6.4-0.4-13.7-0.4-21.1-0.4-7.4 0-19.3 2.8-29.4 13.7-10.1 11.1-38.6 37.8-38.6 92s39.5 106.7 44.9 114.1c5.6 7.4 77.7 118.6 188.4 166.5 70 30.2 97.4 32.8 132.4 27.6 21.3-3.2 65.2-26.6 74.3-52.5 9.1-25.8 9.1-47.9 6.4-52.5-2.7-4.9-10.1-7.7-21-13z"></path>
                                                    <path d="M925.2 338.4c-22.6-53.7-55-101.9-96.3-143.3-41.3-41.3-89.5-73.8-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6 0.3-119.3 12.3-174.5 35.9-53.3 22.8-101.1 55.2-142 96.5-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9 0.3 69.4 16.9 138.3 48 199.9v152c0 25.4 20.6 46 46 46h152.1c61.6 31.1 130.5 47.7 199.9 48h2.1c59.9 0 118-11.6 172.7-34.3 53.5-22.3 101.6-54.3 142.8-95.2 41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5 0.3-60.9-11.5-120-34.8-175.6z m-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-0.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-0.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-0.6 99.6-39.7 192.9-110.1 262.7z"></path>
                                                </svg>
                                            </span>
                                        </a>
                                    )}
                                    {profile.phone && (
                                        <a
                                            target='_blank'
                                            href={`tel:${profile.phone}`}
                                            className='btn margin primary full-width normal button-flex-center'
                                            style={{fontWeight: "unset"}}
                                        >
                                            {profile.phone}
                                            <span className="waicon" style={{marginLeft: "5px"}}>
                                                <FiPhone />
                                            </span>
                                        </a>
                                    )}
                                    {profile.availability === 0 && profile.availability_reason.length > 0 && (
                                        <div className={classes.notAvailableMessage}>{profile.availability_reason}</div>
                                    )}
                                    <JezButton margin onClick={openMessageContext} fullwidth>
                                        <Trans>profile_action_message</Trans>
                                    </JezButton>
                                    <JezButton
                                        id={'profile_real_live_meet'}
                                        margin
                                        onClick={() => {
                                            meetVideoCall(true, t('real_live_meet'));
                                        }}
                                        fullwidth
                                    >
                                        <Trans>profile_real_live_meet</Trans>
                                    </JezButton>
                                    <JezButton
                                        id={'profile_web_cam'}
                                        margin
                                        onClick={() => {
                                            meetVideoCall(true, t('video_meeting'));
                                        }}
                                        fullwidth
                                    >
                                        <Trans>profile_web_cam</Trans>
                                    </JezButton>
                                    <JezButton disabled margin fullwidth>
                                        <Trans>profile_watch_me_web_cam</Trans>
                                    </JezButton>
                                    <JezButton
                                        id={'profile_call_phone'}
                                        fullwidth
                                        margin
                                        onClick={() => {
                                            meetVideoCall(false, t('phone_call'));
                                        }}
                                    >
                                        <Trans>profile_phone</Trans>
                                    </JezButton>
                                    <JezButton disabled margin fullwidth>
                                        <Trans>profile_shop</Trans>
                                    </JezButton>
                                </>
                            )}
                            {auth && isModerator(user) && (
                                <>
                                    <JezButton disabled margin onClick={openMessageContext} fullwidth>
                                        <Trans>profile_action_message</Trans>
                                    </JezButton>
                                    <JezButton disabled margin fullwidth>
                                        <Trans>profile_phone</Trans>
                                    </JezButton>
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
                {profile && profile.description && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                <JezHeading center variant='profileform'>
                                    <Trans>about_me</Trans>
                                </JezHeading>
                                <JezSpacer icon={<FormatAlignCenterIcon />} />
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                                <span className={'text'}>
                                    {profile && profile.description && parse(profile.description)}
                                </span>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={2}>
                    {(profile.meet_swinger ||
                        profile.meet ||
                        profile.meet_home ||
                        profile.meet_hotel ||
                        profile.meet_agreement) && (
                        <Grid item xs={12}>
                            <JezHeading center variant='profileform'>
                                <Trans>profile_meet_group_title</Trans>
                            </JezHeading>
                            <JezSpacer icon={<LocationOnIcon />} />
                        </Grid>
                    )}
                    {profile.meet && (
                        <Grid item xs={4}>
                            <FormControlLabel
                                className={classes.check}
                                control={
                                    <Checkbox
                                        className={classes.check}
                                        icon={<LocationOnIcon />}
                                        checkedIcon={<LocationOnIcon />}
                                        checked={profile.meet}
                                    />
                                }
                                label={t('You can visit me')}
                            />
                        </Grid>
                    )}
                    {profile.meet_swinger && (
                        <Grid item xs={4}>
                            <FormControlLabel
                                className={classes.check}
                                control={
                                    <Checkbox
                                        className={classes.check}
                                        icon={<LocationOnIcon />}
                                        checkedIcon={<LocationOnIcon />}
                                        checked={profile.meet_swinger}
                                    />
                                }
                                label={t('I come to your swingers club meeting')}
                            />
                        </Grid>
                    )}
                    {profile.meet_hotel && (
                        <Grid item xs={4}>
                            <FormControlLabel
                                className={classes.check}
                                control={
                                    <Checkbox
                                        className={classes.check}
                                        icon={<LocationOnIcon />}
                                        checkedIcon={<LocationOnIcon />}
                                        checked={profile.meet_hotel}
                                    />
                                }
                                label={'I come to your hotel'}
                            />
                        </Grid>
                    )}
                    {profile.meet_home && (
                        <Grid item xs={4}>
                            <FormControlLabel
                                className={classes.check}
                                control={
                                    <Checkbox
                                        className={classes.check}
                                        icon={<LocationOnIcon />}
                                        checkedIcon={<LocationOnIcon />}
                                        checked={profile.meet_home}
                                    />
                                }
                                label={'I come to your home'}
                            />
                        </Grid>
                    )}
                    {profile.meet_agreement && (
                        <Grid item xs={4}>
                            <FormControlLabel
                                className={classes.check}
                                control={
                                    <Checkbox
                                        className={classes.check}
                                        icon={<LocationOnIcon />}
                                        checkedIcon={<LocationOnIcon />}
                                        checked={profile.meet_agreement}
                                    />
                                }
                                label={t("I'll go with you")}
                            />
                        </Grid>
                    )}
                </Grid>

                {profile.public_services && profile.public_services.length > 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <JezHeading center variant='profileform'>
                                <Trans>services_label</Trans>
                            </JezHeading>
                            <JezSpacer icon={<AiFillHeart />} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {services.filter(serviceCat => { return  serviceCat.services.filter(serviceItem => profile.public_services.includes(serviceItem._id)).length > 0}).map((serviceCat) => (
                                    <Grid key={serviceCat._id} item xs={12}>
                                        <Grid item xs={12}>
                                            <JezHeading variant='profileform'>{serviceCat.name}</JezHeading>
                                        </Grid>

                                        <Grid container spacing={2} className={classes.margin}>
                                            {serviceCat.services.map(
                                                (service) =>
                                                    profile.public_services &&
                                                    profile.public_services.includes(service._id) && (
                                                        <Grid key={service._id} item xs={12} sm={6}>
                                                            <FormControlLabel
                                                                className={classes.check}
                                                                control={
                                                                    <Checkbox
                                                                        className={classes.check}
                                                                        icon={<AiFillHeart />}
                                                                        checkedIcon={<AiFillHeart />}
                                                                        checked={
                                                                            profile.public_services &&
                                                                            profile.public_services.includes(
                                                                                service._id
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={service.name}
                                                            />
                                                        </Grid>
                                                    )
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {profile.unwanted_services && profile.unwanted_services.length > 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <JezHeading center variant='profileform'>
                                <Trans>nogo_services_label</Trans>
                            </JezHeading>
                            <JezSpacer icon={<AiFillHeart />} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {services.map((serviceCat) => (
                                    <Grid key={serviceCat._id} item xs={12}>
                                        <Grid container spacing={2} className={classes.margin}>
                                            {serviceCat.services.map(
                                                (service) =>
                                                    profile.unwanted_services &&
                                                    profile.unwanted_services.includes(service._id) && (
                                                        <Grid key={service._id} item xs={12} sm={3}>
                                                            <FormControlLabel
                                                                className={classes.check}
                                                                control={
                                                                    <Checkbox
                                                                        className={classes.check}
                                                                        icon={<AiFillHeart />}
                                                                        checkedIcon={<AiFillHeart />}
                                                                        checked={
                                                                            profile.unwanted_services &&
                                                                            profile.unwanted_services.includes(
                                                                                service._id
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={service.name}
                                                            />
                                                        </Grid>
                                                    )
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </JezContainer>
            <JezContainer className={'maxWidth1170'} padding margin>
                <JezHeading center variant='h2' className={classes.splitter}>
                    <Trans>more_hosts</Trans>
                </JezHeading>
                <JezSpacer icon={<img src={mask} alt='mask'/>}/>
                <JezHostGrid limit={'6'} justify={'center'} />
            </JezContainer>

            <Modal
                open={modalOpen}
                onClose={modalHandleClose}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <div className={'login-required-modal'}>
                    <h3 className={'headline'}>
                        <Trans>login_required_headline</Trans>
                    </h3>

                    <div className={'legal-age-message'}>{raw(t('login_required_message'))}</div>

                    <div className={'modal-action-bar'}>
                        <JezLink className={`btn-link btn primary margin small`} to={'/login'} margin small>
                            <Trans>label_login</Trans>
                        </JezLink>
                        <JezButton margin small onClick={modalHandleClose}>
                            <Trans>label_ok</Trans>
                        </JezButton>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default JezProfile;
