import React, {Fragment, useContext, useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import {FiUpload} from 'react-icons/fi';
import {AiFillDelete} from 'react-icons/ai';
import {BiLeftArrow, BiRightArrow} from 'react-icons/bi';
import {makeStyles} from "@material-ui/core";

import TimerSharpIcon from '@material-ui/icons/TimerSharp';
import DoneIcon from '@material-ui/icons/Done';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import './imageUpload.scss';
import {useJezios} from "../../util/jezios";
import {Trans, useTranslation} from "react-i18next";
import AuthContext from '../../context/auth';
import {rand, stringFormat} from "../../util/tools";
import JezTextEdit from "../textedit/textedit";
import JezButton from "../button/button";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    check: {
        color: '#888',
        fontSize: 18,
        '& svg': {
            color: '#b4ab6e',
        },
    },
    margin: {
        marginTop: 12,
    },
    pendingDataContainer: {
        position: 'relative',
    },
    statusIcon: {
        color: '#fff',
        fontSize: 16
    },
    reviewAcceptIcon: {
        color: '#888',
        cursor: 'pointer',
        fontSize: 25
    },
    reviewRejectIcon: {
        color: '#888',
        cursor: 'pointer',
        fontSize: 25
    },
    iconContainer: {
        position: 'absolute',
        right: '5px',
        top: '26px',
        color: '#888',
        zIndex: 10,
    },
    iconBtn: {
        background: '#b4ab6e',
        borderRadius: 3,
        color: '#fff',
        width: 20,
        height: 20,
        textAlign: 'center',
        lineHeight: '27px'
    },
    reviewImageContainer: {
        position: 'absolute',
        right: '5px;',
        top: 5,
    },
    reviewChecked: {
        color: '#807154',
        cursor: 'pointer',
        fontSize: 25
    },
    reviewRejectChecked: {
        color: '#914949'
    }
}));

const JezImageUpload = ({images, self, onChange, imageCount, videoCount}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    const [display, setDisplay] = useState(false);
    const [collection, setCollection] = useState([]);
    const [embedVideo, setEmbedVideo] = useState({show: false, id:'', error: ''});
    const [playVideos, setPlayVideos] = useState([]);
    const [max, setMax] = useState({images: 0, videos: 0});

    /**
     * Hooks
     */
    useEffect(() => {
        setMax({
            images: imageCount !== undefined ? imageCount : 1000,
            videos: videoCount !== undefined ? videoCount : 1000
        });
    }, [imageCount, videoCount]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    const request = useJezios(auth, t);

    const change = async (imageList, addUpdateIndex) => {
    	const data = normalizeData(imageList);
        if (onChange) {
            await onChange(data);
        }

        setCollection(data);
    };

    const fetch = async (images) => {
        let data = [];
        for (let ii = 0; ii < images.length; ii++) {
        	const image = images[ii];

        	if(image instanceof Object) {
				data.push(image);
			} else {
				const response = await request.get(`pictures/${image}?base64=1`);
				if (response.data) {
					data.push({src: response.data, id: image});
				}
			}
        }

        setCollection(normalizeData(data));
    };

    const verify = async (e) => {
        let id = e.target.getAttribute('data-id');
        let index = e.target.getAttribute('data-index');
        let status = e.target.classList.contains('data-accept');

        if (!id) {
            id = e.target.parentNode.getAttribute('data-id');
            index = e.target.parentNode.getAttribute('data-index');
            status = e.target.parentNode.classList.contains('data-accept');
        }

        await request.put(
            `/moderation/data/${id}`,
            {status: status ? 'verified' : 'denied'}
        );

        let update = [...collection];
        update[index].status = status ? 1 : -1;
        setCollection(update);
    };

    const order = (current, changed) => {
    	const update = [...collection];

		changed = changed < 0 ? 0 : changed;
		changed = changed >= collection.length ? collection.length - 1 : changed;
		const index = update.map(function(e) { return e.order; }).indexOf(changed);

		if(index !== -1) {
			update[index].order = current;
		}

		update[current].order = changed;

		change(update);
	};

    const normalizeData = (data) => {

    	//add fields to data
    	for(let ii = 0; ii < data.length; ii++) {
            data[ii].src = data[ii].src.media ? data[ii].src : {media: data[ii].src, video_id: ''}
 			data[ii].deleted = data[ii].deleted !== undefined ? data[ii].deleted : false;
			data[ii].order = data[ii].order !== undefined ? data[ii].order : ii;
			data[ii].file = data[ii].file !== undefined ? data[ii].file : false;
            data[ii].video_id = data[ii].video_id !== undefined ? data[ii].video_id : false;
			data[ii]._id = data[ii]._id !== undefined ? data[ii]._id : rand();
		}

    	for(let ii = 0; ii < collection.length; ii++) {
    		const item = collection[ii];
    		if(!item.file) {
                const index = data.map(function(e) { return e._id; }).indexOf(item._id);

                if(index === -1) {
                    data.push({...item, deleted: true});
                }
            }
		}

    	return data.sort((a, b) => { return  a.order - b.order});
	};

    const invertShowEmbedState = () => {
        setEmbedVideo({...embedVideo, show: !embedVideo.show});
    }

    const createFileObject = async (id) => {
        const response = await axios.get(`/api/youtube/thumbnail/${id}`);
        const buffer = Buffer.from(response.data, 'base64');
        return {
            file: new File([buffer], id, {type:'jpg'}),
            src: `data:image/jpeg;base64,${response.data}`
        };
    }

    const submitEmbedVideo = async (e, imageList) => {
        const file = await createFileObject(embedVideo.id);

        imageList.push({
            ...file,
            src: {
                media: file.src,
                video: embedVideo.id,
            },
            video_id: embedVideo.id,
        });
        const data = normalizeData(imageList);
        if (onChange) {
            await onChange(data);
        }

        setCollection(data);
        setEmbedVideo({show: false, id:'', error: ''});
    }

    const playVideo = (id) => {
        const list = [...playVideos];
        list.push(id);
        setPlayVideos(list);
    }

    const validateLink = (e) => {
        const value = e.target.value;
        const regex = new RegExp('\(https|http\)://www.youtube.[a-z]{2,3}/watch\\?v=(.*)');
        const match = value.match(regex);

        if(!(match && match[2])) {
            setEmbedVideo({...embedVideo, error: t('invalid_video_link')});
            return false;
        } else {
            setEmbedVideo({...embedVideo, error: '', id: match[2]});

            return true;
        }
    }

    const normalize = () => {

    }

    /**
     * Hooks
     */
    useEffect(() => {
        fetch(images);
    }, [images]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='image-upload-container'>
            <ImageUploading multiple={true} value={collection.filter((item) => { return !item.deleted })} onChange={async (evt) => {
                await change(evt);
                setDisplay(!display);
            }} maxNumber={max.images + max.videos} dataURLKey='src'>
                {({imageList, onImageUpload, onImageRemove, isDragging, dragProps, errors}) => (
                    <Fragment>
                        <div className='upload__image-wrapper'>
                            {imageList.map((image, index) => (!image.deleted && (
                                <div key={index} className={' image-item ' + classes.pendingDataContainer}>
                                    <div className={classes.reviewImageContainer + ' ' + (playVideos.indexOf(image._id) !== -1 ? 'video-player-element-top' : '')}>
                                        {self &&
                                        image.status !== null &&
                                        image.status === 0 && (
                                            <div className={classes.iconBtn} title={t('image_review')}><TimerSharpIcon className={classes.statusIcon}/></div>
                                        )}
                                        {self &&
                                        image.status !== null &&
                                        image.status === 1 && (<div className={classes.iconBtn} title={t('image_accepted')}><DoneIcon className={classes.statusIcon}/></div>)}
                                        {self &&
                                        image.status !== null &&
                                        image.status === -1 && (
                                            <div className={classes.iconBtn} title={t('image_rejected')}><ThumbDownIcon className={classes.statusIcon}/></div>
                                        )}
                                        {!self && image.status !== null && (
                                            <CheckBoxIcon
                                                data-id={image.id}
                                                data-index={index}
                                                className={
                                                    classes.reviewAcceptIcon +
                                                    ' data-accept ' +
                                                    (image.status === 1 ? classes.reviewChecked : '')
                                                }
                                                onClick={verify}
                                            />
                                        )}
                                        {!self && image.status !== null && (
                                            <IndeterminateCheckBoxIcon
                                                data-id={image.id}
                                                data-index={index}
                                                className={
                                                    classes.reviewRejectIcon +
                                                    ' ' +
                                                    (image.status === -1 ? classes.reviewRejectChecked : '')
                                                }
                                                onClick={verify}
                                            />
                                        )}
                                    </div>

                                    {(!image.video_id || playVideos.indexOf(image._id) === -1) && (
                                        <Fragment>
                                            <img src={image.src.media} alt='' width='180'/>
                                            {image.video_id && (
                                                <div className={'play-video-icon'} onClick={() => playVideo(image._id)} />
                                            )}
                                        </Fragment>
                                    )}
                                    {playVideos.indexOf(image._id) !== -1 && (
                                        <iframe width="400" height="296" src={`https://www.youtube.com/embed/${image.video_id}?autoplay=1&controls=0&showinfo=0`}
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
                                    )}
                                    <div title={t('image_delete')} className={'remove-image-button ' + (playVideos.indexOf(image._id) !== -1 ? 'video-player-element-bottom' : '')} onClick={() => {
                                        onImageRemove(index);
                                    }}><AiFillDelete/></div>
									<div className={'order-container ' + (playVideos.indexOf(image._id) !== -1 ? 'video-player-element-bottom' : '')}>
										<div className={`arrow-btn ${index === 0 ? 'disabled' : ''}`} onClick={(e) => { order(index, index - 1) }} title={t('order_left')}><BiLeftArrow /></div>
										<div className={`arrow-btn ${index < (imageList.length - 1) ? '' : 'disabled'}`} onClick={(e) => { order(index, index + 1) }} title={t('order_right')}><BiRightArrow /></div>
									</div>
                                </div>
                            )))}

                            {imageList.length === 0 && (<div className={'image-no-items image-item ' + classes.pendingDataContainer}><Trans>image_no_images</Trans></div>)}
                        </div>
                            <div className={'image-upload-context'}>
                                {errors && (<div className={'image-error-context'}>
                                    {errors.maxNumber && (<div className={'image-upload-max-count-error'}>{stringFormat(t('images_error_too_many'), [max.images])}</div>)}
                                </div>)}
                                {!embedVideo.show && (<Fragment>
                                    {max.images > imageList.filter((item) => !item.video_id).length && (
                                    <a className={'image-upload-input ' + (max.videos - imageList.filter((item) => item.video_id).length ? '' : 'fullwidth')} style={isDragging ? {color: '#807154'} : undefined} onClick={() => {
                                        onImageUpload();
                                    }} {...dragProps} id={'imageupload-button'}>
                                        <FiUpload/><br/>
                                        <Trans>media_image_upload</Trans>
                                    </a>)}
                                    {max.videos > imageList.filter((item) => item.video_id).length && (
                                    <a className={'youtube-embed-input ' + (max.images - imageList.filter((item) => !item.video_id).length ? '' : 'fullwidth')} style={isDragging ? {color: '#807154'} : undefined} onClick={() => {
                                        invertShowEmbedState();
                                    }} {...dragProps} id={'youtube-embed-button'}>
                                        <FiUpload/><br/>
                                        <Trans>media_youtube_link</Trans>
                                    </a>)}
                                </Fragment>)}
                                {embedVideo.show && (
                                <div className={'video-embed-input-context'}>
                                    <JezTextEdit
                                        error={!!embedVideo.error}
                                        helperText={embedVideo.error || ''}
                                        onChange={validateLink}
                                        label={t('placeholder_video_url')}
                                        placeholder={t('placeholder_video_url')}
                                    />
                                    <JezButton small secondary type={'error'} onClick={invertShowEmbedState} ><Trans>btn_label_cancel</Trans></JezButton>
                                    <JezButton small secondary id={'video-embed-submit'} disabled={!!embedVideo.error || !embedVideo.id.length} onClick={(e) => { e.preventDefault(); submitEmbedVideo(e, imageList); return false; } }><Trans>btn_label_add</Trans></JezButton>
                                </div>)}
                            </div>
                    </Fragment>
                )}
            </ImageUploading>
        </div>
    );
};

export default JezImageUpload;
