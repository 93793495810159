import {Grid} from "@material-ui/core";
import JezTextEdit from "../../../../components/textedit/textedit";
import React, {useContext, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import axios from "axios";
import AuthContext from "../../../../context/auth";
import JezCenter from "../../../../components/center/center";
import JezButton from "../../../../components/button/button";
import {toast} from "react-toastify";

const MapSettingSection = () => {
    const {t} = useTranslation();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * States
     */
    const [state, setState] = useState({map_api_key: ''});

    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const fetch = async () => {
        try {
            const response = await axios.get(`/api/system`, {
                headers: {'x-auth-token': auth.token},
            });

            setState({...state, map_api_key: response.data.google.map_api_key});

        } catch (err) {
            console.log(err);
        }
    }

    const save = async () => {
        try {
            await axios.put('/api/system', {...state, section: 'google_map'}, {
                headers: {'x-auth-token': auth.token},
            });

            fetch();

            toast.success(t('message_form_saved'));
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                    <JezTextEdit
                        label={t('google_map_api_key')}
                        type={'text'}
                        placeholder={t("google_map_api_key_placeholder")}
                        name={'map_api_key'}
                        value={state.map_api_key}
                        onChange={change}
                    />
                </Grid>
                <Grid item xs={12}>
                    <JezCenter>
                        <JezButton type='submit' onClick={save} margin center>
                            <Trans>save_btn_text</Trans>
                        </JezButton>
                    </JezCenter>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MapSettingSection;