import JezHeading from "../../../components/heading/heading";
import {Trans, useTranslation} from "react-i18next";
import JezSpacer from "../../../components/spacer/spacer";
import mask from "../../../assets/img/mask_76x27x96.png";
import JezHomeLocationGrid from "../../../components/homelocationgrid/homelocationgrid";
import React from "react";
import {makeStyles} from "@material-ui/core";
import ModifyAblePageContent from "../../../components/ModifyAblePageContent/ModifyAblePageContent";
import ContentContainer from "../../../components/contentContainer/contentContainer";

const useStyles = makeStyles((theme) => ({
    black: {
        background: '#181818;',
        overflow: 'hidden'
    },
    padding: {
        padding: '50px 0 70px',
        position: 'relative'
    },
    splitter: {
        margin: '48px 0 0 0'
    }
}));

const ServiceSection = () => {
    /**
     * CSS
     */
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div id={'locations'} className={`${classes.black} ${classes.padding}`}>
            <JezHeading center variant='h2' className={classes.splitter}>
                <ModifyAblePageContent context={'content-section-location-headline'} allowTeaser={false} defaultValue={t('our_locations')} classes={'text-color-white'}/>
            </JezHeading>
            <JezHeading center variant='h4'>
                <ModifyAblePageContent context={'content-section-location-sub-headline'} allowTeaser={false} defaultValue={t('our_locations_text')}/>
            </JezHeading>
            <JezSpacer icon={<img src={mask} alt='mask'/>}/>
            <ContentContainer><ModifyAblePageContent context={'content-before-home-locations'} /></ContentContainer>
            <JezHomeLocationGrid/>
            <ContentContainer><ModifyAblePageContent context={'content-after-home-locations'} /></ContentContainer>
        </div>
    );
}

export default ServiceSection;