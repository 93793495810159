import React, {Fragment, useEffect} from 'react'
import Modal from "@material-ui/core/Modal";
import {makeStyles} from '@material-ui/core/styles';
import {Trans} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        zIndex: 100,
        top: '50%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        boxShadow: theme.shadows[5],
        color: '#b4ab6e',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(2, 4, 3),
        border: '1px solid #b4ab6e',
        borderRadius: 3,
        overflow: 'auto',
        maxHeight: '80%',
        overflowWrap: 'break-word',
        '&.xs': {
            width: 400,
        },
        '&.md': {
            width: 800,
        },
        '&.lg': {
            width: 1200
        }
    },
    close: {
        background: '#b4ab6e',
        border: '1px solid #b4ab6e',
        color: '#fff',
        margin: '5px',
        padding: '2px 3px;',
        cursor: 'pointer',
    },
    btnContainer: {
        textAlign: 'center'
    }
}));

const JezModal = ({className, fullHeight, isOpen, onClose, children, icon, label, size, ...args}) => {
    size = size ? size : 'xs';

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

        if(onClose) {
            onClose();
        }
    };

    useEffect(() => {
        setOpen(!!isOpen);
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            {label && (
                <button type="button" onClick={handleOpen}>
                    {label}
                </button>
            )}
            {icon && (
                <span onClick={handleOpen}>
                    {icon}
                </span>
            )}
            <Modal
                style={{zIndex: 999999}}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={[classes.paper, size].join(' ')}>
                    {children}
                    <div className={classes.btnContainer}>
                        <button className={classes.close} onClick={handleClose}><Trans>button_close</Trans></button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default JezModal;