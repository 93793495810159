import React, {Fragment, useEffect, useState} from 'react'
import Gallery from "react-grid-gallery";
import "./gallery.scss";
import {rand} from "../../util/tools";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {Trans} from "react-i18next";
import {FaRegPlayCircle} from "react-icons/fa";

const JezGallery = ({images, trigger, ...other}) => {

    /**
     * States
     */
    const [mediaIndex, setMediaIndex] = useState(-1);
    const [playVideos, setPlayVideos] = useState([]);
    const [videoSize, setVideoSize] = useState({width: 0, height: 0})
    const [startX, setStartX] = useState(0);
    const [swipeOffset, setSwipeOffset] = useState(0);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    /**
     * Hooks
     */
    useEffect(() => {
        const width = getMaxWidth();
        const height = width/16*9;
        const maxHeight = getMaxHeight() - 80;

        setVideoSize({width: width, height: height > maxHeight ? maxHeight : height});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    /**
     * Functions
     */
    const playVideo = (id) => {
        const list = [...playVideos];
        list.push(id);
        setPlayVideos(list);
    }

    const getMaxHeight = () => {
        return document.documentElement.clientHeight * 0.75;
    }

    const getMaxWidth = () => {
        const viewportWidth = document.documentElement.clientWidth;

        if (viewportWidth > 768) {
            return viewportWidth * 0.5;
        } else if (viewportWidth > 468) {
            return viewportWidth * 0.75;
        }

        return viewportWidth - 100;
    }

    const next = () => {

        const index = images.length > (mediaIndex + 1) ? mediaIndex + 1 : 0;

        setMediaIndex(index);
    }

    const prev = () => {
        const index = (mediaIndex - 1) > 0 ? mediaIndex - 1 : images.length - 1;
        setMediaIndex(index);
    }

    if(trigger) {
        trigger.openGallery = function(index) {
            setMediaIndex(index);
        }
    }

    const handleStart = (event) => {
        setStartX(event.clientX || event.touches[0].clientX);
    };

    const handleMove = (event) => {
        const currentX = event.clientX || event.touches[0].clientX;
        const deltaX = currentX - startX;
        setSwipeOffset(deltaX);
    };

    const handleEnd = (event) => {
        const endX = event.clientX || event.changedTouches[0].clientX;
        const deltaX = endX - startX;

        if (deltaX > 50) {
            // Swipe right
            prev();
        } else if (deltaX < -50) {
            // Swipe left
            next();
        }
        setSwipeOffset(0);
    };

    const handleSetMediaIndex = (index) => {
        setMediaIndex(index);
    };

    return (<div className={'media-gallery'}>
        {images.map((item, index) => (<div key={rand()} className={'media-gallery-item'}>
            <img onClick={() => {
                setMediaIndex(index)
            }} className={'media-gallery-item-image'} src={item.thumbnail}
                 style={{width: item.thumbnailWidth, height: item.thumbnailHeight}}/>
            {item.videoId && (<FaRegPlayCircle className={'gallery-play-video-icon'} onClick={() => setMediaIndex(index) }/>)}
        </div>))}
        {mediaIndex >= 0 && (<div className={'gallery-view-container'}>
            <div
                className={'gallery-container'} style={{height: getMaxHeight(), width: "auto"}}
                onTouchStart={(e) => handleStart(e)}
                onTouchMove={(e) => handleMove(e)}
                onTouchEnd={(e) => handleEnd(e)}
                onMouseDown={(e) => handleStart(e)}
                onMouseUp={(e) => handleEnd(e)}
            >
                <BiLeftArrow
                    className={'prev-media'}
                    onTouchStart={(e) => {
                        if(isIOS) {
                            e.preventDefault();
                            prev();
                        }
                    }}
                    onClick={
                        () => {
                            if(!isIOS) {
                                prev();
                            }
                        }
                    }
                />
                <BiRightArrow
                    className={'next-media'}
                    onTouchStart={(e) => {
                        if(isIOS) {
                            e.preventDefault();
                            next();
                        }
                    }}
                    onClick={() => {
                        if(!isIOS) {
                            next();
                        }
                    }}
                />
                <div className={'header-box'}>
                    <div
                        className={'gallery-counter no-select'}>{mediaIndex + 1}&nbsp;/&nbsp;{images.length}</div>
                </div>
                {playVideos.indexOf(images[mediaIndex].videoId) !== -1 && (
                    <iframe width={videoSize.width} height={videoSize.height} src={`https://www.youtube.com/embed/${images[mediaIndex].videoId}?autoplay=1`}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                )}
                {(!images[mediaIndex].videoId || playVideos.indexOf(images[mediaIndex].videoId) === -1) && (
                    <Fragment>
                        {images[mediaIndex].videoId == null || images[mediaIndex].videoId == undefined || images[mediaIndex].videoId == "" ? (
                            <img className={'image-gallery-fullscreen-media no-select'} style={{transform: `translateX(${swipeOffset}px)`, transition: 'transform 0.3s ease', maxWidth: videoSize.width}} src={images[mediaIndex].src}/>
                            )
                        : (
                            <div className={'video-container'}>
                                <img className={'image-gallery-fullscreen-media no-select'}  style={{width: videoSize.width, height: videoSize.height}} src={images[mediaIndex].src}/>
                                <div className={'play-video-icon'} onClick={() => playVideo(images[mediaIndex].videoId)} />
                            </div>
                        )}
                    </Fragment>)}
                <div className={'action-box'}>
                    <span className={'close-icon no-select'}
                          onTouchStart={(e) => {
                              if(isIOS) {
                                  e.preventDefault();
                                  handleSetMediaIndex(-1);
                              }
                          }}
                          onClick={() => setMediaIndex(-1)}
                    >
                        <Trans>gallery_close</Trans>
                    </span>
                </div>
            </div>
        </div>)}
    </div>);

    return (
        <Gallery images={images} onClick={() => {
        }} onTouchEnd={() => {
        }} backdropClosesModal={true} enableImageSelection={false} {...other}/>
    )
};

export default JezGallery
