import React, {Fragment, useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import axios from "axios";

//Icons
import AnnouncementIcon from '@material-ui/icons/Announcement';
import RateReviewIcon from '@material-ui/icons/RateReview';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TimerSharpIcon from '@material-ui/icons/TimerSharp';
import DoneIcon from '@material-ui/icons/Done';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ChatIcon from '@material-ui/icons/Chat';

import AuthContext from '../../../context/auth';
import Popover from "@material-ui/core/Popover/Popover";
import PropTypes from "prop-types";
import JezSelect from "../../../components/select/select";

const useStyles = makeStyles((theme) => ({
    fieldWrap: {
        position: 'relative'
    },
    reviewAcceptIcon: {
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    reviewRejectIcon: {
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    iconContainer: {
        position: 'absolute',
        right: '5px',
        top: '23px',
        color: '#888',
        zIndex: 10,
    },
    iconContainerSelect: {
        right: 31
    },
    moderatorMessage: {
        color: '#eee',
        padding: '5px 10px;'
    },
    statusIcon: {
        margin: '0 0 0 5px',
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    reviewChecked: {
        color: '#807154',
    },
    popover: {
        padding: '5px 10px',
    },
    popoverHeadline: {
        color: '#b4ab6e',
        margin: '5px 10px'
    },
    popoverTextArea: {
        background: 'transparent',
        border: '1px solid #b4ab6e',
        boxSizing: 'border-box',
        padding: '5px 10px',
        margin: '5px 10px',
        color: '#fff',
        width: 300,
        height: 100
    },
    popoverSubmit: {
        fontSize: '12px',
        margin: '0 0 5px 10px'
    },
    noticeSaveBtn: {
        padding: '3px 10px !important',
        margin: '0 10px 5px !important',
        float: 'right !important'
    },
    rejectedValue: {
        '& label': {
            color: '#900 !important'
        },
        '& input': {
            border: '1px solid #900 !important',
        }
    },
    unit: {
        verticalAlign: 'top',
        color: '#888'
    },
}));

const JezzPopover = withStyles({
    paper: {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        background: 'rgba(0,0,0,0.4)',
        border: `1px solid #b4ab6e !important`,
        borderRadius: '3px',
    },
})(Popover);

const JezModeratedSelect = ({label, name, unit, field, onChange, options, ...args}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATE
     */
    const [state, setState] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();
    const {hostId} = useParams();
    const moderator = auth && hostId && hostId !== auth.id;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const updateNotice = async (e) => {
        const id = field._id;

        setState({...state, notice: e.target.value});

        try {
            await axios.put(`/api/moderation/data/${id}/notice`, {message: e.target.value}, {
                headers: {
                    'x-auth-token': auth.token
                },
            });

            //toast.success(t('notice_successful_saved'));
        } catch (errors) {
            for (let err of errors.response.data.errors) {
                toast.error(t(err.msg));
            }
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const change = async (e) => {
        if (onChange) {
            await onChange(e);
        }
    };

    const updateFieldState = async function (e) {
        let id = e.target.getAttribute('data-id');
        let status = e.target.classList.contains('data-accept');

        if (!id) {
            id = e.target.parentNode.getAttribute('data-id');
            status = e.target.parentNode.classList.contains('data-accept');
        }

        try {
            await axios.put(
                `/api/moderation/data/${id}`,
                {status: status ? 'verified' : 'denied'},
                {
                    headers: {'x-auth-token': auth.token},
                }
            );

            setState({...state, status: status ? 1 : -1});

            //toast.success(t('message_profile_saved'));
        } catch (errors) {
            for (let err of errors.response.data.errors) {
                toast.error(t(err.msg));
            }
        }

        return false;
    };

    useEffect(() => {
        setState(field);
    }, [field]);

    return (
        <Fragment>
            <div className={classes.fieldWrap}>
                <div className={`${classes.iconContainer} ${classes.iconContainerSelect}`}>
                    {unit && (<span className={classes.unit}>{unit}</span>)}
                    {moderator && state && (
                        <span title={t('Field notice')}><ChatIcon aria-describedby={id} onClick={handleClick}
                                                                  className={classes.statusIcon}/></span>)}
                    {!moderator && (
                        <span title={t('Field is required')}><AnnouncementIcon className={classes.statusIcon}/></span>)}
                    {!moderator && (
                        <span title={t('Field is private and not visible in public profile')}><VisibilityOffIcon
                            className={classes.statusIcon}/></span>)}
                    {!moderator && !state && (
                        <span title={t('Field is moderated')}><RateReviewIcon className={classes.statusIcon}/></span>)}
                    {!moderator && state && state.status === 0 && (
                        <span title={t('Field content is in review')}><TimerSharpIcon
                            className={classes.statusIcon}/></span>)}
                    {!moderator && state && state.status === 1 && (
                        <span title={t('Accepted')}><DoneIcon className={classes.statusIcon}/></span>)}
                    {!moderator && state && state.status === -1 && (
                        <span title={t('Rejected')}><ThumbDownIcon className={classes.statusIcon}/></span>)}
                    {moderator && state && (
                        <CheckBoxIcon
                            data-id={state._id}
                            className={
                                classes.reviewAcceptIcon +
                                ' data-accept ' +
                                (state.status === 1 ? classes.reviewChecked : '')
                            }
                            onClick={updateFieldState}
                        />
                    )}
                    {moderator && state && (
                        <IndeterminateCheckBoxIcon
                            data-id={state._id}
                            className={
                                classes.reviewRejectIcon +
                                ' ' +
                                (state.status === -1 ? classes.reviewChecked : '')
                            }
                            onClick={updateFieldState}
                        />
                    )}
                </div>
                <JezSelect
                    name={name}
                    onChange={(e) => change(e)}
                    label={label}
                    placeholder=''
                    value={field && field.value}
                    options={options}
                    className={`profileform ${auth && hostId === auth.id && state && state.status === -1 ? classes.rejectedValue : ''}`}
                    {...args}
                />
                {auth && hostId === auth.id && state && state.notice.length > 0 && (state.status === 0 || state.status === -1) && (<div className={classes.moderatorMessage}><strong><Trans>label_moderator_message</Trans></strong>{state.notice}</div>)}
            </div>
            <JezzPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popover}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <h3 className={classes.popoverHeadline}><Trans>Notice</Trans></h3>
                <div>
                    <textarea defaultValue={state && state.notice} className={classes.popoverTextArea} placeholder={t('notice_placeholder')}
                              onKeyUp={updateNotice}/>
                </div>
                <button onClick={handleClose} className={`btn primary ${classes.noticeSaveBtn}`}><Trans>btn_save</Trans>
                </button>
            </JezzPopover>
        </Fragment>
    );
};

JezModeratedSelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

export default JezModeratedSelect;