import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import AuthContext from "../../../../context/auth";
import {Trans, useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import JezTextEdit from "../../../../components/textedit/textedit";
import InfoBox from "../../../../components/infoBox/infoBox";
import ExternalLink from "../../../../components/externallink/externalLink";
import moment from "moment";
import JezButton from "../../../../components/button/button";
import JezCenter from "../../../../components/center/center";

const CalenderSettingSection = () => {
    const {t} = useTranslation();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');
    const navigate = useNavigate();

    /**
     * States
     */
    const [state, setState] = useState({client_id: '', secret: '', auth: false});
    const [initial, setInitial] = useState('');

    /**
     *
     * Hooks
     */
    useEffect(() => {
        fetch();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        persistToken();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const save = async () => {
        try {
            await axios.put('/api/system', {...state, section: 'google_calendar'}, {
                headers: {'x-auth-token': auth.token},
            });

            const response = await axios.get('/api/system/google/auth', {
                headers: {'x-auth-token': auth.token},
            });

            if(response.data) {
                setState({...state, auth: response.data.auth});
                setInitial({...state, auth: response.data.auth});
            }

            toast.success(t('message_system_google_saved'));
        } catch (err) {
            console.log(err);
        }
    }

    const clear = async () => {
        try {
            await axios.delete('/api/system/google/auth', {
                headers: {'x-auth-token': auth.token},
            });

            toast.success(t('message_system_google_token_deleted'));

            setState({...state, auth: null});
        } catch (err) {
            console.log(err);
        }
    }

    const fetch = async () => {
        try {
            const response = await axios.get(`/api/system`, {
                headers: {'x-auth-token': auth.token},
            });

            let token = response.data.google.auth;

            try {
                token = JSON.parse(token);
            } catch(err) {}

            const initial = {
                client_id: response.data.google.client_id,
                secret: response.data.google.secret,
                auth: token,
                valid: response.data.google.valid,
            };

            setState(initial);
            setInitial(initial);

        } catch (err) {
            console.log(err);
        }
    }

    const persistToken = async () => {
        if(code) {
            try {
                const response = await axios.put('/api/system/google/auth', {code: code}, {
                    headers: {'x-auth-token': auth.token},
                });

                toast.success(t('message_system_google_saved_auth_code'));

                navigate('/system/google');
                fetch();
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                    <JezTextEdit
                        label={t('google_client_id')}
                        type={'text'}
                        placeholder={t("google_client_id_placeholder")}
                        name={'client_id'}
                        value={state.client_id}
                        onChange={change}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <JezTextEdit
                        label={t('google_secret')}
                        type={'text'}
                        placeholder={t("google_secret_placeholder")}
                        name={'secret'}
                        value={state.secret}
                        onChange={change}
                    />
                </Grid>

                {JSON.stringify(state) === JSON.stringify(initial) && (<Fragment>
                    {typeof state.auth === 'string' && (
                        <Grid item xs={12} sm={12} md={12}>
                            <InfoBox><ExternalLink href={state.auth}><Trans>token_google_authorisation</Trans></ExternalLink></InfoBox>
                        </Grid>
                    )}
                    {typeof state.auth === 'object' && (
                        <Fragment>
                            <Grid item xs={12} sm={12} md={12} style={{display: 'none'}}>
                                <InfoBox style={{textAlign: 'left'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            Token: {state.auth.access_token ? state.auth.access_token : 'missing'}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            Refresh: {state.auth.refresh_token ? state.auth.refresh_token : 'missing'}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            Expire: {state.auth.expiry_date ? moment(state.auth.expiry_date).format('DD.MM.YYYY HH:mm') : 'missing'}
                                        </Grid>
                                    </Grid>
                                </InfoBox>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <JezButton type='button' secondary className={'error'} onClick={clear} margin center small>
                                    <Trans>clear_token_btn_text</Trans>
                                </JezButton>
                            </Grid>
                        </Fragment>
                    )}
                </Fragment>)}

                {(JSON.stringify(state) !== JSON.stringify(initial) || state.auth === null || !state.auth) && (
                    <Grid item xs={12}>
                        <JezCenter>
                            <JezButton type='submit' onClick={save} margin center>
                                <Trans>save_btn_text</Trans>
                            </JezButton>
                        </JezCenter>
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
}

export default CalenderSettingSection;