import React, {Fragment, useContext, useEffect, useState} from 'react';
import JezHeading from "../../../components/heading/heading";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import {Trans, useTranslation} from "react-i18next";
import {Grid, makeStyles} from "@material-ui/core";
import JezSelect from "../../../components/select/select";
import JezModeratedTextEdit from "../fields/moderatedTextEdit";
import {isHost, isModerator} from "../../../util/roles";
import Checkbox from "../../../components/checkbox/checkbox";
import AuthContext from "../../../context/auth";
import {useParams} from 'react-router-dom';
import {BsPerson} from 'react-icons/bs';
import moment from "moment";
import Languages from "@cospired/i18n-iso-languages";
import JezTextArea from "../../../components/textarea/textarea";

Languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));
Languages.registerLocale(require("@cospired/i18n-iso-languages/langs/de.json"));

const useStyles = makeStyles((theme) => ({
    sectionHeadline: {
        margin: '50px 0 15px 0 !important'
    },
    pendingDataContainer: {
        position: 'relative',
    },
    iconContainer: {
        position: 'absolute',
        right: '5px',
        top: '26px',
        color: '#888',
        zIndex: 10,
    },
    statusIcon: {
        margin: '0 0 0 5px',
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    iconContainerSelect: {
        right: 31
    }
}));

const JezProfilePersonalData = ({user, data, verified, onChange, checkboxHandler, onChangeUser}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();
    const {hostId} = useParams();
    const moderator = auth && hostId && hostId !== auth.id;

    const [state, setState] = useState({whatsapp: ''});

    const change = async (e) => {
        if(onChange) {
            await onChange(e);
        }
    };

    useEffect(() => {
        setState(data);
    }, [data]);

    const changeNumber = async (e) => {
        e.target.value = e.target.value.replace(/^0/, '').replace(/[^0-9+ ]+/, '').replace('  ', ' ');

        await change(e);
    };

    let birthYear = [];

    const fromYears = moment().add(-80, 'years').format('YYYY');
    const toYears = moment().add(-17, 'years').format('YYYY');
    for (let ii = fromYears; ii < toYears; ii++) {
        birthYear.push({key: ii.toString(), value: ii.toString()});
    }

    const languages = () => {
        let languages = Languages.getNames("de");
        let ordered = Object.entries(languages).sort(([, a], [, b]) => {
            let x = a.toLowerCase();
            let y = b.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        return ordered.map(function(value) {
            return {
                key: value[0],
                value: value[1]
            };
        })
    };

    return (
        <Fragment>
            <JezHeading center variant='profileform' icon={<BsPerson />} className={classes.sectionHeadline}>
                <Trans>profile_main_group_title</Trans>
            </JezHeading>
            <JezSelect
                label={t("availability_label")}
                name='availability'
                required
                onChange={(e) => change(e)}
                options={[
                    {key: 1, value: t('available_label')},
                    {key: 0, value: t('not_available_label')},
                ]}
                className={'profileform'}
                value={state.availability}
            />
            {state.availability === 0 && (
                <JezTextArea onChange={(e) => change(e)} name={'availability_reason'} placeholder={t('not_available_reason_label')}>{state.availability_reason}</JezTextArea>
            )}
            <JezModeratedTextEdit onChange={onChangeUser} name={'username'} label={isHost(user) ? t('hostname_label') :  t("username_label")} field={verified.username} />
            <JezModeratedTextEdit onChange={onChangeUser} name={'email'} label={t("mail_label") + (isHost(user) ? ' *' : '')} field={verified.email} />

            {isHost(user) && (
                <JezModeratedTextEdit onChange={onChangeUser} name={'google_email'} label={t("google_calender_mail_label")} field={verified.google_email} />
            )}

            {(isHost(user) || isModerator(user)) && (
                <JezModeratedTextEdit onChange={changeNumber} name={'phone'} label={t("phone_label") + (isHost(user) ? ' *' : '')} field={verified.phone} helperText={t('phone_info_text')} formhelpertextprops={{focused: true}}/>
            )}
            {isModerator(user) && (
                <Fragment>
                    <JezModeratedTextEdit onChange={changeNumber} name={'mobile'} label={t("mobile_label")} field={verified.mobile} helperText={t('phone_info_text')} formhelpertextprops={{focused: true}}/>
                    <Checkbox
                        label={t('mobile_whatsapp_label')}
                        value={1}
                        name={'whatsapp'}
                        id={'whatsapp-mobile'}
                        onChange={checkboxHandler}
                        checked={state.whatsapp}
                    />
                </Fragment>
            )}
            {isHost(user) && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <JezModeratedTextEdit onChange={change} name={'url'} label={t("url_label")} field={verified.url} helperText={t('url_info_text')} formhelpertextprops={{focused: true}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}  lg={6}>
                        <JezModeratedTextEdit onChange={change} name={'url_name'} label={t("url_name")} field={verified.url_name} helperText={t('url_name_info_text')} formhelpertextprops={{focused: true}}/>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} sm={12} className={classes.pendingDataContainer}>
                <div className={`${classes.iconContainer} ${classes.iconContainerSelect}`}>
                    {!moderator && isHost(user) && (<span title={t('Field is required')}><AnnouncementIcon className={classes.statusIcon}/></span>)}
                </div>
                <JezSelect
                    label={t("birth_year_label") + (isHost(user) ? ' *' : '')}
                    placeholder=''
                    required
                    name='birth_year'
                    onChange={(e) => change(e)}
                    id='birth_year'
                    options={birthYear}
                    className={'profileform'}
                    value={state && state.birth_year > 0 ? state.birth_year.toString() : ''}
                />
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6} className={classes.pendingDataContainer}>
                    <div className={`${classes.iconContainer} ${classes.iconContainerSelect}`}>
                        {!moderator && isHost(user) && (<span title={t('Field is required')}><AnnouncementIcon className={classes.statusIcon}/></span>)}
                    </div>
                    <JezSelect
                        label={t("gender_label") + (isHost(user) ? ' *' : '')}
                        name='gender'
                        placeholder=''
                        required
                        onChange={(e) => change(e)}
                        id='gender'
                        options={[
                            {key: '', value: ''},
                            {key: 'male', value: t('male')},
                            {key: 'female', value: t('female')},
                            {key: 'trans', value: t('transgender')},
                        ]}
                        className={'profileform'}
                        value={state.gender}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={6} className={classes.pendingDataContainer}>
                    <div className={`${classes.iconContainer} ${classes.iconContainerSelect}`}>
                        {!moderator && isHost(user) && (<span title={t('Field is required')}><AnnouncementIcon className={classes.statusIcon}/></span>)}
                    </div>
                    <JezSelect
                        label={t("sex_type_label")}
                        name='sex_type'
                        placeholder=''
                        onChange={(e) => change(e)}
                        id='sex_type'
                        multiple
                        options={[
                            {key: '', value: ''},
                            {key: 'vamp', value: t('option_vamp')},
                            {key: 'domina', value: t('option_domina')},
                            {key: 'escort', value: t('option_escort')},
                            {key: 'devote', value: t('option_devote')},
                            {key: 'dirty', value: t('option_dirty')},
                            {key: 'girlfriend', value: t('option_girlfriend')},
                            {key: 'partygirl', value: t('option_partygirl')},
                        ]}
                        className={'profileform'}
                        value={state.sex_type}
                    />
                </Grid>
            </Grid>
            <JezSelect
                label={t("languages_label")}
                name='languages'
                placeholder=''
                onChange={(e) => change(e)}
                options={[...languages()]}
                className={'profileform'}
                multiple
                value={state.languages}
            />
        </Fragment>
    );
};

export default JezProfilePersonalData;