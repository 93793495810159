import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import React, {useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import JezButton from '../components/button/button';
import JezCenter from '../components/center/center';
import JezContainer from "../components/container/container";
import JezHeading from '../components/heading/heading';
import JezLink from '../components/link/link';
import PasswordForm from "../components/passwordform/passwordform";
import JezTextEdit from '../components/textedit/textedit';
import handleErrors from "../util/handleErrors";
import {isEmail, notEmpty} from '../util/validation';
import JezCheckbox from "../components/checkbox/checkbox";
import defaultConfig from "../config/default.json";

const Styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    switchRegister: {
        textAlign: 'center',
        margin: '15px 0 0 0'
    },
    linkBtn: {
        //background: '#b4ab6e',
        border: '1px solid #b4ab6e',
        color: '#b4ab6e',
        padding: '3px 10px',
        borderRadius: 15,

        '&:hover': {
            background: 'rgba(180, 171, 110, 0.7)',
            color: '#fff'
        },
    },
    disabled: {
        cursor: 'default',
        background: 'rgba(180, 171, 110, 0.7)',
        color: '#fff'
    },
    btnGrid: {
        marginBottom: 25
    }
}));

const RegistrationForm = () => {
    const {t} = useTranslation();
    const {type} = useParams();
    const classes = Styles();
    const config = defaultConfig;
    const navigate = useNavigate();

    const [state, setState] = useState({
        username: '',
        password: '',
        password_comfirm: '',
        email: '',
        phone: '',
        old_enough: '',
        corona_notice: '',
        necessary_cookies: '',
        google_analytics: ''
    });

    const [errors, setErrors] = useState({
        username: false,
        password: false,
        password_comfirm: false,
        email: false,
        phone: false,
    });

    const [user] = useState({roles: [], username: '', email: ''});

    let parentRef = React.useRef(user);

    const validate = (e) => {
        const password = parentRef.current.getState();
        // eslint-disable-next-line
        switch (e.target.name) {
            case 'username':
                setErrors({...errors, username: notEmpty(e)});
                break;
            case 'password':
                setErrors({...errors, password: notEmpty({target: {value: password.password}})});
                setErrors({...errors, password_confirm: !(password.password_confirm === password.password)});
                break;
            case 'password_confirm':
                setErrors({...errors, password_confirm: !(password.password === password.password_confirm)});
                break;
            case 'email':
                if (e.target.value.length > 0) {
                    setErrors({...errors, email: !isEmail(e)});
                }
                break;
            case 'phone':
                if (type === 'host') {
                    setErrors({...errors, phone: notEmpty(e)});
                }
                break;
            default:
        }
    };

    const hasErrors = () => {
        for (let index in errors) {
            if (errors[index]) {
                switch (index) {
                    case 'phone':
                        if (type === 'host') {
                            toast.error(t('phone_required_error'));
                        }
                        break;
                    case 'username':
                        toast.error(t('username_required_error'));
                        break;
                    case 'password':
                        toast.error(t('password_required_error'));
                        break;
                    case 'email':
                        toast.error(t('mail_format_invalid'));
                        break;
                    case 'password_confirm':
                        toast.error(t('password_confirm_error'));
                        break;
                    default:
                        return true;

                }
            }
        }
        return false;
    };

    const change = (e) => {
        validate(e);
        setState({...state, [e.target.name]: e.target.value});
    };

    const changeCheckbox = (e) => {
        setState({...state, [e.target.name]: e.target.checked ? 1: ''});
    };

    const changeAlphaNumeric = (e) => {
        e.target.value = e.target.value.replace(/[\s]+/, '');
        validate(e);
        setState({...state, [e.target.name]: e.target.value});
    };

    const changeNumber = (e) => {
        e.target.value = e.target.value.replace(/^0/, '').replace(/[^0-9+ ]+/, '');
        validate(e);
        setState({...state, [e.target.name]: e.target.value});
    };

    const submit = async (e) => {
        e.preventDefault();
        if (!hasErrors()) {
            try {
                const password = parentRef.current.getState();
                let data = JSON.parse(JSON.stringify(state));
                data.password_confirm = password.password_confirm;
                data.password = password.password;

                // if(!data.corona_notice) {
                //     toast.error(t('corona_notice_required'));
                // }

                if (type === 'host') {
                    await axios.post('/api/hosts', data);
                } else {
                    await axios.post('/api/users', data);
                }

                toast.success(t('message_account_registered'));
                navigate('/login');
            } catch (err) {
                handleErrors(err);
            }
        }

        return false;
    };

    return (
        <>
            <div style={{margin: '96px 0 0 0'}} />
            <JezContainer component='main' maxWidth='xs'>
                <div className={classes.paper}>
                    <JezHeading variant='h2' className={'caseSensitive'}><Trans>portal_title</Trans></JezHeading>
                    <JezHeading variant='h4'>
                        <Trans>register_as_title</Trans>
                    </JezHeading>
                    <div className={classes.switchRegister}>
                            <JezLink className={`${classes.linkBtn} ${type !== 'host' ? classes.disabled : ''}`} to='/register' homelink>
                                <Trans>register_user_link</Trans>
                            </JezLink>
                            <JezLink className={`${classes.linkBtn} ${type === 'host' ? classes.disabled : ''}`} to='/register/host' homelink>
                                <Trans>register_host_link</Trans>
                            </JezLink>
                    </div>
                    <form className={classes.form} onSubmit={(e) => submit(e)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <JezTextEdit
                                    autoComplete={'off'}
                                    autoFocus
                                    error={errors.username ? t('username_already_exists_error') : ''}
                                    id='username'
                                    label={type === 'host' ? t('hostname_label') : t('username_label')}
                                    name='username'
                                    onChange={(e) => changeAlphaNumeric(e)}
                                    placeholder={t('username_label')}
                                    required
                                    type='text'
                                    value={state.username}
                                    formhelpertextprops={{focused: true}}
                                    helperText={type === 'host' ? t('username_allowed_chars') : t('username_allowed_chars_customer')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {type === 'host' && (
                                    <PasswordForm
                                        generate_password
                                        min_length={config && config.password_security && config.password_security.minLength ? config.password_security.minLength : 12}
                                        min_lowercase_count={config && config.password_security && config.password_security.minLowercaseCount ? config.password_security.minLowercaseCount : 2}
                                        min_uppercase_count={config && config.password_security && config.password_security.minUppercaseCount ? config.password_security.minUppercaseCount : 2}
                                        min_numbers_count={config && config.password_security && config.password_security.minNumbersCount ? config.password_security.minNumbersCount : 2}
                                        min_specialchars_count={config && config.password_security && config.password_security.minSpecialcharsCount ? config.password_security.minSpecialcharsCount : 2}
                                        forbiddenchars={config && config.password_security && config.password_security.forbiddenchars ? config.password_security.forbiddenchars : ' "\''}
                                        parentRef={parentRef}
                                        subState={{
                                            password: '',
                                            password_confirm: '',
                                            showPassword: false,
                                        }}
                                    />
                                )}
                                {type !== 'host' && (
                                    <PasswordForm
                                        generate_password
                                        min_length={6}
                                        min_lowercase_count={0}
                                        min_uppercase_count={0}
                                        min_numbers_count={0}
                                        min_specialchars_count={0}
                                        forbiddenchars={' "\''}
                                        parentRef={parentRef}
                                        subState={{
                                            password: '',
                                            password_confirm: '',
                                            showPassword: false,
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {type !== 'host' && (
                                    <JezTextEdit
                                        autoComplete='email'
                                        error={errors.email ? t('valid_mail_error') : ''}
                                        id='email'
                                        label={t('mail_label')}
                                        name='email'
                                        onChange={(e) => change(e)}
                                        placeholder={t('mail_label')}
                                        type='email'
                                        value={state.email}
                                    />
                                )}
                            </Grid>
                            {type === 'host' && (
                                <Grid item xs={12}>
                                    <JezTextEdit
                                        autoComplete='email'
                                        error={errors.email ? t('valid_mail_error') : ''}
                                        id='email'
                                        label={t('mail_label')}
                                        name='email'
                                        required
                                        onChange={(e) => change(e)}
                                        placeholder={t('mail_label')}
                                        type='email'
                                        value={state.email}
                                    />
                                    <JezTextEdit
                                        error={errors.phone ? t('phone_required_error') : ''}
                                        id='phone'
                                        label={t('phone_label')}
                                        name='phone'
                                        onChange={(e) => changeNumber(e)}
                                        placeholder={t('phone_label')}
                                        required
                                        type='text'
                                        value={state.phone}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <JezCheckbox
                                    checked={state.old_enough}
                                    id={'old_enough'}
                                    label={t('old_enough')}
                                    name={'old_enough'}
                                    onChange={(e) => changeCheckbox(e)}
                                    value={1}
                                />
                                <JezCheckbox
                                    checked={state.corona_notice}
                                    id={'corona_notice'}
                                    label={t('corona_notice')}
                                    name={'corona_notice'}
                                    onChange={(e) => changeCheckbox(e)}
                                    value={1}
                                />
                                <JezCheckbox
                                    checked={state.necessary_cookies}
                                    id={'necessary_cookies'}
                                    label={t('necessary_cookies')}
                                    name={'necessary_cookies'}
                                    onChange={(e) => changeCheckbox(e)}
                                    value={1}
                                />
                                <JezCheckbox
                                    checked={state.google_analytics}
                                    id={'google_analytics'}
                                    label={t('google_analytics')}
                                    name={'google_analytics'}
                                    onChange={(e) => changeCheckbox(e)}
                                    value={1}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.btnGrid}>
                                <JezCenter>
                                    <JezButton type='submit' center>
                                        <Trans>sign_up_btn_text</Trans>
                                    </JezButton>
                                </JezCenter>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </JezContainer>
        </>
    );
};

export default RegistrationForm;
