import React, { useEffect, useState } from 'react';
import { Grid, Container } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import JezGridImg from "../gridimg/gridimg";
import axios from 'axios';
import JezHeading from "../heading/heading";
import {Trans, useTranslation} from "react-i18next";
import JezSliderbutton from "../sliderbutton/sliderbutton";



const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto',
        position: "relative",
        zIndex: "500000"
    },
}));

const JezHostGrid = ({filter, limit, center, searchAble}) => {
    const classes = useStyles();
    const [meetFilter, setMeetFilter] = useState(false);
    const [meetHomeFilter, setMeetHomeFilter] = useState(false);

    limit = limit ? limit : 0;
    center = center ? center : 'left';

    const { t } = useTranslation();

    const [hosts, setHosts] = useState([]);
    // const [services, setServices] = useState([]);
    // const [filter, setFilter] = useState({public_services:[]});

    useEffect(() => {
        fetchHosts(filter);
        fetchServices();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchHosts = async (filter) => {
        try {
            let searchFilter = [];

            for (let key in filter) {
                const item = filter[key];
                if (item) {
                    searchFilter.push(`filter[${key}]=${item}`);
                }
            }

            searchFilter = searchFilter.length > 0 ? '&' + searchFilter.join('&') : '';

            const response = await axios.get(`/api/hosts?limit=${limit}${searchFilter}`);
            setHosts(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchServices = async () => {
        try {
            const response = await axios.get(`/api/categories`);

            let serviceCollection = [];
            response.data.forEach((serviceCategory) => {
                serviceCollection.push({category: serviceCategory.name,});
                serviceCollection.push(...serviceCategory.services.map((service) => {
                    return {key: service._id, value: service.name};
                }));
            });

            //setServices(serviceCollection);
        }
        catch(err) {
            console.log(err);
        }
    };

    const getNotAvailableText = (user) => {

        if(!!user && !!user.profile.availability_reason) {
            return user.profile.availability_reason;
        }

        return t('host_not_available')
    }

    useEffect(() => {
        const appliedFilter = {
            meet: meetFilter,
            meet_home: meetHomeFilter,
            // Füge hier weitere Filter hinzu, falls benötigt
        };
        fetchHosts(appliedFilter);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetFilter, meetHomeFilter]);

    /**
     * CONTROLS
     */
    // const change = (e) => {
    //     const update = { ...filter, [e.target.name]: e.target.value };
    //     setFilter(update);
    //     fetchHosts(update);
    // };
    if (limit==0) {
    return (
        <Container component='div' className={classes.maxWidth1170}>
            <Grid center container className="filterContainer justifyConentCenter">
                <Grid item>
                    <input id="meet" type="checkbox" checked={meetFilter} onChange={() => setMeetFilter(!meetFilter)} />
                    <label htmlFor="meet">
                        Ich komme zu dir
                    </label>
                </Grid>
                <Grid item>
                    <input id="meetHome" type="checkbox" checked={meetHomeFilter} onChange={() => setMeetHomeFilter(!meetHomeFilter)} />
                    <label htmlFor="meetHome">
                        Du kannst mich besuchen
                    </label>
                </Grid>
            </Grid>
            {searchAble && (
            <div>
            </div>)}
            <Grid center={center} container spacing={1}>
                {hosts.map((host, index) => { if(host._id !== 0) { return (
                    <JezGridImg key={index} image={`/api/hosts/${host._id}/avatar/single`} id={host.profile.availability === 1 ? host._id : null} username={host.username} sextype={host.profile.availability === 1 ? host.profile.sex_type : getNotAvailableText(host)} birthyear={host.profile.birth_year}/>
                )} return '' })}
                {hosts.length === 0 && (<JezHeading center variant='h4'><Trans>host_search_empty_result</Trans></JezHeading>)}
            </Grid>
        </Container>
    );
    } else {
        return (
            <Container component='div' className={classes.maxWidth1170}>
                <Grid center container className="filterContainer justifyConentCenter">
                    <Grid item>
                        <input id="meet" type="checkbox" checked={meetFilter} onChange={() => setMeetFilter(!meetFilter)} />
                        <label htmlFor="meet">
                            Ich komme zu dir
                        </label>
                    </Grid>
                    <Grid item>
                        <input id="meetHome" type="checkbox" checked={meetHomeFilter} onChange={() => setMeetHomeFilter(!meetHomeFilter)} />
                        <label htmlFor="meetHome">
                            Du kannst mich besuchen
                        </label>
                    </Grid>
                </Grid>
                {searchAble && (
                    <div>
                        {/*<JezHeading center variant='h4'>*/}
                        {/*    <Trans>host_search_filter</Trans>*/}
                        {/*</JezHeading>*/}
                        {/*<JezSpacer />*/}
                        {/*<Grid container spacing={1}>*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <JezSelect*/}
                        {/*            id='services'*/}
                        {/*            label={t('Public services')}*/}
                        {/*            multiple*/}
                        {/*            name='public_services'*/}
                        {/*            onChange={(e) => change(e)}*/}
                        {/*            options={services}*/}
                        {/*            placeholder=''*/}
                        {/*            value={filter.public_services}*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        {/*{hosts.length > 0 && (<div>*/}
                        {/*    <JezHeading center variant='h4'>*/}
                        {/*        <Trans>our_host_results</Trans>*/}
                        {/*    </JezHeading>*/}
                        {/*    <JezSpacer icon={<SearchIcon />} />*/}
                        {/*</div>)}*/}
                    </div>)}
                <Grid center={center} container spacing={1}>
                    {hosts.map((host, index) => { if(host._id !== 0) { return (
                        <JezGridImg key={index} image={`/api/hosts/${host._id}/avatar/single`} meet={host.profile.meet} meethome={host.profile.meet_home} id={host.profile.availability === 1 ? host._id : null} username={host.username} sextype={host.profile.availability === 1 ? host.profile.sex_type : getNotAvailableText(host)} birthyear={host.profile.birth_year}/>
                    )} return '' })}
                    {hosts.length === 0 && (<JezHeading center variant='h4'><Trans>host_search_empty_result</Trans></JezHeading>)}
                </Grid>
                <div id={"host"}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            <JezSliderbutton to='/hosts'>
                                Weiter
                            </JezSliderbutton>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        );
    }

};

export default JezHostGrid;