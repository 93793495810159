import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import JezTextEdit from "../components/textedit/textedit";
import {Grid} from "@material-ui/core";

const JezAddress = ({address, onChange}) => {
    const [state, setState] = useState({street: '', number: '', zip: '', city: ''});
    const {t} = useTranslation();

    const change = (e) => {
        const update = {...state, [e.target.name]: e.target.value};
        if(onChange) {
            onChange(update);
        }

        setState(update);
    };

    useEffect(() => {
       setState(address);
    }, [address]);

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={9}>
                    <JezTextEdit label={t("label_street")} name={'street'} value={state.street} onChange={change}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={3}>
                    <JezTextEdit label={t("label_number")} name={'number'} value={state.number} onChange={change}/>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={3}>
                    <JezTextEdit label={t("label_zip")} name={'zip'} value={state.zip} onChange={change}/>
                </Grid>
                <Grid item xs={12} sm={12} lg={9}>
                    <JezTextEdit label={t("label_city")} name={'city'} value={state.city} onChange={change}/>
                </Grid>
            </Grid>
        </Fragment>
    );
};

JezAddress.propTypes = {
    address: PropTypes.object.isRequired,
};

export default JezAddress;