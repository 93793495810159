import React, {useContext, useEffect, useState} from 'react';
import './homelocationgrid.scss';
import {Container, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import locations2 from '../../assets/img/locations2.jpg';

import escort from "../../assets/img/escort.jpg";
import wellness from "../../assets/img/wellness.jpg";
import sm from "../../assets/img/bdsm_studio.jpg";
import rendRoom from "../../assets/img/zimmervermietung.jpg";
import nightClub from "../../assets/img/nightclub.jpg";

import {Link} from "react-router-dom";
import {useJezios} from "../../util/jezios";
import AuthContext from "../../context/auth";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto'
    },
}));

const JezHomeLocationGrid = ({ children }) => {
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    const request = useJezios(auth, t);
    const [locations, setLocations] = useState([]);

    const fetch = async () => {
        const response = await request.get('/locations');

        if(response) {
            setLocations(response.data);
        }
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    const getLocationsBySlug = (slug) => {
        const location = locations.filter(item => item.slug === slug);

        if(location.length > 0) {
            return location[0];
        }

        return {name: 'UNKNOWN'};
    }

    return (
        <Container component='div' className={classes.maxWidth1170}>
            <div className='homelocationgrid-container'>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={5}>
                        <Link to={'/locations/nightclub'}>
                            <div className='locationgridwrap firstimage'>
                                <div className={'locationgridimg'} style={{ backgroundImage: `url('${nightClub}')` }} />
                                <span className="tooltiptext">{getLocationsBySlug('nightclub').name}</span>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/tagesstudio'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${locations2}')` }} />
                                        <span className="tooltiptext">{getLocationsBySlug('tagesstudio').name}</span>
                                    </div>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/escort'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${escort}')` }} />
                                        <span className="tooltiptext">{getLocationsBySlug('escort').name}</span>
                                    </div>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/bdsm-studio'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${sm}')` }} />
                                        <span className="tooltiptext">{getLocationsBySlug('bdsm-studio').name}</span>
                                    </div>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Link to={'/locations/zimmervermietung'}>
                                    <div className='locationgridwrap'>
                                        <div className={'locationgridimg'} style={{ backgroundImage: `url('${rendRoom}')` }} />
                                        <span className="tooltiptext">{getLocationsBySlug('zimmervermietung').name}</span>
                                    </div>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <Link to={'/locations/wellness'}>
                            <div className='locationgridwrap firstimage'>
                                <div className={'locationgridimg'} style={{ backgroundImage: `url('${wellness}')` }} />
                                <span className="tooltiptext">{getLocationsBySlug('wellness').name}</span>
                            </div>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default JezHomeLocationGrid;
