import React, {useContext, useEffect, useState} from 'react';
import JezContainer from "../../components/container/container";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import JezTable from "../../components/table/table";
import AuthContext from "../../context/auth";
import {useJezios} from "../../util/jezios";
import {Link, useNavigate} from "react-router-dom";
import {isModerator} from "../../util/roles";

const useStyles = makeStyles((theme) => ({
    link: {
        color: '#b4ab6e',
        padding: '5px 10px',
        borderRadius: 4,
        border: '1px solid #b4ab6e',
        background: 'rgba(0,0,0,0.5)',
        '&:hover': {
            background: '#b4ab6e',
            color: '#fff'
        }
    },
    actionContainer: {
        textAlign: 'right',
        margin: '0 0 15px 0'
    },
    btn: {
        background: '#b4ab6e',
        padding: '5px 10px',
        borderRadius: 4,
        '&:hover': {
            background: '#716b44',
            color: '#fff'
        }
    }
}));

const LocationList = () => {
    /**
     * CSS
     */
    const classes = useStyles();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    if (!auth || (auth && !isModerator(auth))) {
        navigate('/');
    }


    const request = useJezios(auth, t);

    const columns = [
        {name: 'name', title: t('table_name'), dataCellOptions: {component: 'th'}, render: (item) => {
            return <div
                dangerouslySetInnerHTML={{__html: `${item.name}${item.slug === 'gentlemans-base' ? ' <strong>(Über uns Seite)</strong>' : ''}`}}
            />
            return `${item.name}${item.slug === 'gentlemans-base' ? ' <strong>(Über uns Seite)</strong>' : ''}`
        }},
        {name: 'action', title: t('table_action'), dataCellOptions: {align: 'right'}, options: {align: 'right'}, render: (item) => { return (<Link className={classes.link} to={`/moderation/locations/${item._id}`}><Trans>news_edit</Trans></Link> ); }}
    ];

    const fetch = async () => {
        const response = await request.get('/locations');

        if(response) {
            setRows(response.data);
        }
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    return (
        <JezContainer maxWidth={'lg'} padding style={{margin: '96px auto 32px auto'}} header={t('location_list')}>
            <JezTable rows={rows} columns={columns}/>
        </JezContainer>
    );
};

export default LocationList;