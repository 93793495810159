import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import JezTimeRange from "../components/timeRange/timeRange";
import {rand} from "../util/tools";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#fff',
        width: '100px',
        display: 'inline-block',
        margin: '9px 0',
        verticalAlign: 'top',
        height: 8
    },
    range: {
        display: 'inline-block',
        width: 'calc(50% - 59px)',
        margin: '0 2px',
        color: '#b4ab6e',
        fontWeight: 'bold',
        textAlign: 'center'
    }
}));

const JezOpeningHours = ({openingTimes, onChange}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    const [state, setState] = useState([]);
    const {t} = useTranslation();

    const days = [t('day_sunday'), t('day_monday'), t('day_tuesday'), t('day_wednesday'), t('day_thursday'), t('day_friday'), t('day_saturday')];

    const change = async (data) => {
        if(onChange) {
            await onChange(data);
        }
    };

    useEffect(() => {
        if(openingTimes instanceof Array) {
            setState(openingTimes);
        }
    }, [openingTimes]);

    return (
        <Fragment>
            <div>
                <div className={classes.label}/>
                <div className={classes.range}><Trans>label_from</Trans></div>
                <div className={classes.range}><Trans>label_to</Trans></div>
            </div>
            {state.map((item, index) => (<JezTimeRange id={index} onChange={change} key={rand()} to={item.to} label={days[item.day]} from={item.from}/>))}
        </Fragment>
    );
};

JezOpeningHours.propTypes = {
    openingTimes: PropTypes.array.isRequired,
};

export default JezOpeningHours;