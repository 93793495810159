import React from 'react';
import './spacer.scss';

const JezSpacer = ({ icon }) => {
	return (
		<div className='spacer'>
			<>{icon}</>
		</div>
	);
};

export default JezSpacer;
