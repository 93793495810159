import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import { Trans, useTranslation } from 'react-i18next';
import './videoChat.scss';
import SocketContext from "../../context/socket";
import moment from "moment";
import AuthContext from "../../context/auth";
import axios from "axios";

const JezVideoChat = ({onClick, user}) => {
    const { t } = useTranslation();

    const { auth } = useContext(AuthContext);
    const { socket } = useContext(SocketContext);

    const [messages, setMessages] = useState([]);

    const messageRef = useRef();
    messageRef.current = messages;

    const onMessage = async () => {
        const message = document.getElementById('chat-message').value;

        if (message.trim().length > 0) {
            await axios.post(
                '/api/call/chat',
                {
                    uid: user.data.id,
                    message: message,
                },
                { headers: { 'x-auth-token': auth.token } }
            );
            let list = JSON.parse(JSON.stringify(messages));

            list.push({ message: message, date: moment().format('hh:mm:ss'), self: true });
            document.getElementById('chat-message').value = '';

            setMessages(list);
        }
    };

    useEffect(() => {
        setMessages([]);
    }, [user]);

    useEffect(() => {
        if(socket !== null) {
            socket.on(`call/${auth.id}/chat`, function (event) {
                let list = JSON.parse(JSON.stringify(messageRef.current));

                list.push({ message: event.message, date: moment().format('hh:mm:ss'), self: false });

                setMessages(list);
            });
        }
    }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

    const randomId = () => {
        return Math.floor(Math.random() * 10000001);
    };

    const keyDown = (e) => {
        if(!(e.ctrlKey || e.shiftKey) && e.keyCode === 13) {
            document.getElementById('message-submit').click();
        }
    };

    return (
        <Fragment>
            <div className={'video-chat-container'}>
                <div className={'video-chat-messages'}>
                    {messageRef.current.map((item) => (
                        <div key={randomId()} className={item.self ? 'my-message' : 'other-message'}>
                            <div>
                                <span className={'time'}>{item.date}</span>
                                <span className={'message'}>{item.message}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'video-chat-input-container'}>
                    <textarea id={'chat-message'} placeholder={t('message_label')} onKeyDown={keyDown}></textarea>
                    <button id={'message-submit'} onClick={onMessage} className={'btn primary center'}>
                        <Trans>message_send</Trans>
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default JezVideoChat;