import React, {Fragment, useContext, useEffect, useState} from "react";
import JezContainer from "../../../components/container/container";
import JezHeading from "../../../components/heading/heading";
import {Trans, useTranslation} from "react-i18next";
import AuthContext from "../../../context/auth";
import CalenderSettingSection from "./sections/calender";
import JezTab from "../../../components/tab/tab";
import MapSettingSection from "./sections/map";
import {isModerator} from "../../../util/roles";
import {useNavigate} from "react-router-dom";


const JezGoogleSettings = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    if (!auth || (auth && !isModerator(auth))) {
        navigate('/');
    }


    return (<Fragment>
        <div style={{margin: '96px 0 0 0'}}/>
        <JezContainer className={'maxWidth1170'} padding margin>
            <JezHeading center variant='h2'>
                <Trans>system_section</Trans>
            </JezHeading>
            <JezTab>
                <CalenderSettingSection title={t('system_google_auth_section')}/>
                <MapSettingSection title={t('system_google_map_section')} />
            </JezTab>
        </JezContainer>
    </Fragment>);
}

export default JezGoogleSettings;