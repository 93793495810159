import React from 'react'
import PropTypes from 'prop-types';
import './button.scss'

const JezButton = ({className, small, secondary, type, outlined, margin, center, fullwidth, ...other}) => {

    function classNames() {
        let classname = 'btn';
        if(center !== undefined)
            classname += ' center';
        if(margin !== undefined)
            classname += ' margin';

        if(small !== undefined)
            classname += ' small';

        if(secondary) {
            classname += " secondary"
        }

        if(outlined) {
            classname += " outlined"
        } else {
            classname += " primary"
        }

        if(fullwidth) {
            classname += ' full-width';
        }

        return classname;
    }

    return (
        <button className={`${classNames()} ${className ? className : ''} ${type}`} {...other}>
            {other.children}
        </button>
    )
};

JezButton.propTypes = {
    disabled: PropTypes.bool,
    margin: PropTypes.bool,
    center: PropTypes.bool,
    fullwidth: PropTypes.bool,
    type: PropTypes.oneOf(['default', 'error', 'normal'])
};

JezButton.defaultProps = {
    type: 'normal'
};

export default JezButton
