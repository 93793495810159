import React, {Fragment} from 'react';
import './sliderbutton.scss';
import {Link} from "react-router-dom";

const JezSliderbutton = ({ to, children, layout }) => {

    return (<Fragment>
            {to && (<Link to={to}><div className={'sliderbutton'}>
                { children }
            </div></Link>)}
            {!to &&
                (<div className={`sliderbutton ${layout && layout}`}>
                    { children }
                </div>
                )}
        </Fragment>

    );
};

export default JezSliderbutton;
