import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import JezHeading from '../components/heading/heading';
import JezLink from '../components/link/link';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import JezParagraph from '../components/paragraph/paragraph';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Activation = () => {
	const classes = useStyles();
	const query = useQuery();
	const [success, setSuccess] = useState(null);

	const { t } = useTranslation();

	const activate = async () => {
		try {
			await axios.get(`/api/users/activate?token=${query.get('token')}`);
			setSuccess(true);
		} catch (err) {
			setSuccess(false);
		}
	};

	useEffect(() => {
		activate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container component='main' maxWidth='xs'>
			<div className={classes.paper}>
				<JezHeading variant='h2' className={'caseSensitive'}><Trans>portal_title</Trans></JezHeading>
				<JezHeading variant='h4'>
					<Trans>activation_text</Trans>
				</JezHeading>
				<JezParagraph>
					{success === null
						? t('acivation_pending_text')
						: success
						? t('activation_success_text')
						: t('activation_failed_text')}
				</JezParagraph>
				{success && (
					<JezLink to='/login' homelink>
						<Trans>activation_success_link</Trans>
					</JezLink>
				)}
			</div>
		</Container>
	);
};

export default Activation;
