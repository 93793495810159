import React from 'react';
import './container.scss';
import {makeStyles, withStyles} from "@material-ui/core";
import {Container} from '@material-ui/core';
import PropTypes from "prop-types";

const JezzContainer = withStyles({
    root: {
        //boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
        background: 'rgba(0,0,0,0.5)',
        border: `1px solid #b4ab6e !important`,
        borderRadius: '3px',
        position: 'relative',
        padding: '0 48px',
        '& h2': {
            margin: 0
        },
        '& input[type=text], & input[type=password], & input[type=number], & input[type=email], & select, & .MuiSelect-select, & textarea': {
            background: 'rgba(0,0,0,0.4)',
            backgroundColor: 'rgba(0,0,0,0.4) !important',
            borderRadius: '2px'
        },
        '& input[type=text]:not(:focus), & input[type=password]:not(:focus), & input[type=number]:not(:focus), & input[type=email]:not(:focus), & select:not(:focus), & .MuiSelect-select:not(:focus), & textarea:not(:focus)': {
            border: `1px solid #444`,
        },
        '& input[type=text]:hover, & input[type=password]:hover, & input[type=number]:hover, & input[type=email]:hover, & select:hover, & .MuiSelect-select:hover, & textarea:hover': {
            border: `1px solid #b4ab6e`,
        },
        '& label.Mui-focused': {
            color: '#b4ab6e !important'
        },
        '& button': {
            border: 0,
            padding: '0.5em 1em',
            borderRadius: '2px'
        },
        '& button[type=submit]': {
            marginTop: '18px',
        }
    }
})(Container);

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: '24px 48px',
    },
    margin: {
        margin: '48px auto'
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%);'
    },
    mobilepadding:{
        padding: '24px 5px',
    },
    headline: {
        color: '#b4ab6e',
        padding: '3px 25px',
        fontSize: 24,
        border: '2px solid #b4ab6e',
        borderTop: 0,
        display: 'inline-block',
        position: 'absolute',
        top:0,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 30,
        borderRadius: '0 0 25px 25px',
        cursor: 'default',
        fontFamily: "'Segoe UI', sans-serif"
    },
    headlineLeft: {
        // height: 30,
        // width: 52,
        // transform: 'rotate(45deg)',
        // borderBottom: '1px solid #b4ab6e',
        // position: 'absolute',
        // top: -8,
        // left: -34
    },
    headerPadding: {
        paddingTop: 48
    },
    maxWidth1170: {
        maxWidth: '1170px',
        margin: '48px auto',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
}));

const JezContainer = ({header, margin, center, padding, mobilepadding, ...other}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    return (<JezzContainer {...other}
                           className={`data-container ${other.maxWidth === 'lg' ? classes.maxWidth1170 : ''} ${center ? classes.center : ''} ${padding ? classes.padding : ''}  ${mobilepadding ? classes.mobilepadding : ''}  ${margin ? classes.margin : ''} ${header ? classes.headerPadding: ''}`}>
        {header && (
        <div className={classes.headline}>
            {header}
        </div>)}
        {other.children}
    </JezzContainer>);
};

JezContainer.propTypes = {
    margin: PropTypes.bool,
    center: PropTypes.bool,
    padding: PropTypes.bool
};

export default JezContainer;