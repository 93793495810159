import { Grid } from '@material-ui/core';
import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import './gridimg.scss';
import moment from 'moment';

const JezGridImg = ({ actionButtons, url, image, id, onClick, username, birthyear, sextype}) => {
	if(birthyear) {
		birthyear = ` (${moment().format('YYYY') - birthyear})`;
	}
	if(sextype) {
		sextype = ` ${sextype}`;
	}

	return (
		<Grid item xs={12} sm={6} md={4} className={`relative-positioning`}>
			{(!!url || !!id) && (<Fragment>
				<Link to={url ? url : `/host/${id}/profile`}>
					<div className='gridwrap'>
						<div className={'gridimg'} style={{ backgroundImage: `url('${image}')` }}></div>
						<span className="tooltiptext">{username}{birthyear}{sextype}</span>
					</div>
				</Link>
			</Fragment>)}
			{!(!!url || !!id) && (<Fragment>
				<div className='gridwrap' onClick={!!onClick ? onClick : () => {}}>
					<div className={'gridimg'} style={{ backgroundImage: `url('${image}')` }}></div>
					<span className="tooltiptext">{username}{birthyear}{sextype}</span>
				</div>
			</Fragment>)}
			{actionButtons}
		</Grid>
	);
};

export default JezGridImg;
