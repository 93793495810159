import React from 'react';
import './icontext.scss';

const JezIconText = ({ icon, children }) => {
	return (
		<p className='icontext'>
			<>{icon}</>
			{children}
		</p>
	);
};

export default JezIconText;
