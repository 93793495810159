import React from 'react';
import './paragraph.scss';

const JezParagraph = ({children}) => {
    return (
        <p>
            {children}
        </p>
    )
}

export default JezParagraph
