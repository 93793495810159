import React from 'react';
import './texteditor.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const JezTextEditor = ({placeholder, toolbar, ...props}) => {
    const editorConfiguration = {
        placeholder: placeholder ? placeholder : '',
        toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'undo',
            'redo',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            '|',
            'undo',
            'redo',
        ].concat(toolbar),
    };

    return (
        <div className={'jeztexteditor'}>
            <CKEditor
                {...props}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                    props.setState(editor.getData());
                }}
                config={editorConfiguration}
            />
        </div>
    );
};

JezTextEditor.defaultProps = {
    toolbar: []
};

export default JezTextEditor;
