import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {NavLink} from "react-router-dom";
import useWindowDimensions from '../../hooks/useWindowDimension';
import JezHeading from '../heading/heading';
import './slideroverlay.scss';
import {Grid} from "@material-ui/core";
import {Link} from "react-scroll";
import JezSliderbutton from "../sliderbutton/sliderbutton";

const JezSliderOverlay = () => {
	const { height, width } = useWindowDimensions();
	const {t} = useTranslation();

	let sliderHeight = height - 121;

	if(width < 1025) { sliderHeight = 500; }
	if(width < 469) { sliderHeight = 650; }
	if(width < 326) { sliderHeight = 750; }

	return (
		<div className='overlay-wrap'>
			<div style={{ top: `-${sliderHeight}px`}} className='overlay-container'>
				<Grid container spacing={0} justify="center" className={'only-mobile-margin'}>
					<Grid item xs = {0} sm = {0} md={1} lg = {2} >
					</Grid>
					<Grid item xs = {2} sm = {3} md={4} lg = {2} >
					</Grid>
				</Grid>
				<JezHeading styles={{marginTop: '25px'}} center>
					<div>
						<Trans>message_welcome</Trans>
					</div>
					<JezHeading variant='portalName'/>
				</JezHeading>
				<JezHeading center variant='h3'>
					<span className={'golden-element-container'} dangerouslySetInnerHTML={{ __html: t('message_title_sub') }} />
				</JezHeading>
				<Grid container spacing={1} justify="center">
					<Grid item>
						<NavLink exact to='/news'>
							<JezSliderbutton layout={"sliderButton3"}>
								News - Angebote
							</JezSliderbutton>
						</NavLink>
					</Grid>
					<Grid item>
						<Link to={'ladies'} smooth={true} offset={-73}>
							<JezSliderbutton layout={"sliderButton3"}>
								Damen
							</JezSliderbutton>
						</Link>
					</Grid>
					<Grid item>
						<Link to={'locations'} smooth={true} offset={-73}>
							<JezSliderbutton>
								Leistungen
							</JezSliderbutton>
						</Link>
					</Grid>
					<Grid item>
						<NavLink exact to='/locations/gentlemans-base' className={['sliderbutton', 'external-menu-link'].join(' ')}>
							<Trans>Über uns</Trans>
						</NavLink>
					</Grid>
					<Grid item>
						<Link to={'contact'} smooth={true} offset={-73}>
							<JezSliderbutton>
								<Trans>btn_label_contact</Trans>
							</JezSliderbutton>
						</Link>
					</Grid>
					<Grid item>
						<NavLink exact to='/jobs'>
							<JezSliderbutton>
								<Trans>jobs_label</Trans>
							</JezSliderbutton>
						</NavLink>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default JezSliderOverlay;
