import React, { useContext, useEffect } from 'react'
import AuthContext from '../context/auth';
import axios from 'axios';
import JezHeading from '../components/heading/heading';
import {useNavigate} from "react-router-dom";

const Premium = () => {
    const { auth, setAuth } = useContext(AuthContext);
	const navigate = useNavigate();

	const checkAuth = async () => {
		try {
			await axios.get('/api/auth', {
				headers: {
					'x-auth-token': auth.token,
				},
			});
		} catch (err) {
			localStorage.removeItem('auth');
			setAuth(null);
			navigate('/');
		}
	};

	useEffect(() => {
		if (auth === null) {
			navigate('/');
		} else {
			checkAuth();
		}
	});

	console.log("testen");

    return (
        <div>
            <JezHeading center variant='h2'>Member Section</JezHeading>
            <JezHeading center variant='h4'>Coming Soon</JezHeading>
        </div>
    )
}

export default Premium
