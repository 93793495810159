import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import React, {useContext, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {toast} from 'react-toastify';
import JezButton from '../components/button/button';
import JezCenter from '../components/center/center';
import JezHeading from '../components/heading/heading';
import JezLink from "../components/link/link";
import JezTextEdit from '../components/textedit/textedit';
import AuthContext from '../context/auth';
import {notEmpty} from '../util/validation';
import JezContainer from '../components/container/container';
import SocketContext from "../context/socket";
import {isCustomer, isHost, isModerator} from "../util/roles";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '16px', //theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginForm() {
    const {t} = useTranslation();

    const classes = useStyles();
    const {setAuth} = useContext(AuthContext);
    const { socket } = useContext(SocketContext);
    const navigate = useNavigate();

    const [state, setState] = useState({
        username: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        password: false,
        username: false,
    });

    const validate = (e) => {
        // eslint-disable-next-line
        switch (e.target.name) {
            case 'password':
                setErrors({...errors, password: notEmpty(e)});
                break;
            case 'username':
                setErrors({...errors, username: notEmpty(e)});
                break;
        }
    };

    const change = (e) => {
        validate(e);
        setState({...state, [e.target.name]: e.target.value});
    };

    const submit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/auth', state);
            localStorage.setItem('auth', JSON.stringify(response.data));
            if(isModerator(response.data) || isHost(response.data)) {
                socket.emit(`call/available`, {uid: {destination: null, source: response.data.id}, state: 1});
                socket.emit(`io_join_call_center_waiting_room`, {uid: response.data.id});
            }

            socket.emit('io_login', response.data.id);

            setAuth(response.data);
            if(isCustomer(response.data)) {
                navigate('/hosts');
            } else {
                navigate('/profile');
            }
        } catch (err) {
            toast.error(t('invalid_credentials_error'));
        }

        return false;
    };

    return (
        <JezContainer component='main' maxWidth='xs' center>
            <div className={classes.paper}>
                <JezHeading variant='h2' className={'caseSensitive'}><Trans>portal_title</Trans></JezHeading>
                <JezHeading variant='h4'><Trans>login_text</Trans></JezHeading>
                <form onSubmit={(e) => submit(e)} className={classes.form}>
                    <JezTextEdit
                        autoFocus
                        error={errors.username}
                        errorText={t('username_required_error')}
                        id='username'
                        label={t('username_label')}
                        name='username'
                        onChange={(e) => change(e)}
                        placeholder={t('username_label')}
                        required
                        type='text'
                    />
                    <JezTextEdit
                        error={errors.password}
                        id='password'
                        label={t('password_label')}
                        margin={'1'}
                        name='password'
                        onChange={(e) => change(e)}
                        placeholder={t('password_label')}
                        type='password'
                        required
                    />
                    <JezCenter>
                        <JezButton
                            center
                            margin
                            type='submit'
                        >
                            <Trans>sign_in_btn_text</Trans>
                        </JezButton>
                    </JezCenter>
                </form>
                <JezLink to="/register" homelink><Trans>register_link_text</Trans></JezLink>
                <span className='homelink'></span>
            </div>
        </JezContainer>
    );
}
