import React from 'react';
import './toTopButton.scss';
import {Link} from 'react-scroll'
import {RiArrowUpCircleLine} from 'react-icons/ri';

const JezToTop = ({children}) => {
    return (
        <div className={'totopbutton'}>
            <Link to={'top'} smooth={true} offset={-71}>
                <RiArrowUpCircleLine />
            </Link>
        </div>
    )
}

export default JezToTop
