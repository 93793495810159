import './infoBox.scss';
import React from 'react';

const InfoBox = ({className, children, type, args}) => {
    type = type ? type : 'general'
    className = className ? className : '';

    return (<div className={['info-box', type, className].join(' ').trim()} {...args}>
        {children}
    </div>);
}

export default InfoBox;