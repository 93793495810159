import {Fragment} from "react";

import JezSlider from "../../../components/slider/slider";
import JezSlide from "../../../components/slide/slide";
import slide1 from "../../../assets/img/slide1.jpg";
import slide2 from "../../../assets/img/slide2.jpg";
import slide3 from "../../../assets/img/slide3.jpg";
import JezSliderOverlay from "../../../components/slideroverlay/slideroverlay";
import React from "react";

const SliderSection = () => {
    return (<Fragment>
        <JezSlider>
            <JezSlide url={slide1}/>
            <JezSlide url={slide2}/>
            <JezSlide url={slide3}/>
        </JezSlider>
        <JezSliderOverlay/>
    </Fragment>);
}

export default SliderSection