import React, {useContext, useEffect, useState} from 'react';
import { Grid, Container } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import JezGridImg from "../gridimg/gridimg";
import {useJezios} from "../../util/jezios";
import AuthContext from "../../context/auth";
import {useTranslation} from "react-i18next";



const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto'
    },
}));

const JezLocationGrid = ({limit, center, searchAble}) => {
    const classes = useStyles();
    limit = limit ? limit : 0;
    center = center ? center : 'left';

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    const jezios = useJezios(auth, t);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        fetchLocations([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchLocations = async (filter) => {
            let searchFilter = [];

            for(let key in filter) {
                const item = filter[key];
                if(item instanceof Array) {
                    for(let entry of item) {
                        searchFilter.push(`filter[${key}]=${entry}`);
                    }
                } else {
                    searchFilter.push(`filter[${key}]=${item}`);
                }
            }

            searchFilter = searchFilter.length > 0 ? '&' + searchFilter.join('&') : '';

            const response = await jezios.get(`locations?limit=${limit}${searchFilter}`);
            setLocations(response.data);

    };

    return (
        <Container component='div' className={classes.maxWidth1170}>
            <Grid center={center} container spacing={1}>
                {locations && locations.map((location, index) => location.images.length > 0 && (
                    <JezGridImg key={index} url={`/locations/${location._id}`} image={`/api/pictures/${location.images[0]}`} id={location.images[0]} username={location.name}/>
                ))}
            </Grid>
        </Container>
    );
};

export default JezLocationGrid;