import React, {Fragment} from 'react';
import './dragAbleContainer.scss';

import Draggable from 'react-draggable';

// import MovablePopover from 'movable-popover-react';

const JezDragAbleContainer = ({children, title, visible}) => {
    const draggable = window.innerWidth >768;

    return (<Fragment>
        <div className={visible ? 'in-screen' : 'out-off-screen'}>
            {draggable && (
                <Draggable>
                    <div style={{
                        position: 'absolute',
                        width: '200px',
                        padding: '10px',
                        border: '1px solid black',
                        backgroundColor: 'white',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        borderRadius: '4px',
                        zIndex: 1000,
                    }}>
                        <div className={'drag-able-container'}>
                            <div className={'headline'}>{title}</div>
                            <div className={'content'}>{children}</div>
                        </div>
                        {/*<Popover*/}
                        {/*    isOpen={true}*/}
                        {/*    positions={['top', 'right']}*/}
                        {/*    content={<div className={'drag-able-container'}><div className={'headline'}>{title}</div><div className={'content'}>{children}</div></div>}*/}
                        {/*>*/}
                        {/*    /!*<button onClick={() => setIsPopoverOpen(!isPopoverOpen)}>*!/*/}
                        {/*    /!*    Click me to toggle Popover*!/*/}
                        {/*    /!*</button>*!/*/}
                        {/*</Popover>*/}
                    </div>
                </Draggable>
            )}

            {!draggable && (
                <div className={'mobile-container'}>
                    <div className={'headline'}>{title}</div><div className={'content'}>{children}</div>
                </div>)}
        </div>
    </Fragment>);

    // return (
    //     <Fragment>
    //         <div className={visible ? '' : 'out-off-screen'}>
    //             {draggable && (
    //                 <MovablePopover
    //                     id="examle-id"
    //                     moveTo="left"
    //                     backgroundColor="transparent"
    //                     content={<div className={'drag-able-container'}><div className={'headline'}>{title}</div><div className={'content'}>{children}</div></div>}
    //                     isVisible={true}
    //                 />
    //             )}
    //
    //             {!draggable && (
    //             <div className={'mobile-container'}>
    //                 <div className={'headline'}>{title}</div><div className={'content'}>{children}</div>
    //             </div>)}
    //         </div>
    //     </Fragment>
    // );
};

export default JezDragAbleContainer;
