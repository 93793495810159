import {toast} from "react-toastify";
import axios from "axios";

export const useJezios = (auth, translation) => {
    return {
        api: true,
        auth: auth ? auth : false,
        trans: translation ? translation : (str) => { return str },
        createOptions: function(options) {
            options = options ? options : {};
            if(!options.headers) { options.headers = {} }

            if(this.auth) { options.headers['x-auth-token'] = this.auth.token; }

            return options;
        },
        get: async function (url, options) {
            try {
                return await axios.get(this.api ? `/api/${url}` : url, this.createOptions(options));
            } catch(err) {
                this.error(err);
            }

            return false;
        },
        delete: async function (url, options) {
            try {
                return await axios.delete(this.api ? `/api/${url}` : url, this.createOptions(options));
            } catch(err) {
                this.error(err);
            }

            return false;
        },
        put: async function(url, data, options) {
            try {
                return await axios.put(this.api ? `/api/${url}` : url, data, this.createOptions(options));
            } catch(err) {
                this.error(err);
            }

            return false;
        },
        post: async function(url, data, options) {
            try {
                return await axios.post(this.api ? `/api/${url}` : url, data, this.createOptions(options));
            } catch(err) {
                this.error(err);
            }

            return false;
        },
        error: function(errors) {
            if(errors.response.data.errors instanceof Array) {
                for (let err of errors.response.data.errors) {
                    toast.error(this.trans(err.msg));
                }
            }
        }
    };
}