import {Trans} from "react-i18next";
import JezButton from "../button/button";
import Modal from "@material-ui/core/Modal";
import React, {Fragment, useEffect, useState} from "react";
import Cookies from 'universal-cookie';
import './legalAge.scss';


const LegalAge = () => {
    const [modalOpen, setModalOpen] = useState(true);

    const modalHandleClose = () => {
        setModalOpen(false);
    };

    const accept = () => {
        const cookies = new Cookies();
        cookies.set('legalAge', 1, { path: '/' });
        modalHandleClose();
    };

    useEffect(() => {
        const cookies = new Cookies();
        const legalAge = cookies.get('legalAge');
        setModalOpen(parseInt(legalAge) !== 1);
    }, []);

  return (
      <Fragment>
          {modalOpen && (
          <div className={"legal-age-container"}>
              <Modal
                  className={'legal-age-modal-container'}
                  open={modalOpen}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
              >
                  <div className={'legal-age-modal'}>
                      <h3 className={'headline'}><Trans>legal_age_headline</Trans></h3>

                      <div className={'legal-age-message'}>
                          <Trans>message_only_legal_age_only</Trans>
                      </div>

                      <div className={'modal-action-bar'}>
                          <JezButton margin small className={'btn primary error'}><Trans>label_no_eighteen</Trans></JezButton>
                          <JezButton margin small onClick={accept}><Trans>label_yes_eighteen</Trans></JezButton>
                      </div>
                  </div>
              </Modal>
          </div>
          )}
      </Fragment>
  );
};

export default LegalAge;