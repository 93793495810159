import React, {useEffect} from 'react'
import JezHostGrid from  '../components/hostgrid/hostgrid';
import {Container, makeStyles} from "@material-ui/core";
import JezParagraph from "../components/paragraph/paragraph";
import JezHeading from "../components/heading/heading";
import {Trans} from "react-i18next";
import JezSpacer from "../components/spacer/spacer";
import mask from "../assets/img/mask_76x27x96.png";
import {scrollTop} from "../util/scrollTop";
import ModifyAblePageContent from "../components/ModifyAblePageContent/ModifyAblePageContent";
import ContentContainer from "../components/contentContainer/contentContainer";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto'
    },
    black: {
        background: '#181818;',
        overflow: 'hidden'
    },
    padding: {
        padding: '50px 0 70px',
        position: 'relative'
    },
    margin: {
        margin: '48px 0'
    },
    splitter: {
        margin: '48px 0 0 0'
    },
    parallax1: {
        backgroundImage: 'url(http://coffeecreamthemes.com/themes/scent/site/images/parallax1.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundAttachment: 'fixed',
        height: '200%',
        left: '0',
        opacity: '.25',
        overflow: 'hidden',
        position: 'absolute',
        top: '0',
        width: '100%',
    },
    parallax2: {
        backgroundImage: 'url(http://coffeecreamthemes.com/themes/scent/site/images/parallax2.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundAttachment: 'fixed',
        height: '200%',
        left: '0',
        opacity: '.25',
        overflow: 'hidden',
        position: 'absolute',
        top: '0',
        width: '100%',
    },
    tint: {
        height: '100%',
        top: '0',
        position: 'absolute',
        left: '0',
        width: '100%',
        backgroundImage: 'url(http://coffeecreamthemes.com/themes/scent/site/images/texture.png)',
    }
}));

const EscortDetails = ( ) => {
    const classes = useStyles();

    useEffect(() => {
        scrollTop();
    }, []);

    return (
        <div>
            <div id={'escort'} className={`${classes.padding} ${classes.black}`}>
                <div className={`${classes.parallax1}`}>

                </div>
                <div className={`${classes.tint}`}>

                </div>
                <JezHeading center variant='h2' className={classes.splitter}>
                    <Trans>escort_title</Trans>
                </JezHeading>
                <JezHeading center variant='h4'>
                    <Trans>escort_text</Trans>
                </JezHeading>
                <JezSpacer icon={<img src={mask} alt='mask' />} />
                <Container component='div' className={classes.maxWidth1170}>
                    <JezParagraph>
                        Coming soon
                    </JezParagraph>
                </Container>
            </div>
            <div id={'ladies'} className={`${classes.padding} ${classes.black}`}>
                <JezHeading center variant='h2' className={classes.splitter}>
                    <Trans>our_hosts</Trans>
                </JezHeading>
                <JezHeading center variant='h4'>
                    <Trans>our_hosts_text</Trans>
                </JezHeading>
                <JezSpacer icon={<img src={mask} alt='mask' />} />
                <JezHostGrid limit={'9'} justify={'center'}/>
            </div>
            <div id={'escort'} className={`${classes.padding} ${classes.black}`}>
                <div className={`${classes.parallax2}`}>

                </div>
                <div className={`${classes.tint}`}>

                </div>
                <JezHeading center variant='h2' className={classes.splitter}>
                    <Trans>escort_info</Trans>
                </JezHeading>
                <JezHeading center variant='h4'>
                    <Trans>escort_info_text</Trans>
                </JezHeading>
                <JezSpacer icon={<img src={mask} alt='mask' />} />
                <ContentContainer><ModifyAblePageContent context={'content-after-escort-info'} /></ContentContainer>
            </div>
        </div>
    );
};

export default EscortDetails;
