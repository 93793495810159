import React, {Fragment, useContext, useEffect, useState} from 'react';
import JezContainer from "../../components/container/container";
import {Grid, makeStyles} from "@material-ui/core";
import JezTextEdit from "../../components/textedit/textedit";
import JezTextEditor from "../../components/texteditor/texteditor";
import JezSubmit from "../../components/submit/submit";
import {Trans, useTranslation} from "react-i18next";
import {useJezios} from "../../util/jezios";
import AuthContext from "../../context/auth";
import {buildFormData} from "../../util/tools";
import {toast} from "react-toastify";
import {useNavigate, useParams} from 'react-router-dom';
import JezSelect from "../../components/select/select";
import moment from "moment";
import JezHeading from "../../components/heading/heading";
import JezImageUpload from "../../components/imageUpload/imageUpload";
import {BsImage, BsFillGearFill} from 'react-icons/bs';
import JezButton from "../../components/button/button";
import {isModerator} from "../../util/roles";

const useStyles = makeStyles((theme) => ({
   imageHeadline: {
       margin: '0 0 15px 0'
   },
    floatRight: {
       float: 'right'
    }
}));

const JobForm = () => {
    /**
     * CSS
     */
    const classes = useStyles();
    const navigate = useNavigate();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    if (!auth || (auth && !isModerator(auth))) {
        navigate('/');
    }


    let { jobId } = useParams();

    const request = useJezios(auth, t);

    const [state, setState] = useState({name: '', visible: 1, publish: 1, visibleTo: '', release :'', images: []});
    const [id, setId] = useState(false);
    const [contentEditor, setContentEditor] = useState('');

    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const addImage = (imageList) => {
        setState({...state, images: imageList});
    };

    const submit = async () => {
        const data = buildFormData({...state, content: contentEditor});

        const action = id ? 'put' : 'post';

        const response = await request[action](`/jobs/${id ? id : ''}`, data);

        if(response) {
            setId(response.data.id);
            toast.success(t('message_job_saved'));
        } else {
            toast.error(t('job_save_error'));
        }
    };

    const remove = async () => {
        const response = await request.delete(`/jobs/${id}`);

        if(response) {
            toast.success(t('message_job_deleted'));
            navigate('/moderation/jobs');
        }
    };

    const fetch = async (jobId) => {
        if(jobId) {
            const response = await request.get(`/jobs/${jobId}`);

            if(response) {
                const release = response.data.release ? moment(response.data.release).format('YYYY-MM-DD') : '';
                const visibleTo = response.data.visibleTo ? moment(response.data.visibleTo).format('YYYY-MM-DD') : '';
                setState({...response.data, release: release, visibleTo: visibleTo});
                setContentEditor(response.data.content);
                setId(jobId);
            }
        }
    };

    useEffect(() => {
        fetch(jobId);
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <JezContainer header={t('create_job')} maxWidth={'lg'} padding style={{margin: '96px auto 32px auto'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <JezTextEdit name={'name'} value={state.name} onChange={change} label={t('job_title')}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <JezTextEditor
                            placeholder={t('job_content')}
                            name={'content'}
                            data={contentEditor}
                            state={contentEditor}
                            onChange={(e) => setContentEditor(e.target.value)}
                            setState={(val) => { setContentEditor(val) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <JezHeading center variant='profileform' icon={<BsImage />} className={classes.imageHeadline}>
                            <Trans>label_images</Trans>
                        </JezHeading>
                        <JezImageUpload images={state.images} self={true} onChange={addImage} imageCount={30}/>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <JezHeading center variant='profileform' icon={<BsFillGearFill />}>
                            <Trans>settings_label</Trans>
                        </JezHeading>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} lg={state.publish === 2 ? 8 : 12}>
                                <JezSelect
                                    label={t("status_label")}
                                    name='publish'
                                    required
                                    onChange={(e) => change(e)}
                                    options={[
                                        {key: 1, value: t('published_label')},
                                        {key: 2, value: t('published_at_label')},
                                        {key: 0, value: t('private_label')},
                                    ]}
                                    className={'profileform'}
                                    value={state.publish}
                                />
                            </Grid>
                            {state.publish === 2 && (
                                <Grid item xs={12} sm={12} lg={4}>
                                    <JezTextEdit label={t('release_at_label')} onChange={(e) => change(e)} type={'date'} name={'release'} value={state.release}/>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} lg={state.visible === 2 ? 8 : 12}>
                                <JezSelect
                                    label={t("visible_duration_label")}
                                    name='visible'
                                    required
                                    onChange={(e) => change(e)}
                                    options={[
                                        {key: 1, value: t('always_label')},
                                        {key: 2, value: t('visible_to_label')},
                                    ]}
                                    className={'profileform'}
                                    value={state.visible}
                                />
                            </Grid>
                            {state.visible === 2 && (
                                <Grid item xs={12} sm={12} lg={4}>
                                    <JezTextEdit label={t('visible_to_label')} onChange={(e) => change(e)} type={'date'} name={'visibleTo'} value={state.visibleTo}/>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} lg={10}>
                                <JezSubmit onSubmit={submit}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <JezButton onClick={remove} className={classes.floatRight}>
                                    <Trans>job_delete</Trans>
                                </JezButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </JezContainer>
        </Fragment>
    );
};

export default JobForm;