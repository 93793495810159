import {Container, Grid, makeStyles} from "@material-ui/core";
import JezHeading from "../components/heading/heading";
import {Trans} from "react-i18next";
import JezSpacer from "../components/spacer/spacer";
import mask from "../assets/img/mask_76x27x96.png";
import React from "react";

const useStyles = makeStyles((theme) => ({
    splitter: {
        margin: '48px 0 0 0'
    },
    maxWidth1170: {
        maxWidth: '768px',
        margin: 'auto',
        textAlign: "left"
    },
    black: {
        background: '#181818;',
        overflow: 'hidden'
    },
    padding: {
        padding: '30px 0 70px',
        position: 'relative'
    },
    data: {
        color: '#fff'
    },
}));

const Privacy = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <Grid container spacing={1} id={'contact'} className={`${classes.padding} ${classes.black}`}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <JezHeading center variant='h2' className={classes.splitter}>
                            <Trans>header_privacy</Trans>
                        </JezHeading>
                        <JezSpacer icon={<img src={mask} alt='mask'/>}/>
                    </Grid>
                </Grid>
                <Container component='div' className={classes.maxWidth1170}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>DATENSCHUTZ-INFORMATION</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Die JEzzABER Gentlemen Base freut sich über Ihren  Besuch unserer Website. Datenschutz ist für die IFMG Event-und Gastronomie GMBH ( „wir“  im folgendem Textgenannt) ein zentrales Anliegen. Daher wollen wir Sie hiermit darüber informieren, wie wir Ihre personenbezogenen Daten verarbeiten.
                                <br /><br />
                                Jedenfalls gilt: Die Verarbeitung Ihrer Daten erfolgt ausschließlich im Einklang mit den geltenden Datenschutzgesetzen, insbesondere der Datenschutz-Grundverordnung (DSGVO) und dem Datenschutzgesetz (DSG).</div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>ALLGEMEINE INFORMATIONEN</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist<br />
                                <br />
                                IFMG Event- und Gastronomie GmbH<br />
                                Ausstellungsstraße 50 / C / 2OG<br />
                                1020 Wien Österreich FN 551783x ,<br />
                                UID : ATU76586437<br />
                                Geschäftsführung: Adriana PONEA<br />
                                Post : <a href={'mailto:post@ifmgevent.at'}>post@ifmgevent.at</a>    <br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>DATENVERARBEITUNG</Trans>
                            </JezHeading>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>ZWECK DER DATENVERARBEITUNG</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Wir verarbeiten Ihre personenbezogenen Daten ausschließlich zu den folgenden Zwecken:<br />
                                <br />
                                a) Analyse der Nutzung sowie Verbesserung und Sicherung der Performance unserer Website;<br />
                                b) Prüfung, Erfüllung und Abrechnung von Bestellungen über unseren Webshop;<br />
                                c) Prüfung von, Durchführung von und Antwort auf Reservierungsanfragen über unsere Website;<br />
                                d) Zusendung von Newslettern, Informationen und Marketingmaterial;<br />
                                e) Durchsetzung von oder Verteidigung gegen Rechtsansprüche;<br />
                                f) Einhaltung von Aufzeichnungs-, Auskunfts- und Meldepflichten, soweit gesetzlich erforderlich;<br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>HERKUNFT DER PERSONENBEZOGENEN DATEN</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Diese Daten erheben wir ausschließlich von Ihnen selbst, indem Sie uns diese bei Ihrer Online-Bestellung, bei Ihrer Reservierungsanfrage oder bei Ihrer Newsletter-Anmeldung mitteilen oder indem wir diese anhand Ihrer Nutzung unserer Website erheben.</div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>RECHTLICHE GRUNDLAGE DER VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Diese Verarbeitung Ihrer personenbezogenen Daten zu den unter 1. genannten Zwecken beruht auf den folgenden Rechtsgrundlagen:<br />
                                <br />
                                Datenverarbeitung zu den Zwecken 1a) und 1e):<br />
                                Die Datenverarbeitung zu diesem Zweck beruht darauf, dass wir ein berechtigtes Interesse daran haben, die Performance unserer Website zu verbessern und zu sichern, um unseren Webauftritt für Sie so attraktiv wie möglich zu gestalten, sowie darauf, dass wir ein berechtigtes Interesse daran haben, etwaige Rechtsansprüche, die in Zusammenhang mit unserer Website entstehen könnten, durchzusetzen und uns gegen etwaige solche Rechtsansprüche zu verteidigen (Art 6 Abs 1 lit f DSGVO).
                                <br /><br />
                                Datenverarbeitung zu den Zwecken 1b) und 1c):<br />
                                Die Datenverarbeitung zu diesem Zweck beruht darauf, dass diese Verarbeitung notwendig ist, damit wir unseren Vertrag mit Ihnen erfüllen können bzw. die von Ihnen initiierten vorvertraglichen Reservierungsmaßnahmen vornehmen können (Art 6 Abs 1 lit b DSGVO).<br />
                                Datenverarbeitung zum Zweck 1d):<br />
                                Die Datenverarbeitung zu diesem Zweck beruht auf unserem berechtigten Interesse, Sie über unsere Produkte und Aktionen zu informieren (Art 6 Abs 1 lit f DSGVO). Sie können diesen Zusendungen jederzeit widersprechen (siehe dazu weiter unten).<br />
                                Datenverarbeitung zum Zweck 1f):<br />
                                Die Datenverarbeitung zu diesem Zweck beruht darauf, dass die Verarbeitung dieser Daten notwendig ist, damit wir unsere rechtlichen Verpflichtungen, nämlich die gesetzlichen Aufzeichnungs , Auskunfts- und Meldepflichten, erfüllen können (Art 6 Abs 1 lit c DSGVO).<br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>WEITERLEITUNG IHRER PERSONENBEZOGENEN DATEN AN DRITTE</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Wir ziehen zur Sicherstellung der Sicherheit, Erreichbarkeit und der Effizienz unserer Website und Arbeitsumgebung IT-Dienstleister als Auftragsverarbeiter bei. Auch diesen werden unter Umständen Ihre personenbezogenen Daten zu den oben genannten Zwecken (1a-1f) zugänglich gemacht.<br />
                                <br />
                                Auch ziehen wir zur Erreichung des oben genannten Zweckes 1d) Marketingdienstleister bei, denen Ihre Kontaktdaten zu diesem Zweck unter Umständen zugänglich gemacht werden.<br />
                                <br />
                                Weiters ziehen wir zur Bearbeitung der jeweiligen Bestellung (Zweck 1b) Logistik-, IT- und Zustell-Dienstleister bei, an die wir Ihre personenbezogenen Daten zu diesem Zweck weiterleiten werden.<br />
                                Falls zum oben genannten Zweck 1e) notwendig, dürfen wir Ihre personenbezogenen Daten an unsere RechtsvertreterInnen sowie an Gerichte weiterleiten. Falls zur Erreichung des oben genannten Zwecks 1f) notwendig und gesetzlich geboten, werden wir Ihre personenbezogenen Daten an dazu berechtigte Behörden oder Dritte weiterleiten.<br />
                                <br />
                                Wir übermitteln keine personenbezogenen Daten in Drittländer außerhalb des Europäischen Wirtschaftsraumes, ohne vorher Ihre Zustimmung (die sie jederzeit widerrufen können) einzuholen.<br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>AUFBEWAHRUNG IHRER PERSONENBEZOGENEN DATEN</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Ihre personenbezogenen Daten werden von uns nur solange aufbewahrt, wie dies vernünftigerweise von uns als nötig erachtet wird, um die oben genannten Zwecke zu erreichen, und wie dies nach dem anwendbaren Recht zulässig ist. Wir speichern Ihre personenbezogenen Daten jedenfalls, solange gesetzliche Aufbewahrungspflichten bestehen oder Verjährungsfristen potentieller Rechtsansprüche (insbesondere die siebenjährige Aufbewahrungsfrist nach § 212 UGB) noch nicht abgelaufen sind. Wir werden Ihre Daten länger über diese Frist hinaus aufbewahren, wenn und solange wir diese zur Durchsetzung oder zur Abwehr von konkreten Rechtsansprüchen benötigen.
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>FREIWILLIGKEIT DER BEREITSTELLUNG DER PERSONENBEZOGENEN DATEN</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Die Bereitstellung Ihrer personenbezogenen Daten ist grundsätzlich freiwillig (siehe weiter unten zur Möglichkeit, die Datenverarbeitung über Cookies abzulehnen). Wenn Sie die Daten nicht bereitstellen, können Sie jedoch womöglich nicht alle Funktionen unserer Website nutzen, Sie können u.U. nicht über unseren Webshop bestellen oder wir können diese Bestellung nicht bearbeiten und wir können Ihre Reservierungsanfrage u.U. nicht bearbeiten.<br />
                                <br />
                                Bei Online-Bestellungen sind die folgenden personenbezogenen Daten verpflichtend anzugeben: E Mail-Adresse, Versandadresse (Vorname, Nachname, Adresse, E-Mail-Adresse, Telefonnummer), Rechnungsadresse (Vorname, Nachname, Adresse, E-Mail-Adresse, Telefonnummer), Zahlungsinformation. Die Angabe aller anderen Daten ist freiwillig und nicht notwendig, damit wir Ihre Bestellung bearbeiten können.<br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>VIDEOÜBERWACHUNG IN DER JEzzABER Gentlemen Base</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                In unseren Lokalen werden die Gästebereiche sowie die Eingänge zu unseren Lagerbereichen videoüberwacht.<br />
                                <br />
                                Für diese Videoüberwachung ist jene Gesellschaft datenschutzrechtlich Verantwortlicher, die das jeweilige Restaurant betreibt.<br />
                                Zweck dieser Videoüberwachung ist der vorbeugende Schutz unserer Mitarbeiter und der Gäste unseres Restaurants, der jeweiligen Restaurants selbst und der darin enthaltenen Wertgegenstände gegen Straftaten und andere potentielle Gefährdungen sowie im Anlassfall die Auswertung der Aufzeichnungen zu Beweiszwecken zur Verteidigung gegen oder zur Geltendmachung von Rechtsansprüchen.<br />
                                <br />
                                Rechtsgrundlage der Datenverarbeitung zu diesem Zweck ist, dass wir an diesem vorbeugenden Schutz und im Anlassfall an der Rechtsdurchsetzung ein berechtigtes Interesse haben, um mögliche Diebstähle, Vandalismus-Akte und andere Rechtsverstöße ebenso wie Unfälle aufklären zu können (Art 6 Abs 1 lit f DSGVO; § 12 Abs 2 Z 4 DSG).<br />
                                Die Videoaufzeichnungen werden zu keinen anderen Zwecken verwendet.<br />
                                <br />
                                Die Videoaufzeichnungen werden ausschließlich lokal gespeichert und werden nur im Anlassfall von der Geschäftsführung sowie im Falle, dass die Aufnahmen zu Beweiszwecken dienen sollen, von den an der Rechtsverfolgung sinnvollerweise Beteiligten (also etwa den zuständigen Strafverfolgungsbehörden) eingesehen. Eine Weitergabe oder Zugänglichmachung der Aufnahmen an andere Dritte erfolgt nicht.<br />
                                <br />
                                Die angefertigten Videoaufnahmen werden 72 Stunden nach Erstellung gelöscht, sofern nicht ein berechtigtes Interesse (z.B. weil der Verdacht einer aufzuklärenden, unzulässigen Handlung im Restaurant vorliegt) oder eine gesetzliche Pflicht (z.B. Anforderung der Polizei) zur längeren Aufbewahrung besteht. Sofern die Aufnahmen über die 72-Stunden-Frist hinaus aufbewahrt werden, löschen wir die Aufnahmen nach Wegfall des berechtigten Interesses oder der gesetzlichen Pflicht zur Aufbewahrung.<br />
                                <br />
                                Es erfolgt keine Datenverarbeitung außerhalb des Europäischen Wirtschaftsraumes. Automatische Entscheidungsfindung iSd Art 22 DSGVO erfolgt nicht. Ein Besuch der JEzzABER-LOKALE, ohne dass Sie unter Umständen von dieser Videoüberwachung erfasst werden, ist leider nicht möglich.<br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>NEWSLETTER</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Wenn Sie bei uns reservieren, bei uns bestellen oder unabhängig davon unseren Newsletter abonnieren, informieren wir Sie regelmäßig per E-Mail oder per Post über unsere Produkte und Angebote. Sie haben jederzeit die Möglichkeit, den Newsletter wieder abzubestellen, die genauen Informationen dazu finden Sie im Newsletter selbst. Sie können sich jedoch auch an die oben angegebene Kontaktadresse wenden, um eine weitere Zusendung abzulehnen.
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>COOKIES</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Wir verwenden auf unserer Website zu den oben unter 1. genannten Zwecken Cookies. Dabei handelt es sich um kleine Textdateien, die auf Ihrem Gerät abgelegt werden, zur Erhebung und Speicherung statistischer Informationen dienen und für gewisse Websitefunktionen mitunter notwendig sind (zB Abspielen von Videos, zum Anbieten eines Warenkorbes). Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach dem Schließen Ihres Browsers, wieder gelöscht (sogenannte Sitzungs-Cookies). Andere verbleiben auf Ihrem Gerät und ermöglichen uns, Ihren Browser beim nächsten Besuch wieder zu erkennen (dauerhafte Cookies). Die Bestanddauer von dauerhaften Cookies können Sie in Ihren Browser-Einstellungen einsehen.<br />
                                <br />
                                Anhand der auf Ihrem Gerät gesetzten Cookies ermitteln wir statistische Daten, um die Performance unserer Website zu verbessern, Problemfelder zu identifizieren und Ihnen so das bestmögliche Kundenerlebnis zu bieten. Wir werden diese statistischen Daten nie mit anderen Daten verknüpfen und keinen Bezug zu identifizierten oder identifizierbaren Personen herstellen. Die statistischen Daten werden ausschließlich in anonymisierter Form ausgewertet. Diese Datenverarbeitung beruht auf unserem berechtigten Interesse, unsere Website zu verbessern und Probleme mit der Website zu beheben (Art 6 Abs 1 lit f DSGVO).<br />
                                <br />
                                Selbstverständlich können Sie Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln oder generell deren Annahme ausschließen können. Nähere Informationen dazu finden Sie in den Benutzungshinweisen Ihres Browsers. Einige der von uns verwendeten Cookies sind für bestimmte Funktionen unserer Website notwendig (zB Abspielen von Videos, Warenkorb). Bei Nichtannahme von Cookies kann daher die Funktionalität der Website eingeschränkt sein und Sie können unter Umständen nicht über unseren Webshop bestellen.<br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>IHRE RECHTE</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Welche Rechte haben Sie nach dem geltenden Datenschutzrecht?<br />
                                Sie haben das Recht, (i) von uns zu erfahren, ob und welche personenbezogenen Daten wir über Sie gespeichert haben, und Kopien dieser Daten zu erhalten (Art 15 DSGVO), (ii) von uns zu verlangen, dass wir Ihre personenbezogenen Daten berichtigen, ergänzen oder löschen, wenn diese falsch sind oder nicht rechtskonform verarbeitet werden (Art 16, 17 DSGVO), (iii) von uns zu verlangen, dass wir die Verarbeitung Ihrer personenbezogenen Daten einschränken (Art 18 DSGVO), (iv) unter bestimmten Umständen Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder einer/m Dritten zu übermitteln (Recht auf Datenübertragbarkeit, Art 20 DSGVO) und (v) unter bestimmten Umständen der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen (Art 21 DSGVO). Wenn Sie eines oder mehrere dieser Rechte ausüben wollen, können Sie sich gerne an uns wenden.<br />
                                <br />
                                Zusätzlich haben Sie das Recht, bei der Datenschutzbehörde (Kontaktdaten siehe <a href={'www.dsb.gv.at'} target={'_blank'}>www.dsb.gv.at</a> ) Beschwerde einzureichen, wenn Sie glauben, dass Sie in Ihren Datenschutzrechten verletzt wurden.<br />
                                Sie können die Datenschutzgrundverordnung (DSGVO) online abrufen unter.<br />
                                <a href={'http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE'} target={'_blank'}>http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE</a><br />
                                Sie können das Datenschutzgesetz (DSG) in seiner geltenden Fassung online abrufen unter:<br />
                                <a href={'https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597'} target={'_blank'}>https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597</a><br />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default Privacy;