import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from '@material-ui/core/InputLabel';
import './select.scss';
import Select  from "react-select";
import {useTranslation} from "react-i18next";

/**
 * Used NPM Lib https://www.npmjs.com/package/react-select
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const JezSelect = (props) => {
    const {t} = useTranslation();

    const change = function(e) {
        if(e) {
            props.onChange({target: {name: props.name, value:  e instanceof Array ? e.map((item) => item.value ) : e.value}});
        }
    };

    let optionsBefore = [];
    let options = [];
    let groups = {};
    let existingOptions = [];

    let currentCategory = false;

        for(let item of props.options) {
            if (item.category) {
                currentCategory = item.category;
                groups[currentCategory] = ({label: currentCategory, options: []});
            } else {
                let opt = {
                    label: item.value instanceof Array ? item.value.join('') : item.value,
                    value: item.key
                };

                if(currentCategory) {
                    groups[currentCategory].options.push(opt);
                } else {
                    if(item.before) {
                        optionsBefore.push(opt);
                    } else {
                        options.push(opt);
                    }
                }

                existingOptions.push(opt);
            }
        }

    options = optionsBefore.concat(Object.values(groups).concat(options));

    let selected = existingOptions.filter(item => { return item.value === props.value || (props.value instanceof Array && props.value.includes(item.value)); });

    // const handleMultiValueClick = (e, props) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //
    //     console.log(e, props);
    //     return false;
    // };
    //
    // const tagSelect = (e) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //
    //     console.log("wwwww");
    //
    //     return false;
    // };

    // const MultiValueLabel = props => {
    //     console.log(props);
    //     return (
    //         <div onClick={(e) => handleMultiValueClick(e, props)} style={{position: 'relative'}}>
    //             <div style={{position: 'absolute', top: '-60px', border: '1px solid: #fff', background: '#fff', width: '300px', zIndex: 1}}>
    //                 <input type={'checkbox'} onMouseDown={(e) => { console.log(e); return false; }} /><label>Das ist eine Sprache</label>
    //             </div>
    //             <components.MultiValueLabel {...props}><select id={'test-select'} onMouseDown={tagSelect}><option>1</option><option>2</option></select></components.MultiValueLabel>
    //         </div>
    //     );
    // };

    return (
        <div className='input-wrap'>
            <FormControl className={'select' + (selected.length === 0 ? ' select-placeholder' : '')}>
                <InputLabel id={props.id}>{props.label}</InputLabel>
                {props.multiple && (<Select noOptionsMessage={() => t('select_no_options')} placeholder={t('select_placeholder')} {...props} classNamePrefix={'jez-selection'} isMulti className={'jez-selection'} options={options} value={selected} onChange={change}/>)}
                {!props.multiple && (<Select noOptionsMessage={() => t('select_no_options')} placeholder={t('select_placeholder')} classNamePrefix={'jez-selection'} className={'jez-selection'} options={options} value={selected} onChange={change}/>)}

                {props.helperText && (
                    <FormHelperText>{props.helperText}</FormHelperText>
                )}
            </FormControl>
            {props.error && <p className='error-text'>{props.errorText}</p>}
        </div>
    );
};

export default JezSelect;
