import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import axios from "axios";
import AuthContext from '../../context/auth';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {isCustomer, isAdmin, isModerator} from "../../util/roles";
import {toast} from 'react-toastify';
import JezContainer from "../../components/container/container";
import SocketContext from "../../context/socket";
import JezTable from "../../components/table/table";
import {rand} from "../../util/tools";
import {useLocation, useNavigate} from "react-router-dom";
import OptInButton from "../../components/OptInButton/OptInButton";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: '48px auto',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    container: {
        width: '100%',
        height: '800px',
        margin: '30px 0 0',
        position: 'relative'
    },
    table: {
        color: '#fff'
    },
    button: {
        background: 'transparent',
        border: '1px solid #807154 !important',
        color: '#807154',
        margin: '5px',
        padding: '2px 3px;',
        cursor: 'pointer'
    },
    buttonActive: {
        background: '#807154',
        border: '1px solid #807154 !important',
        color: '#fff',
        margin: '5px',
        padding: '2px 3px;',
        cursor: 'pointer'
    },
    reviewIcon: {
        margin: '-2px 5px 0 0',
        verticalAlign: 'middle'
    },
    smallIcon: {
        margin: '-2px 0 0 5px',
        verticalAlign: 'middle',
        fontSize: '14px'
    },
    missingFields: {
        color: '#b4ab6e'
    },
    changeRole: {
        color: '#fff',
        border: '1px solid #fff !important',
        cursor: 'pointer'
    },
    loader: {
        color: '#b4ab6e',
        fontSize: 50,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
    isOnline: {
        color: '#b4ab6e'
    },
    loaderTime: {
        height: 10,
        lineHeight: 0,
        margin: '-18px 0 0 0',
        display: 'inline-block'
    },
    loaderTimeContainer: {
        height: 10,
        lineHeight: 0,
        margin: '-18px 0 0 0',
    }
}));

const UserModerationForm = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {socket} = useContext(SocketContext);
    const {t} = useTranslation();
    const navigate = useNavigate();


    if (!auth || (auth && !isModerator(auth))) {
        navigate('/');
    }

    /**
     * STATES
     */
    const [rows, setRows] = useState([]);
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState([]);
    const [filter, setFilter] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const location = useLocation();

    const rowsRef = useRef();
    rowsRef.current = rows;

    const fetchData = async (page, filter) => {
        try {
            let query = [];
            if(!!filter && !!filter.over_all) {
                query.push(`filter[username]=${filter.over_all[0]}`);
            }

            if(!!filter && !!filter.roles) {
                for(let ii = 0; ii < filter.roles.length; ii++) {
                    query.push(`filter[roles][]=${filter.roles[ii]}`);
                }
            }

            if(query.length > 0) {
                query = '&' + query.join('&')
            }

            let response = await axios.get(`/api/users/all?page=${page}${query}`, {
                headers: {'x-auth-token': auth.token},
            });

            response.data.items = response.data.items.map((item, index) => {
                return {...item, id: index}
            });

            for (const index in response.data.items) {
                const responseRaw = {data: response.data.items[index].profile};

                const responseUserRaw = {data: response.data.items[index].user};
                // const responseUserRaw = await axios.get(`/api/users/${response.data[index]._id}?pending=1`, {
                //     headers: {'x-auth-token': auth.token},
                // });

                let verifiedRequiredFields = 0;
                let missingFields = [t('phone'), t('email'), t('username')];

                for (let key in responseRaw.data) {
                    if (responseRaw.data[key] && responseRaw.data[key].value) {
                        //if (responseRaw.data[key].required) {
                            if(responseRaw.data[key].status === 1) {
                                if(missingFields.indexOf(t(responseRaw.data[key].field)) !== -1) {
                                    verifiedRequiredFields++;
                                    missingFields = missingFields.filter((item) => item !== t(responseRaw.data[key].field));
                                }
                            }
                        //}
                    }
                }

                for (let key in responseUserRaw.data) {
                    if (responseUserRaw.data[key] && responseUserRaw.data[key].value !== undefined) {
                        // if (responseUserRaw.data[key].required) {
                            if(responseUserRaw.data[key].status === 1) {
                                if(missingFields.indexOf(t(responseUserRaw.data[key].field)) !== -1) {
                                    verifiedRequiredFields++;
                                    missingFields = missingFields.filter((item) => item !== t(responseUserRaw.data[key].field));
                                }
                            }
                        //}
                    }
                }

                response.data.items[index].verifyAble = verifiedRequiredFields === 3 || isCustomer(response.data[index]);
                response.data.items[index].missingFields = missingFields;
            }

            response.data.items = response.data.items.filter((item) => isAdmin(auth) || !item.roles.includes('ROLE_MODERATOR'));

            setRows(response.data.items);
            setPages(response.data.max);
            setPage(page);
            setFilter(!!filter ? filter : {});
            setDataLoaded(true);
            if(socket) {
                socket.emit('io_req_is_online');
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        if(socket) {
            socket.on('io_rsp_is_online', (response) => {
                let update = [...rowsRef.current];
                for(let ii = 0; ii < update.length; ii++) {
                    if(response[update[ii]._id]) {
                        let dom = document.getElementById(`online-${update[ii]._id}`);

                        if(dom) {
                            dom.innerHTML = `<span class="${classes.isOnline}">${t('label_online')}</span>`;
                        }
                    }
                }
            });
        }

        fetchData();

        return () => {
            if(socket) {
                socket.off('io_rsp_is_online')
            }
        }
    }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

    const activateUser = async (e) => {
        e.preventDefault();

        const id = e.target.getAttribute('data-id');

        try {
            await axios.put(`/api/users/${id}/activate`, {}, {headers: {'x-auth-token': auth.token}});

            toast.success(t('message_user_activated'));

            fetchData(page, filter);
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    const deactivateUser = async (e) => {
        e.preventDefault();

        const id = e.target.getAttribute('data-id');

        try {
            await axios.put(`/api/users/${id}/deactivate`, {}, {headers: {'x-auth-token': auth.token}});

            toast.success(t('message_user_deactivated'));

            fetchData(page, filter);
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    const deleteUser = async (e, id) => {
        e.preventDefault();

        try {
            await axios.delete(`/api/users/${id}`, {headers: {'x-auth-token': auth.token}});

            toast.success(t('message_user_deleted'));

            fetchData(page, filter);
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    const changeRole = async (e) => {
        e.preventDefault();

        const id = e.target.getAttribute('data-id');

        try {
            await axios.put(`/api/users/${id}/role`, {role: e.target.value}, {headers: {'x-auth-token': auth.token}});

            toast.success(t('message_user_role_changed'));

            fetchData(page, filter);
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    const columns = [
        {name: 'online', title: t('moderation_table_column_online'), dataCellOptions: {component: 'th'}, render: (item) => { return (<div id={`online-${item._id}`}><Trans>label_offline</Trans></div>); }},
        {name: 'username', title: t('moderation_table_column_user'), dataCellOptions: {style:{minWidth: 100}}},
        {name: 'roles', title: t('moderation_table_column_role'), render: (item) => {
                    return (<div>
                        <select id={`role-field-${item._id}`} className={classes.changeRole} defaultValue={item.roles[0]} data-id={item._id}
                                onChange={changeRole}>
                            <optgroup label={t('role_user_label')}>
                                <option value={'ROLE_USER'}>{t('role_user')}</option>
                                <option value={'ROLE_USER_SILVER'}>{t('role_user_silver')}</option>
                                <option value={'ROLE_USER_GOLD'}>{t('role_user_gold')}</option>
                                <option value={'ROLE_USER_PLATINUM'}>{t('role_user_platinum')}</option>
                            </optgroup>
                            <optgroup label={t('role_host_label')}>
                                <option value={'ROLE_HOST'}>{t('role_host')}</option>
                                <option value={'ROLE_HOST_ELEGANCE'}>{t('role_host_elegance')}</option>
                                <option value={'ROLE_HOST_LUXURY'}>{t('role_host_luxury')}</option>
                            </optgroup>
                            {isAdmin(auth) && (<option value={'ROLE_MODERATOR'}>{t('role_moderator')}</option>)}
                            {isAdmin(auth) && (<option value={'ROLE_ADMIN'}>{t('role_admin')}</option>)}
                        </select>
                    </div>);
            },
            filter: {
                entries: [
                    {key: 'ROLE_USER', label: t('role_user')},
                    {key: 'ROLE_USER_SILVER', label: t('role_user_silver')},
                    {key: 'ROLE_USER_GOLD', label: t('role_user_gold')},
                    {key: 'ROLE_USER_PLATINUM', label: t('role_user_platinum')},
                    {key: 'ROLE_HOST', label: t('role_host')},
                    {key: 'ROLE_HOST_ELEGANCE', label: t('role_host_elegance')},
                    {key: 'ROLE_HOST_LUXURY', label: t('role_host_luxury')},
                ].concat(isAdmin(auth) ? [{key: 'ROLE_MODERATOR', label: t('role_moderator')}, {key: 'ROLE_ADMIN', label: t('role_admin')}]: [])
            }
        },
        {name: 'profile', title: t('moderation_table_column_profile'), render: (item) => {
                return (<div>
                    <a target={'_blank'} href={`/profile/${item._id}`}><Trans>button_view</Trans><OpenInNewIcon
                        className={classes.smallIcon}/></a>
                </div>)
            },
            dataCellOptions: {style:{minWidth: 100}}
        },
        {name: 'pending', title: t('moderation_table_column_reviews'), render: (item) => {
                return (<div>
                    <a target={'_blank'} href={`/moderation/user/${item._id}`}>{item.pending > 0 ? (
                        <span><VisibilityOutlinedIcon
                            className={classes.reviewIcon}/><strong>{item.pending}</strong> <Trans>button_fields</Trans></span>) : (
                        <span><CheckOutlinedIcon className={classes.reviewIcon}/><Trans>button_completed</Trans></span>)}<OpenInNewIcon
                        className={classes.smallIcon}/></a>
                </div>);
            },
            dataCellOptions: {style:{minWidth: 100}}
        },
        {name: 'action', title: t('moderation_table_column_activated'), render: (item) => {
                if (!item.verifyAble) {
                    return (<span style={{
                        whiteSpace: 'normal',
                        lineHeight: '12px'
                    }}><Trans>moderation_missing_verified_required_fields</Trans><br />
                            <Trans>moderation_this_fields_verify</Trans>: {item.missingFields.map((itemField, index) => (
                            <Fragment key={rand()}>
                                <span className={classes.missingFields}>{itemField}</span>
                                {item.missingFields.length - 1 > index ? ', ' : ''}
                            </Fragment>
                        ))}
                        </span>);
                }

                return (<div>
                    <button className={item.verified === false ? classes.buttonActive : classes.button}
                            data-id={item._id} onClick={deactivateUser}><Trans>button_no</Trans></button>
                    <button className={item.verified === true ? classes.buttonActive : classes.button}
                            data-id={item._id} onClick={activateUser}><Trans>button_yes</Trans></button>
                </div>);
            }
        },
        {name: 'action2', title: t('moderation_table_column_delete'), render: (item) => {
                return (
                    <OptInButton onSubmit={async (e, id) => {
                        await deleteUser(e, id);
                    }} className={['error'].join(' ')} id={item._id} label={'Löschen'}>
                        <h4>Möchten Sie diesen Benutzer löschen?</h4>
                    </OptInButton>
                );
            }},
    ];

    const updateLoader = () => {
        const dom = document.getElementById('loader-time');

        if(dom) {
            if(dom.textContent.length < 20) {
                dom.textContent += '.';
            } else {
                dom.textContent = '';
            }

            window.setTimeout(updateLoader, 250);
        }
    };

    useEffect(() => {
        updateLoader();
        // eslint-disable-next-line
    }, []);

    return (
        <JezContainer className={classes.maxWidth1170} padding style={{margin: '96px auto 32px auto'}} header={t('admin_user_management')}>
            <div className={classes.container}>
                <JezTable rows={rows} pages={pages} page={page} fetch={fetchData} columns={columns} loading={!dataLoaded}/>
            </div>
        </JezContainer>
    )
};

export default UserModerationForm;