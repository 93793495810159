import React, {Fragment, useEffect, useState} from 'react'
import {Grid, makeStyles} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import JezHeading from "../heading/heading";
import PropTypes from "prop-types";
import Link from "../link/link";
import JezGallery from "../gallery/gallery";
import {BsImage} from "react-icons/bs";
import {FaRegPlayCircle} from "react-icons/fa";
import "./listItem.scss";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 15
    },
    content: {
        fontSize: 18,
        '& p': {
            fontSize: 18,
            color: '#b4ab6e',
            padding: "0px 0px 0px 20px"
        },
        '& li': {
            fontSize: 18,
            color: '#b4ab6e',
            padding: "0px 0px 0px 20px"
        }
    },
    closed: {
        maxHeight: 100,
        overflow: 'hidden'
    },
    expand: {
        color: '#b4ab6e',
        border: '1px solid #b4ab6e',
        borderRadius: 5,
        padding: '5px 10px',
        cursor: 'pointer',
        '&:hover': {
            background: '#b4ab6e',
            color: '#fff'
        }
    },
    galleryImage: {
        margin: '10px 0 0 0',
        width: '100%'
    },
    detailButton: {
        background: "transparent",
        backgroundColor: "transparent",
        border: "1px solid #b4ab6e",
        borderRadius: "5px",
        color: "#ffffff",
        cursor:"pointer",
        fontFamily:"Roboto",
        fontSize: "17px",
        marginTop: "15px",
        padding: "5px 5px 5px 5px",
        textAlign:"right"
    },
    galleryHeadline: {
        margin: '20px 0 10px 0 !important'
    }

}));

const JezItem = ({ section, id, expanded, collapseable, title, content, images, imageSize }) => {
    /**
     * CSS
     */
    const classes = useStyles();

    const [collapse, setCollapse] = useState(false);
    const [pictures, setPictures] = useState([]);

    const trigger = {};

    collapseable = collapseable === undefined ? true : collapseable;

    const {t} = useTranslation();

    const click = () => {
        setCollapse(!collapse);
    };

    const whatsappLink = (content) => {
        return content.replace(/<a.*?href="(.*?)".*?>(.*?)<\/a>/g, (match, href, text) => {
            if (href.includes('wa.me')) {
                return (
                    `<a class="whatsappLink" href="${href}">
                        ${text}
                        <span class="waicon">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1569683925316" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <defs></defs>
                                <path d="M713.5 599.9c-10.9-5.6-65.2-32.2-75.3-35.8-10.1-3.8-17.5-5.6-24.8 5.6-7.4 11.1-28.4 35.8-35 43.3-6.4 7.4-12.9 8.3-23.8 2.8-64.8-32.4-107.3-57.8-150-131.1-11.3-19.5 11.3-18.1 32.4-60.2 3.6-7.4 1.8-13.7-1-19.3-2.8-5.6-24.8-59.8-34-81.9-8.9-21.5-18.1-18.5-24.8-18.9-6.4-0.4-13.7-0.4-21.1-0.4-7.4 0-19.3 2.8-29.4 13.7-10.1 11.1-38.6 37.8-38.6 92s39.5 106.7 44.9 114.1c5.6 7.4 77.7 118.6 188.4 166.5 70 30.2 97.4 32.8 132.4 27.6 21.3-3.2 65.2-26.6 74.3-52.5 9.1-25.8 9.1-47.9 6.4-52.5-2.7-4.9-10.1-7.7-21-13z"></path>
                                <path d="M925.2 338.4c-22.6-53.7-55-101.9-96.3-143.3-41.3-41.3-89.5-73.8-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6 0.3-119.3 12.3-174.5 35.9-53.3 22.8-101.1 55.2-142 96.5-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9 0.3 69.4 16.9 138.3 48 199.9v152c0 25.4 20.6 46 46 46h152.1c61.6 31.1 130.5 47.7 199.9 48h2.1c59.9 0 118-11.6 172.7-34.3 53.5-22.3 101.6-54.3 142.8-95.2 41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5 0.3-60.9-11.5-120-34.8-175.6z m-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-0.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-0.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-0.6 99.6-39.7 192.9-110.1 262.7z"></path>
                            </svg>
                        </span>
                    </a>`
                );
            } else {
                return match;
            }
        });
    };

    const fetch = (images) => {
        if(images) {
            console.log(images);
            let list = [];
            for (const item of images) {
                list.push({
                    // originalHeight: '250px',
                    // original: `/api/pictures/${imageId}`,
                    // thumbnail: `/api/pictures/${imageId}`,
                    src: item.src.media,
                    thumbnail: item.src.media,
                    videoId: item.src.video,
                    thumbnailWidth: 342,
                    thumbnailHeight: 260,
                });
            }
            setPictures(list);
        }
    };
    const textSize = 12 - imageSize;
    useEffect(() =>  {
        fetch(images)
        // eslint-disable-next-line
    }, [images]);

    useEffect(() => {
        setCollapse(!!expanded);
    }, [expanded]);
        return (<div className={classes.container}>
            <JezHeading variant='h3'>
                {id && (<Link to={`/${section}/${id}`}>{title}</Link>)}
                {!id ? title : ''}
            </JezHeading>
            <Grid container spacing={1}>
                {pictures.length > 0 && (
                    <Grid item xs={12} m={imageSize} sm={imageSize} lg={imageSize}>
                        <div className={'list-item-image-container'}>
                            {id && (<Link to={`/${section}/${id}`}><img alt={t('image_label')} src={pictures[0].src} className={classes.galleryImage}/></Link>)}
                            {!id && (<img alt={t('image_label')} src={pictures[0].src} className={classes.galleryImage}/>)}
                            {pictures[0].videoId && (<FaRegPlayCircle className={'gallery-play-video-icon'} onClick={() => { trigger.openGallery(0); console.log(trigger); } }/>)}
                        </div>
                        {/*<ImageGallery items={pictures} showPlayButton={false} showThumbnails={false} showFullscreenButton={false} additionalClass={classes.galleryImage}/>*/}
                    </Grid>
                )}
                <Grid item xs={12} m={textSize} sm={textSize} lg={textSize}>
                    <div className={`${classes.content} ${collapse ? '': classes.closed}`} dangerouslySetInnerHTML={{__html: whatsappLink(content)}} />
                </Grid>
                {
                    expanded === false ?
                    <Grid container spacing={1} justify={"flex-end"}>
                        <div className={classes.detailButton}>
                            <Link to={`/${section}/${id}`}>
                                <Trans>link_read_more</Trans>
                            </Link>
                        </div>
                    </Grid> : null
                }
            </Grid>
            <Grid>
                {collapse && (<div style={{position: pictures.length === 1 ? 'fixed' : 'static', left: 5000}}>
                    <JezHeading center variant='profileform' icon={<BsImage />} className={classes.galleryHeadline}>
                        <Trans>headline_gallery</Trans>
                    </JezHeading>
                    <JezGallery images={pictures} trigger={trigger}/>
                </div>)}
            </Grid>
            {collapseable && (<small className={classes.expand} onClick={click}>{collapse ? t(`${section}_collapse`): t(`${section}_expand`)}</small>)}
        </div>);
};

JezItem.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default JezItem;