import React from 'react'
import JezLocationGrid from "../components/locationgrid/locationgrid";
import JezHeading from "../components/heading/heading";
import {Trans, useTranslation} from "react-i18next";
import JezSpacer from "../components/spacer/spacer";
import HouseIcon from '@material-ui/icons/House';
import ModifyAblePageContent from "../components/ModifyAblePageContent/ModifyAblePageContent";
import ContentContainer from "../components/contentContainer/contentContainer";


const LocationList = ( ) => {
    const {t} = useTranslation();

    return (
        <div style={{margin: '116px 0 48px 0px'}}>
            <JezHeading center variant='h2'>
                <ModifyAblePageContent context={'content-section-our-locations-headline'} allowTeaser={false} defaultValue={t('our_locations')} classes={'text-color-white'}/>
            </JezHeading>
            <JezHeading center variant='h4'>
                <ModifyAblePageContent context={'content-section-our-locations-sub-headline'} allowTeaser={false}/>
            </JezHeading>
            <JezSpacer icon={<HouseIcon/>}/>
            <ContentContainer><ModifyAblePageContent context={'content-before-locations'} /></ContentContainer>
            <JezLocationGrid searchAble={true}/>
            <ContentContainer><ModifyAblePageContent context={'content-after-locations'} /></ContentContainer>
        </div>
    );
};

export default LocationList
