import {Container, Grid, makeStyles, Table} from "@material-ui/core";
import React, {Fragment, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import JezHeading from "../../../components/heading/heading";
import mask from "../../../assets/img/mask_76x27x96.png";
import JezSpacer from "../../../components/spacer/spacer";
import {toast} from "react-toastify";
import axios from "axios";
import JezButton from "../../../components/button/button";
import InfoBox from "../../../components/infoBox/infoBox";
// import Map from "../../../components/map/map";
import {rand} from "../../../util/tools";

import "./contactSection.scss";
import ModifyAblePageContent from "../../../components/ModifyAblePageContent/ModifyAblePageContent";

const useStyles = makeStyles((theme) => ({
    splitter: {
        margin: '48px 0 0 0'
    },
    black: {
        background: '#181818;',
        overflow: 'hidden'
    },
    padding: {
        padding: '50px 0 70px',
        position: 'relative'
    },
    officeTimeTable: {
        fontSize: 0,
        textAlign: 'center',
        margin: '8px 0 0 0',
        '& > *': {
          margin: '2px 0'
        },
        '& *': {
            fontSize: 14,
            color: '#b4ab6e'
        }
    },
    officeTimeElement: {
        margin: '0 5px 0 0'
    },
    officeTimeTableDay: {
        width: 80,
        display: 'inline-block',
        textAlign: 'left',
    },
    officeTimeTableTimes: {
        display: 'inline-block',
        textAlign: 'left',
    },
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto'
    },
    openingFilter: {
        margin: '28px 0 16px 0',
        padding: 0,
        fontSize: 0,
        listStyleType: 'none',
        textAlign: 'center',
        '& li': {
            fontSize: 16,
            display: 'inline-block',
            margin: '4px 4px 0 0'
        }
    },
    address: {
        textAlign: 'left',
        fontSize: 14,
        color: '#b4ab6e',
        margin: '6px 0 0 0'
    },
    mapContainer: {
        border: '1px solid #b4ab6e',
        borderRadius: 3,
        overflow: "hidden",
        width: 400
    }
}));

const ContactSection = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    const {t} = useTranslation();

    /**
     * States
     */
    const [locations, setLocations] = useState([]);
    const [openTimes, setOpenTimes] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [address, setAddress] = useState(null);
    const [mapApiKey, setMapApiKey] = useState(null);
    const [geoCord, setGeoCord] = useState(null);


    /**
     * Hooks
     */
    useEffect(() => {
        fetch();
        fetchSystem();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(locations.length) {
            setSelectedIndex(-1);
        }
    }, [locations]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSetOpeningTimes();
        getAddress();
    }, [selectedIndex]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [mapApiKey]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () => {
        try {
            const response = await axios.get(`/api/locations?filter[slug][]=tagesstudio&filter[slug][]=nightclub`, {});
            setLocations(response.data.reverse());

        }catch (errors) {
            for (let err of errors.response.data.errors) {
                toast.error(t(err.msg));
            }
        }
    }

    const fetchSystem = async () => {
        try {
            const response = await axios.get('/api/system/public?field=map_api_key');

            setMapApiKey(response.data);
        } catch (err) {
            console.log(err);
        }
    }

    const getSetOpeningTimes = () => {
        if(!locations.length) {
            return false;
        }
        let openTimes = [];
        if(selectedIndex === -1) {
            for(let ii = 0; ii < locations[0].business_hours.length; ii++) {
                const item = locations[0].business_hours[ii];
                const item2 = locations[1].business_hours ? locations[1].business_hours[ii] : null;
                if(item2) {
                    openTimes.push({day: item.day, times: [{from: item.from, to: item.to}, {from: item2.from, to: item2.to}]});
                } else {
                    openTimes.push({day: item.day, times: [{from: item.from, to: item.to}]});
                }
            }
        } else {
            for(let ii = 0; ii < locations[selectedIndex].business_hours.length; ii++) {
                const item = locations[selectedIndex].business_hours[ii];
                openTimes.push({day: item.day, times: [{from: item.from, to: item.to}]});
            }
        }

        setOpenTimes(openTimes);
    }

    const getAddress = () => {
        if(!locations.length) {
            return false;
        }

        const index = selectedIndex === -1 ? 0 : selectedIndex;
        setAddress({...locations[index].address});

        if(locations[index].maps_url) {
            const match = locations[index].maps_url.match('\/@([0-9\.]+),([0-9\.]+),[0-9]+z');
            if(match) {
                setGeoCord({
                    lat: parseFloat(match[1]),
                    lng: parseFloat(match[2])
                })
            }
        } else {
            setGeoCord(null)
        }
    }

    const formatTime = (time) => {
        const match = time.match(/([0-9]{1,2}):([0-9]{1,2})/);

        const hours = parseInt(match[1]) > 9 ? match[1] : `0${parseInt(match[1])}`;
        const minutes = parseInt(match[2]) > 9 ? match[2] : `0${parseInt(match[2])}`;

        return `${hours}:${minutes}`;
    }

    const getDayName = (item) => {
        let value = '';
        switch(item.day) {
            case 1:
                value = t('day_monday');
                break;
            case 2:
                value = t('day_tuesday');
                break;
            case 3:
                value = t('day_wednesday');
                break;
            case 4:
                value = t('day_thursday');
                break;
            case 5:
                value = t('day_friday');
                break;
            case 6:
                value = t('day_saturday');
                break;
            case 7:
                value = t('day_sunday');
                break;
            default:
                value = t('day_sunday');
        }

        return value;
    }

    function getLocation() {
        if (locations[selectedIndex === -1 ? 0 : selectedIndex].maps_url && locations[selectedIndex === -1 ? 0 : selectedIndex].maps_url !== "") {
            return locations[selectedIndex === -1 ? 0 : selectedIndex].maps_url
        } else {
            return `https://www.google.com/maps/place/${address.street},+${address.zip}+${address.city},+${address.country}`;
        }
    }

    return (
        <Fragment>
            <Grid container spacing={1} id={'contact'} className={`${classes.padding} ${classes.black}`}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <JezHeading center variant='h2' className={classes.splitter}>
                                <ModifyAblePageContent context={'content-section-contact-headline'} allowTeaser={false} defaultValue={t('header_contact')} classes={'text-color-white'}/>
                            </JezHeading>
                            <JezHeading center variant='h4'>
                                <ModifyAblePageContent context={'content-section-contact-sub-headline'} allowTeaser={false}/>
                            </JezHeading>
                            <JezSpacer icon={<img src={mask} alt='mask'/>}/>
                            <ul className={classes.openingFilter}>
                                <li key={rand()}><JezButton className={selectedIndex === -1 ? 'active' : ''} secondary outlined small onClick={() => setSelectedIndex(-1)}><Trans>btn_label_all</Trans></JezButton></li>
                                {locations.map((item, index) => (<li key={rand()}><JezButton className={selectedIndex === index ? 'active' : ''} secondary outlined small onClick={() => setSelectedIndex(index)}>{item.name}</JezButton></li>))}
                            </ul>
                        </Grid>
                    </Grid>
                    <Container component='div' className={classes.maxWidth1170}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <JezHeading center variant='h4'>
                                <Trans>business_hours_label</Trans>
                            </JezHeading>
                            <div className={classes.officeTimeTable}>
                                {openTimes.map((item) => (<div key={rand()}>
                                    <div className={classes.officeTimeTableDay}>{getDayName(item)}</div>
                                    <div className={classes.officeTimeTableTimes}>{item.times.map((time, index) => (<Fragment key={rand()}>
                                        {index > 0 && (<span className={classes.officeTimeElement}><Trans>and</Trans></span>)}
                                        <span className={classes.officeTimeElement}>{formatTime(time.from)} - {formatTime(time.to)}</span>
                                    </Fragment>))}</div>
                                </div>))}
                                {openTimes.length === 0 && (
                                    <InfoBox><Trans>time_table_no_data</Trans></InfoBox>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3} className={'contact-address-column'}>
                            <JezHeading variant='h4'>
                                <Trans>address_hours_label</Trans>
                            </JezHeading>
                            {address && (<div className={`${classes.address} address-data`}>
                                <div>{locations[selectedIndex === -1 ? 0 : selectedIndex].name}</div>
                                <div>{address.street} {address.number}</div>
                                <div>{address.zip} {address.city}</div>
                                <div>{address.country} {address.city}</div>
                                <div>
                                    <a href= {getLocation()}
                                       target={"_blank"} className={'mapButton contact-route'}>
                                        <Trans>link_label_route</Trans>
                                    </a>
                                </div>
                            </div>)}
                        </Grid>
                        {/*{mapApiKey && (*/}
                        {/*<Grid item xs={12} md={5}>*/}
                        {/*    {geoCord && (*/}
                        {/*    <div className={classes.mapContainer}>*/}
                        {/*        <Map*/}
                        {/*            api={mapApiKey}*/}
                        {/*            center={geoCord ? geoCord : {lat: 52.5250839, lng: 13.3672133}}*/}
                        {/*            zoom={16}*/}
                        {/*            address={[`${address.street} ${address.number},`, address.zip, address.city].join('+')}*/}
                        {/*        />*/}
                        {/*    </div>)}*/}
                        {/*    {!geoCord && (<InfoBox><Trans>no_map_available</Trans></InfoBox>)}*/}
                        {/*</Grid>)}*/}
                    </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ContactSection;