import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const JezSlider = ({ children }) => {
	return (
		<div className='slide-container'>
			<Fade pauseOnHover={false} arrows={false} >
				{ children }
			</Fade>
		</div>
	);
};

export default JezSlider;
