import React, {Fragment, useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import {Trans} from "react-i18next";
import {Grid} from "@material-ui/core";
import JezButton from "../button/button";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {RiLoader3Fill} from "react-icons/ri"; //comment in if you add Proptypes to your Component
import "./OptInButton.scss"

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        zIndex: 100,
        top: '50%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        boxShadow: theme.shadows[5],
        color: '#b4ab6e',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(2, 4, 3),
        border: '1px solid #b4ab6e',
        borderRadius: 3,
        overflow: 'auto',
        maxHeight: '80%',
        overflowWrap: 'break-word',
        '&.xs': {
            width: 400,
        },
        '&.md': {
            width: 800,
        },
        '&.lg': {
            width: 1200
        }
    },
    close: {
        background: '#b4ab6e',
        border: '1px solid #b4ab6e',
        color: '#fff',
        margin: '5px',
        padding: '2px 3px;',
        cursor: 'pointer',
    },
    modalTriggerButton: {
        margin: '0 5px'
    },
    btnContainer: {
        textAlign: 'center'
    },
    loader: {
        fontSize: 20,
        verticalAlign: 'bottom',
        margin: '0 5px 0 0',
        animationName: 'spin',
        animationDuration: '1000ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear'
    }
}));

const OptInButton = ({id, className, fullHeight, isOpen, onSubmit, onClose, children, icon, label, ...args}) => {
    const optInButtonClasses = useStyles();

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if(loading) {
            return false;
        }

        setOpen(false);

        if(onClose) {
            onClose();
        }
    };

    const handleSubmit = async (e) => {
        if(onSubmit) {
            setLoading(true);
            await onSubmit(e, id);
        }

        handleClose();
    };

    useEffect(() => {
        setOpen(!!isOpen);
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            {label && (
                <JezButton secondary className={[optInButtonClasses.modalTriggerButton, className].join(' ')} small type="button" onClick={handleOpen}>
                    {label}
                </JezButton>
            )}
            {icon && (
                <span onClick={handleOpen}>
                    {icon}
                </span>
            )}
            <Modal
                style={{zIndex: 999999}}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={[optInButtonClasses.paper, 'xs'].join(' ')}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            {children}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <JezButton disabled={loading} small secondary onClick={handleSubmit} className={[].join(' ')}>{loading && <RiLoader3Fill className={optInButtonClasses.loader}/>}<Trans>button_label_yes</Trans></JezButton>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <JezButton disabled={loading} small secondary onClick={handleClose} className={['error'].join(' ')} style={{float: 'right'}}><Trans>button_label_no</Trans></JezButton>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </Fragment>
    );
}

OptInButton.propTypes = {
    id: PropTypes.number.isRequired,
    label: PropTypes.string
};

OptInButton.defaultProps = {};

export default OptInButton;