import React from "react";

// replacement for $.isEmptyObject()
export const isEmptyObject = ( obj ) => {
    let name;
    for (name in obj) {
        return false;
    }
    return true;
};

export const rand = (min, max) => {

    min = min ? min : 0;
    max = max ? max : 9999999;

    return Math.floor(Math.random() * (max - min) ) + min;
};

export const raw = (str) => {
  return (<div dangerouslySetInnerHTML={{__html: str.split("\n").join('<br />')}} />);
};

export const stringFormat = (string, data) => {
    if(data) {
        data = data instanceof Array ? data : [data];
        const match = string.match(/%[^\s]/g);

        if(match) {
            for(let ii = 0; ii < match.length; ii++) {
                if(ii < data.length) {
                    string = string.replace(match[ii], data[ii]);
                }
            }
        }
    }

      return string;
};

export const buildFormData = (data) => {
    let formData = new FormData();

    for(let key in data) {
        if(data[key] instanceof Object) {
            if(key === 'images') {
                let images = [];
                for(let ii = 0; ii < data[key].length; ii++) {
                    if(data[key][ii].file) {
                        images.push({file: true, order: data[key][ii].order, video_id: data[key][ii].video_id, deleted: false});
                        formData.append('files', data[key][ii].file);
                    } else {
                        let meta = data[key][ii] instanceof Object ? {...data[key][ii]} : {id:data[key][ii], delete:false};
                        delete meta.src;
                        delete meta._id;
                        delete meta.order;
                        images.push(meta);
                    }
                }
                formData.append(key, JSON.stringify(images));
            } else {
                formData.append(key, JSON.stringify(data[key]));
            }
        } else {
            formData.append(key, data[key])
        }
    }

    return formData;
};

export const checkRequiredFields = (validation, requiredFields, data) => {
    let newValidation = Object.assign({}, validation);

    const keys = Object.keys(validation.errorMessage);
    for(const key of keys) {
        if(!requiredFields[key]) {
            delete validation.errorMessage[key];
        }
    }

    for(let requiredField in requiredFields) {
        if(!data[requiredField] || data[requiredField] === '') {
            newValidation['error'] = true;
            newValidation['errorMessage'] = {
                ...newValidation.errorMessage,
                [requiredField]: requiredFields[requiredField] + " muss ausgefüllt werden."
            };
        }
        else {
            let errorMessage = newValidation.errorMessage;
            delete (errorMessage[requiredField]);
            newValidation['errorMessage'] = {
                ...newValidation.errorMessage,
            };
            newValidation['error'] = !isEmptyObject(errorMessage);
        }
    }
    newValidation['validated'] = true;
    return newValidation;
};

export const inputChange = (e, data) => {
    let newState = Object.assign({}, data);
    switch(e.target.type) {
        case 'checkbox':
            newState[e.target.name] = e.target.checked;
            break;
        case 'file':
            newState[e.target.name] = e.target.files[0];
            break;
        case 'radio':
        default:
            newState[e.target.name] = e.target.value;
            break;
    }
    return newState;
};
