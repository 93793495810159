import JezContainer from "../components/container/container";
import JezHeading from "../components/heading/heading";
import React, {useEffect, useState, useContext} from "react";
import AuthContext from "../context/auth";
import {Trans, useTranslation} from "react-i18next";
import {useJezios} from "../util/jezios";
import {useParams} from 'react-router-dom';
import JezItem from "../components/listItem/listItem";

const NewsDetails = ( ) => {
    const [item, setItem] = useState({});
    const {newsId} = useParams();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    const request = useJezios(auth, t);

    const fetch = async () => {
        const response = await request.get(`/news/${newsId}`);

        if(response) {
            setItem(response.data);
        }
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    return (
        <JezContainer maxWidth={'lg'} padding style={{margin: '96px auto 32px auto'}}>
            <JezHeading center variant='h2'>
                <Trans>news_label</Trans>
            </JezHeading>
            {item.content && (
                <JezItem section={'news'} content={item.content} title={item.name} images={item.images} expanded={true} collapseable={false} imageSize={4}/>
            )}
        </JezContainer>
    );
};

export default NewsDetails;