import React, {useContext} from 'react';
import JezHeading from "../components/heading/heading";
import Homepage from './homepage/homepage';
import {Route, Routes, useNavigate} from 'react-router-dom';
import JezNavbar from '../components/navbar/navbar';
import Premium from './premium';
import Activation from './activation';
import RegistrationForm from '../forms/registration';
import LoginForm from '../forms/login';
import ModerationDataForm from '../forms/moderation/data';
import ModerationUserForm from '../forms/moderation/user';
import JezProfile from '../components/profile/profile';
import JezFooter from '../components/footer/footer';
import ProfileForm from "../forms/profile";
import HostList from "./hosts";
import AuthContext from '../context/auth';
import JezVideoCall from "../components/videoCall/videoCall";
import Location from "./location";
import LocationForm from "./../forms/locationForm";
import LocationList from "./locations";
import EscortDetails from "./escort";
import NewsForm from "../forms/moderation/newsForm";
import NewsList from "../forms/moderation/newsList";
import News from "./news";
import ModerationLocationList from "../forms/moderation/locationList"
import LegalAge from "../components/legalAge/legalAge";
import NewsDetails from "./newsDetails";
import JobList from "../forms/moderation/jobList";
import JobForm from "../forms/moderation/jobForm";
import Jobs from "./jobs";
import JobDetails from "./jobDetails";
import JezGoogleSettings from "../forms/system/google/settings";
import WorkingTimeForm from "../forms/workingTime";
import ModerationBookList from "./book/list";
import Impress from "./impress";
import Privacy from "./privacy";
import BookingListForm from "../forms/moderation/bookingList";

const Application = ({ history }) => {
	const { auth } = useContext(AuthContext);
	const navigate = useNavigate();

	if(auth && auth.redirectTo && auth.redirectTo === 'profile_edit'){
		if (history.location.pathname !== '/profile') navigate('/profile');
	}

	return (
		<>
		<LegalAge/>
		<div className='wrap'>
			<JezNavbar title={<JezHeading variant={'portalName'}/>} />
			<Routes>
				<Route path='/' element={<Homepage />} />

				<Route exact path='/locations' element={<LocationList />} />
				<Route path='/escort' element={<EscortDetails />} />
				<Route exact path='/hosts' element={<HostList />} />
				<Route exact path='/hosts/work/time' element={<WorkingTimeForm />} />
				<Route exact path='/premium' element={<Premium />} />
				<Route exact path='/member/:hostId' element={<JezProfile />} />
				<Route exact path='/host/:hostId/profile' element={<JezProfile />} />
				<Route exact path='/profile' element={<ProfileForm />} />
				<Route exact path='/profile/:hostId' element={<ProfileForm />} />
				<Route exact path='/profile/:hostId/work/time' element={<WorkingTimeForm />} />
				<Route exact path='/profile/:hostId/bookings' element={<BookingListForm />} />
				<Route exact path='/users/activate' element={<Activation />} />
				<Route exact path='/register/:type' element={<RegistrationForm />} />
				<Route exact path='/register' element={<RegistrationForm />} />
				<Route exact path='/login' element={<LoginForm />} />
				<Route exact path='/locations/:locationId' element={<Location />} />
				<Route exact path='/news' element={<News />} />
				<Route exact path='/news/:newsId' element={<NewsDetails />} />
				<Route exact path='/jobs' element={<Jobs />} />
				<Route exact path='/job/:jobId' element={<JobDetails />} />

				<Route exact path='/moderation/hosts/reservation' element={<BookingListForm />} />
				<Route exact path='/moderation/hosts/booking' element={<ModerationBookList />} />
				<Route exact path='/moderation/locations' element={<ModerationLocationList />} />
				<Route exact path='/moderation/locations/:locationId' element={<LocationForm />} />
				<Route exact path='/moderation/user/:uid' element={<ModerationDataForm />} />
				<Route exact path='/moderation/user' element={<ModerationUserForm />} />
				<Route exact path='/moderation/news' element={<NewsList />} />
				<Route exact path='/moderation/news/create' element={<NewsForm />} />
				<Route exact path='/moderation/news/:newsId' element={<NewsForm />} />
				<Route exact path='/moderation/jobs' element={<JobList />} />
				<Route exact path='/moderation/job/create' element={<JobForm />} />
				<Route exact path='/moderation/job/:jobId' element={<JobForm />} />

				<Route exact path='/system/google' element={<JezGoogleSettings />} />

				<Route path='/impressum' element={<Impress />} />
				<Route path='/datenschutz' element={<Privacy />} />

			</Routes>
			{auth && (<JezVideoCall />)}
		</div>
		<JezFooter />
		</>
	);
};

export default Application;
