import React from "react";
import {rand} from "../../util/tools";
import {useTranslation} from "react-i18next";

const JezSubmit = ({onSubmit, children, ...args}) => {

    const buttonId = rand();

    const {t} = useTranslation();

    const submit = async () => {
        const btn = document.getElementById(`submit-${buttonId}`);

        btn.setAttribute('disabled', '');

        if(onSubmit) {
            try {
                await onSubmit();
            } catch(err) {}
        }

        btn.removeAttribute('disabled');
    };

    return (
        <button className={`btn primary`} id={`submit-${buttonId}`} onClick={submit} {...args}>{children ? children : t('btn_label_submit')}</button>
    )
};

export default JezSubmit